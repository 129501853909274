import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ApiService } from '../../services/api.service';

import { DialogLoadingComponent } from '../dialog-loading/dialog-loading.component';
import { AddPhysicianModalComponent } from '../modal/add-physician-modal/add-physician-modal.component';

import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
    selector: 'app-physicians',
    templateUrl: './physicians.component.html',
    styleUrls: ['./physicians.component.scss']

})
export class PhysiciansComponent implements OnInit {
    public officeKeys: any = []
    public mappedPhysicians: any = []
    public providers: any = []

    public selectedOfficeKey: string = ''

    public isLoading: boolean = false;
    public isLoadingTable: boolean = false;
    public isLoadingProvider: boolean = false;
    public dialogLoading: any;

    public isShowBtnAdd: boolean = false
    public isLoginAmd: boolean = false

    constructor(
        private apiService: ApiService,
        private matDialog: MatDialog,
        private _snackBar: MatSnackBar
    ){
  
    }

    ngOnInit(): void {
        this.loadDataOfficeKey()
    }

    loadDataOfficeKey() {
        this.isLoading = true
        this.apiService.getOfficeKeysByUserAssigned().subscribe((r) => {
            this.officeKeys = r
            this.isLoading = false
        })
    }

    changeOfficeKey(event) {
        if (this.selectedOfficeKey !== '') {
            this.isLoadingProvider = true
            //this.logIntoAmd(this.selectedOfficeKey)
            this.isLoginAmd = true
            this.loadDataMappedPhysician(this.selectedOfficeKey, this.providers)
            this.isLoadingTable = true
            this.isShowBtnAdd = true
        } else {
            this.isShowBtnAdd = false
            this.mappedPhysicians = []
        }
    }

    logIntoAmd(officeKey){
        this.apiService.logIntoAmd(officeKey).subscribe((r) => {
            if(!!r){
               this.isLoginAmd = true
               this.loadProviders(officeKey)
               this.loadDataMappedPhysician(officeKey, this.providers)
            }else{
                this.isLoginAmd = false
            }
        })
    }

    loadProviders(officeKey){
        this.isLoadingProvider = true
        this.apiService.lookupProviders(officeKey).subscribe((r) => {
            this.providers = r
            this.providers = this.customizeProviderList(this.providers)
            this.isLoadingProvider = false
        })
    }

    loadDataMappedPhysician(officeKey, providers) {
        this.isLoadingTable = true
        this.isLoadingProvider = true
        this.apiService.getMapedPh(officeKey).subscribe((r) => {
            if (r) {
                this.providers = r.providerList 
                this.providers = this.customizeProviderList(this.providers)
                this.mappedPhysicians = r.physicians
                _.each(this.mappedPhysicians, (e) => {
                    e.providerAPI = this.mapProvider(e.providerAPI)
                })
            } else {
                this.mappedPhysicians = []
                this.providers = []
            }
            this.isLoadingTable = false
            this.isLoadingProvider = false
        })
    }

    customizeProviderList(providers){
        _.each(providers, (p) => {
           p = this.mapProvider(p)
        })
        return providers
    }

    mapProvider(provider){
        if(provider && provider.name){
            let name = provider.name.replace("(INACTIVE)", " ").trim()
            let nameArr = name.split(',')
            provider.lastName = nameArr[0]?.trim()
            provider.firstName = nameArr[1]?.trim()
        }
        return provider
    }

    checkMatchProvider(phyCheck:string, providerCheck:string){
        if(phyCheck!= null && providerCheck != null && phyCheck.trim().toLowerCase() !== providerCheck.trim().toLowerCase()){
            return true
        }else{
            return false
        }
    }

    deletePhyisican(physician, index) {
        if (confirm('You are about to delete a rule, are you sure?')) {
            let payload = {
                pvPhysicianId: parseInt(physician.pvPhysicianId, 10),
                officeKey: this.selectedOfficeKey
            }
            this.apiService.deletePhysicianNew(payload).subscribe((r) => {
                if (r.success) {
                    this.showSnackBar(r.message)
                    this.mappedPhysicians = _.filter(this.mappedPhysicians, (e, ind) => { return ind != index })
                } else {
                    this.showSnackBar(r.message)
                }
            })
        }
    }

    addNewPhysician(){
        const dialogRef = this.matDialog.open(AddPhysicianModalComponent, {
            minWidth: '600px',
            data: {
                officeKey: this.selectedOfficeKey,
                isEdit: false
            },
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result == "saved") {
                this.loadDataMappedPhysician(this.selectedOfficeKey, this.providers)
            }
        });
    }

    editPhysician(physician){
        const dialogRef = this.matDialog.open(AddPhysicianModalComponent, {
            minWidth: '600px',
            data: {
                officeKey: this.selectedOfficeKey,
                physician: physician,
                isEdit: true
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result == "saved") {
                this.loadDataMappedPhysician(this.selectedOfficeKey, this.providers)
            }
        });
    }

    openLoading() {
       this.dialogLoading = this.matDialog.open(DialogLoadingComponent, {
            minWidth: '150px',
            disableClose: true
        });
    }

    closeLoading() {
        this.dialogLoading.close()
    }

    showSnackBar(message) {
        this._snackBar.open(message)
        setTimeout(() => {
            this._snackBar.dismiss()
        }, 1000);

    }

    addNewPhyToProvider(provider){
       const dialogRef = this.matDialog.open(AddPhysicianModalComponent, {
            minWidth: '600px',
            data: {
                officeKey: this.selectedOfficeKey,
                provider,
                isEdit: false
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result == "saved") {
                provider.isNeedAddPhy = false
                this.loadDataMappedPhysician(this.selectedOfficeKey, this.providers)
            }
        });
    }

    updateActivePhy(item){
        var payload = {
            officeKey: this.selectedOfficeKey,
            pvPhysicianId: item.pvPhysicianId,
            active: item.active
        }

        this.apiService.UpdateActivePhysician(payload).subscribe((r) => {
            if(r.success){
                this.showSnackBar(r.message)
            }
        })
    }
   
    editPhyToProvider(provider, ind){

    }
}
