import { Component, OnInit, Inject } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-dialog-payment-import-log',
    templateUrl: './dialog-payment-import-log.component.html',
    styleUrls: ['./dialog-payment-import-log.component.scss']

})
export class DialogImportLogComponent implements OnInit {
    responseRaw: any;
    responseRaw_XML
    constructor(private matDialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any) {
  
    }

    ngOnInit(): void {
        this.responseRaw = this.data.responseRaw
        this.responseRaw_XML = this.data.responseRaw_XML
     }

   

}
