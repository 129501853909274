<h2 mat-dialog-title>{{currentAddendumItem.id ? 'Edit':'Add'}} Addendum Item</h2>


<div mat-dialog-content>
    <div class="row">
        <div class="col-12">
            <div >
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Label: </mat-label>
                    <input matInput [(ngModel)]="currentAddendumItem.label">

                    <ng-container *ngIf="isError && !currentAddendumItem.label">
                        <mat-error >Label is required</mat-error>
                    </ng-container>

                </mat-form-field>
            </div>

            <div style="margin-bottom:10px;">
                <label>Input Label</label>
                <div *ngFor="let label of currentAddendumItem.inputLabels; let i = index">
                    <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span><b>{{ '{' + label.index + '}' }}</b></span>
                        <input [(ngModel)]="label.keyLabel" placeholder="Input label" style="width:50%" />
                        <button mat-icon-button (click)="removeLabel(i)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>
                <div>
                    <button mat-raised-button color="primary" (click)="addLabel()"> <mat-icon>add</mat-icon> input label</button>
                </div>
            </div>

            <div>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Document Text: </mat-label>
                    <textarea matInput [(ngModel)]="currentAddendumItem.documentText" rows="3"></textarea>
                    <ng-container *ngIf="isError && !currentAddendumItem.documentText">
                        <mat-error>DocumentText is required</mat-error>
                    </ng-container>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>

<div mat-dialog-actions style="justify-content: end; ">
    <button class="btn" style="background-color: white; color: dimgrey; border: 1px solid dimgrey; margin-right: 10px" (click)="closeDialog()">
        <ng-container>Cancel</ng-container>
    </button>

    <button class="btn" [disabled]="inCallback" style="background-color: white; color: green; border: 1px solid green;" (click)="saveAddendumItem()">
        <ng-container *ngIf="!inCallback">{{currentAddendumItem.id?"Save Update":"Save New"}}</ng-container>
        <ng-container *ngIf="inCallback">Processing...</ng-container>
    </button>
</div>
