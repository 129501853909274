import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
    selector: 'app-confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.scss']

})
export class ConfirmModalComponent implements OnInit {

    public title: "";
    public messageBody: "";

    constructor(public dialogRef: MatDialogRef<ConfirmModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
        this.title = this.data.title;
        this.messageBody = this.data.message;
    }


    confirm() {
        this.dialogRef.close(true);
    }


}
