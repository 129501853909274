import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/services/api.service';
import { NewUpdateInsuranceInformationComponent } from '../modal/new-update-insurance-information/new-update-insurance-information.component';
import { DialogLoadingComponent } from '../dialog-loading/dialog-loading.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-insurance-information',
  templateUrl: './insurance-information.component.html',
  styleUrls: ['./insurance-information.component.scss']
})
export class InsuranceInformationComponent implements OnInit {
  public page = {
    PageSize: 20,
    CurrentPage: 0,
    RowCount: 0
  }
  public loadingTable: boolean = false
  public rowsInsurances: any = []
  private dialogLoading: any;

  constructor(
    private apiService: ApiService,
    private matDialog: MatDialog,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.loadData()
  }

  loadData() {
    this.loadingTable = true
    this.apiService.getInsuranceInformations(this.page.PageSize, this.page.CurrentPage).subscribe((r: any) => {
      this.rowsInsurances = r.results
      this.page.RowCount = r.total
      this.loadingTable = false
    })
  }

  getPage(event) {
    this.page.CurrentPage = event.offset
    this.loadData()
  }

  newInsurance() {
    const dialogRef = this.matDialog.open(NewUpdateInsuranceInformationComponent, {
      minWidth: '600px',
      data: {
        isEdit: false
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == "saved") {
         this.loadData();
      }
    });
  }

  editInsurance(insurance) {
    const dialogRef = this.matDialog.open(NewUpdateInsuranceInformationComponent, {
      minWidth: '600px',
      data: {
        isEdit: true,
        insurance: insurance
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == "saved") {
         this.loadData();
      }
    });
  }

  removeInsurance(insurance) {
    if (confirm('You are about to delete a insurance, are you sure?')) {
      this.openLoading()
      this.apiService.deleteInsuranceInformationById(insurance.insuranceId).subscribe((r) => {
          this.loadData();
          this.showSnackBar("Delete this insurance successfully!")
          this.closeLoading()
      }, err => {
        this.showSnackBar("Failed to delete this insurance!")
        this.closeLoading()
      })
    }
  }

  openLoading() {
    this.dialogLoading = this.matDialog.open(DialogLoadingComponent, {
         minWidth: '150px',
         disableClose: true
     });
 }

 closeLoading() {
     this.dialogLoading.close()
 }

 showSnackBar(message) {
  this._snackBar.open(message)
  setTimeout(() => {
    this._snackBar.dismiss()
  }, 1000);
}

}
