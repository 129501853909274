<div class="row" style="display:block;">
    <div class="col-lg-6">
        <h1>Clinic Manager</h1>
    </div>
</div>

<div style=" border-top: 5px solid #fbaf03; background-color:white;">
    <div class="btn-add-action" style="width: 100%; text-align:end; padding: 15px;">
        <button mat-raised-button style="background-color: #4ac94a;" (click)="openModalNewClinic()">New Clinic</button>
    </div>
    <ngx-datatable class="material" [rows]="clinics" [headerHeight]="50" [footerHeight]="50" rowHeight="auto"
                   [scrollbarH]="true" [loadingIndicator]="isLoading" [count]="total" [offset]="offset"
                   limit="10" (page)="getPage($event)"  [externalPaging]="true">
        <ngx-datatable-column name="Clinic" [width]="300">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.clinicId}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Clinic Full Name" [width]="400">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.clinicFullName}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="AMD Code Name" [width]="300">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.amdcodeName}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="OfficeKey" [width]="300">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.officeKey}}
            </ng-template>
        </ngx-datatable-column>
        <!--<ngx-datatable-column name="Action" [width]="200">
            <ng-template let-row="row" ngx-datatable-cell-template>
               
            </ng-template>
        </ngx-datatable-column>-->   
    </ngx-datatable>
</div>
