<div style="padding: 10px 20px">
    <div class="row top-buffer">
        <div class="box box-widget">
            <div class="box-header with-border">
                <h3 class="box-title">Physician</h3>
            </div>
            <div class="box-body">
                <div class="row" *ngIf="!isLoading">
                    <div class="col-md-3">
                        <mat-form-field appearance="fill" style="width: 100%">
                            <mat-label>Office Key:</mat-label>
                            <mat-select [(ngModel)]="selectedOfficeKey" (ngModelChange)="changeOfficeKey($event)">
                                <mat-option value="">-Select Office Key-</mat-option>
                                <mat-option *ngFor="let item of officeKeys" [value]="item.value">{{item.text}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <button *ngIf="isShowBtnAdd" type="button" class="btn btn-primary" (click)="addNewPhysician()">Add New</button>
                    </div>
                </div>
                <div class="row" *ngIf="isLoading">
                    <div class="col-12 text-center">
                        <span class="text-gray">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row top-buffer" *ngIf="selectedOfficeKey !== '' ">
        <div class="box box-widget">
            <div class="box-header with-border">
                <h3 class="box-title">Providers (Our Database) </h3>
            </div>
            <div class="box-body">
                <div class="row">
                    <div class="col-12" style="padding: 0 20px; height: 500px; overflow-y: auto;">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">PV Physican Id</th>
                                    <th scope="col">Physican First Name</th>
                                    <th scope="col">Physican Last Name</th>
                                    <th scope="col">Amd Provider Name</th>
                                    <th scope="col">Amd Provider Code</th>
                                    <th scope="col">Amd Provider Id</th>
                                    <th scope="col">Active</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="isLoadingTable">
                                    <td colspan="8" class="text-center">
                                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                                        <span class="text-gray">Loading...</span>
                                    </td>
                                </tr>
                                <tr *ngIf="!isLoadingTable && mappedPhysicians?.length == 0">
                                    <td colspan="6" class="text-center">
                                        <span style="font-weight: bold"> No Physisian has been found.</span>
                                    </td>
                                </tr>
                                <ng-container *ngIf="!isLoadingTable && mappedPhysicians?.length > 0">
                                    <tr *ngFor="let item of mappedPhysicians; let ind = index">
                                        <th scope="row">{{item.pvPhysicianId}}</th>
                                        <td>
                                            {{item.pvFirstName}}
                                            <div class="warning" *ngIf="checkMatchProvider( item.pvFirstName, item.providerAPI?.firstName)">
                                               <span>Mismatch first name with AMD API </span> <br>
                                               <span>{{item.pvFirstName}} (database) and {{item.providerAPI?.firstName}} (AMD API)</span>
                                            </div>
                                        </td>
                                        <td>
                                            {{item.pvLastName}}
                                            <div class="warning" *ngIf="checkMatchProvider(item.pvLastName, item.providerAPI?.lastName)">
                                                <span>Mismatch last name with AMD API </span> <br>
                                                <span>{{item.pvLastName}} (database) and {{item.providerAPI?.lastName}} (AMD API)</span>
                                            </div>
                                        </td>
                                        <td>{{item.amdDisplayName}}</td>
                                        <td>
                                            {{item.amdProviderCode}}
                                            <div class="warning" *ngIf="checkMatchProvider(item.amdProviderCode, item.providerAPI?.code)">
                                                <span>Mismatch providerCode with AMD API </span> <br>
                                                <span>{{item.amdProviderCode}} (database) and {{item.providerAPI?.code}} (AMD API)</span>
                                            </div>
                                        </td>
                                        <td>
                                            {{item.amdProfileId}}
                                            <div class="warning" *ngIf="checkMatchProvider(item.amdProfileId, item.providerAPI?.id)">
                                                <span>Mismatch providerId with AMD API </span> <br>
                                                <span>{{item.amdProfileId}} (database) and {{item.providerAPI?.id}} (AMD API)</span>
                                            </div>
                                        </td>
                                        <td>
                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" [(ngModel)]="item.active" (change)="updateActivePhy(item)">
                                        </td>
                                        <td>
                                            <div style="display: flex; align-items: center; justify-content: center;">
                                                <button class="btn btn-link editPh" (click)="editPhysician(item)">Edit</button>|
                                                <button class="btn btn-link deletePh" (click)="deletePhyisican(item, ind)">Deactivate</button>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row top-buffer" *ngIf="selectedOfficeKey !== '' && !!isLoginAmd">
        <div class="box box-widget">
            <div class="box-header with-border">
                <h3 class="box-title">Providers (AMD API)</h3>
            </div>
            <div class="box-body">
                <div class="row">
                    <div class="col-12" style="padding: 0 20px; height: 500px; overflow-y: auto;">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">ProviderId</th>
                                    <th scope="col">Provider Name</th>
                                    <th scope="col">Provider Code</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="isLoadingProvider">
                                    <td colspan="6" class="text-center">
                                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                                        <span class="text-gray">Loading...</span>
                                    </td>
                                </tr>
                                <tr *ngIf="!isLoadingProvider && providers?.length == 0">
                                    <td colspan="6" class="text-center">
                                        <span style="font-weight: bold"> No Providers has been found.</span>
                                    </td>
                                </tr>
                                <ng-container *ngIf="!isLoadingProvider && providers?.length > 0">
                                    <tr *ngFor="let item of providers; let ind = index">
                                        <th scope="row">{{item.id}}</th>
                                        <td>{{item.name}}</td>
                                        <td>{{item.code}}</td>
                                        <td>{{item.status}}</td>
                                        <td>
                                            <div style="display: flex; align-items: center; justify-content: center;">
                                                <button *ngIf="!!item.isNeedAddPhy" class="btn btn-link editPh" (click)="addNewPhyToProvider(item)">Add Physican</button>
                                                <ng-container *ngIf="!!item.isNeedAddPhy">|</ng-container>
                                                <button class="btn btn-link deletePh" (click)="editPhyToProvider(item, ind)">Edit</button>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>