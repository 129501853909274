<h1 mat-dialog-title>Doc Viewer</h1>
<div mat-dialog-content>
    <div *ngIf="isLoading">
        Loading..
    </div>
    <div class="modal-body mx-3">
        <!--<div class="viewer text-center" style="height:800px; width:100%;" id="chartviewer"
             *ngIf="srcChartImage != ''">
            <img alt="" class="img-thumbnail img-fluid" id="chartimage" [src]="srcChartImage">
        </div>-->
        <div *ngIf="!hasImage">
            No Data.
        </div>
    </div>
</div>
<div mat-dialog-actions style="justify-content: end;">
    <button class="btn btn-secondary" style="margin-right: 5px" mat-dialog-close>Close</button>
</div>