import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '../../../services/api.service';
import * as _ from 'lodash';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'update-guarantor-information-modal',
    templateUrl: './update-guarantor-information-modal.component.html',
    styleUrls: ['./update-guarantor-information-modal.component.scss']

})
export class UpdateGuarantorInformationModalComponent implements OnInit {
    public visit: any;
    public currentPatient: any;
    public payers = [] as any;
    public relationShips = [] as any;
    public currentGuarantor: any;
    public isLoading = true;
    public isSaving = false;
    constructor(
        public dialogRef: MatDialogRef<UpdateGuarantorInformationModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private apiService: ApiService,
        private _snackBar: MatSnackBar
    ) {
        this.visit = data.visit;
        this.currentPatient = data.visit.pvPatient;
        console.log(this.visit)
        console.log(this.currentPatient)
    }

    ngOnInit(): void {
        this.fetchData();
    }

    fetchData() {
        this.isLoading = true;
        forkJoin({
            guarantor: this.apiService.getGuarantorInformation(this.visit.guarantorPayerId, this.visit.emR_ID),
            payerInformations: this.apiService.getPayerInformations(this.visit.visitId),
            allRelationShips: this.apiService.getallRelationShips(this.visit.emR_ID)
        }).subscribe(
            result => {
                this.currentGuarantor = result.guarantor;
                this.payers = result.payerInformations;
                this.relationShips = result.allRelationShips;
                this.isLoading = false;
            },
            error => {
                this.isLoading = false;
            }
        );
    }


    saveUpdateAndReQueue() {
        this.isSaving = true;
        if (!this.currentGuarantor.firstName || !this.currentGuarantor.lastName) {
            this.isSaving = false; 
            return; 
        }
        this.apiService.updateGuarantorInformation(this.currentGuarantor, this.visit.visitId).subscribe(r => {
            this.isSaving = false;
            this.showSnackBar("Saved successfully!");
            this.dialogRef.close(true);
        }, err => {
            this.showSnackBar('Failed to update guarantor information!');
        })
    }


    showSnackBar(message) {
        this._snackBar.open(message)
        setTimeout(() => {
            this._snackBar.dismiss()
        }, 1000);
    }

}
