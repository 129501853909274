<div class="row" style="display:flex; align-items: center; justify-content: space-between">
    <div class="col-lg-6">
        <h1>Insurance Information</h1>
    </div>
    <div class="col-lg-6" style="text-align: end;">
        <button mat-raised-button color="primary" (click)="newInsurance()">New Insurance</button>
    </div>
</div>

<div>
    <ngx-datatable 
            class="material" [rows]="rowsInsurances" [headerHeight]="50" [footerHeight]="50" rowHeight="auto"
            [scrollbarH]="true" [loadingIndicator]="loadingTable" [count]="page.RowCount" [offset]="page.CurrentPage"
            [limit]="page.PageSize"  (page)="getPage($event)" [externalPaging]="true">
        <ngx-datatable-column name="InsuranceId" [width]="100">
            <ng-template let-row="row" ngx-datatable-cell-template>
               {{row.insuranceId}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Name" [width]="200">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.primaryName}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="AmdCode" [width]="150">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.amdCode}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Address1" [width]="150">
            <ng-template let-row="row" ngx-datatable-cell-template>
             {{row.primaryAddress1}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Address2" [width]="150">
            <ng-template let-row="row" ngx-datatable-cell-template>
             {{row.primaryAddress2}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="City" [width]="200">
            <ng-template let-row="row" ngx-datatable-cell-template>
             {{row.primaryCity}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="State" [width]="200">
            <ng-template let-row="row" ngx-datatable-cell-template>
             {{row.primaryState}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Zip" [width]="100">
            <ng-template let-row="row" ngx-datatable-cell-template>
             {{row.primaryZip}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Phone" [width]="100">
            <ng-template let-row="row" ngx-datatable-cell-template>
             {{row.primaryPhone}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Action" [width]="100">
            <ng-template let-row="row" ngx-datatable-cell-template>
               <div class="row-action">
                <button mat-icon-button class="edit-insurance" aria-label="Edit Insurance" matTooltip="Edit Insurance" (click)="editInsurance(row)">
                    <span class="glyphicon glyphicon-pencil" arial-hidden="true" data-></span>
                </button>
                <button mat-icon-button class="remove-insurance" aria-label="Delete Insurance" matTooltip="Delete Insurance" (click)="removeInsurance(row)">
                    <span class="glyphicon glyphicon-trash" arial-hidden="true" data-></span>
                </button>
               </div>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
</div>