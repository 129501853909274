import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ApiService } from '../../../services/api.service';

import * as moment from 'moment';
import * as _ from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-process-log-error-detail-modal',
  templateUrl: './process-log-error-detail-modal.component.html',
  styleUrls: ['./process-log-error-detail-modal.component.scss']
})
export class ProcessLogErrorDetailModalComponent implements OnInit {
 
    public processId: number = 0;
    fileName =""
    public isLoading: boolean = false;
    public listError: any = []
    public listDuplicates: any = [];

  constructor(
    private matDialog: MatDialog,
    public dialogRef: MatDialogRef<ProcessLogErrorDetailModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private _snackBar: MatSnackBar,
  ) { }

    ngOnInit() {
      console.log(this.data)
        this.processId = this.data.processId
        this.fileName = this.data.fileName
    if(this.processId){
       this.loadDataErrorDetail()
    }
  }

  loadDataErrorDetail(){
      this.isLoading = true
      this.apiService.getMissingPatientRecords(`C:\\inetpub\\ftproot\\LocalUser\\PVFtpFileAccess\\`+this.fileName).subscribe((r) => {
          this.listError = r.missingRecords
          this.listDuplicates = r.duplicateLogNums ?? []
         _.each(this.listError, (e) => {
            e.patientInfo = e.patientInfoJson ? JSON.parse(e.patientInfoJson) : null
         })
         this.isLoading = false
    })
    }

    //getMissingPatientRecords(fileName) {
    //    this.apiService.reImportErorRecord(error).subscribe((r) => {
    //        error.isLoading = false
    //        if (!!r) {
    //            this.showSnackBar("Loading missing records!")
               
    //        } else {
               
    //        }
    //}

  reImportError(){



      var payload = {
          records: this.listError,
          sourceProcessId: this.processId,
          sourceFile: this.fileName
      };
      this.isLoading = true;
      this.apiService.reImportErorRecord(payload).subscribe((r) => {
          this.isLoading = false;
          if (r) {
              
        this.showSnackBar("Re-Import Sucessfully!")
        this.loadDataErrorDetail()
      }else{
        this.showSnackBar("Re-Import Unsucessfully!")
      }
     }, err => {
          this.isLoading = false;
      this.showSnackBar("Re-Import Unsucessfully!")
     })
  }

  public showSnackBar(message) {
    this._snackBar.open(message)
    setTimeout(() => {
        this._snackBar.dismiss()
    }, 1000);
 }

}
