<h1 mat-dialog-title>
    <ng-container *ngIf="!isEdit">
        Add New Insurance Information
    </ng-container>
    <ng-container *ngIf="isEdit">
        Edit Insurance Information
    </ng-container>
</h1>
<div mat-dialog-content>
    <div class="modal-body mx-3">
        <form [formGroup]="insuranceForm">
            <div class="form-group">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Name: </mat-label>
                    <input matInput formControlName="primaryName">
                </mat-form-field>
                <ng-container *ngIf="isSubmitted && insuranceF.primaryName.errors">
                    <mat-error *ngIf="insuranceF.primaryName.errors.required">Name is required</mat-error>
                </ng-container>
            </div>
            <div class="form-group">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Phone: </mat-label>
                    <input matInput formControlName="primaryPhone">
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Address 1: </mat-label>
                    <input matInput formControlName="primaryAddress1">
                </mat-form-field>
                <ng-container *ngIf="isSubmitted && insuranceF.primaryAddress1.errors">
                    <mat-error *ngIf="insuranceF.primaryAddress1.errors.required">Address1 is required</mat-error>
                </ng-container>
            </div>
            <div class="form-group">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Address 2: </mat-label>
                    <input matInput formControlName="primaryAddress2">
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>City: </mat-label>
                    <input matInput formControlName="primaryCity">
                </mat-form-field>
                <ng-container *ngIf="isSubmitted && insuranceF.primaryCity.errors">
                    <mat-error *ngIf="insuranceF.primaryCity.errors.required">City is required</mat-error>
                </ng-container>
            </div>
            <div class="form-group">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>State: </mat-label>
                    <input matInput formControlName="primaryState">
                </mat-form-field>
                <ng-container *ngIf="isSubmitted && insuranceF.primaryState.errors">
                    <mat-error *ngIf="insuranceF.primaryState.errors.required">State is required</mat-error>
                </ng-container>
            </div>
            <div class="form-group">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Zip: </mat-label>
                    <input matInput formControlName="primaryZip">
                </mat-form-field>
                <ng-container *ngIf="isSubmitted && insuranceF.primaryZip.errors">
                    <mat-error *ngIf="insuranceF.primaryZip.errors.required">Zip is required</mat-error>
                </ng-container>
            </div>
            <div class="form-group">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>AmdCode: </mat-label>
                    <input matInput formControlName="amdCode">
                </mat-form-field>
                <ng-container *ngIf="isSubmitted && insuranceF.amdCode.errors">
                    <mat-error *ngIf="insuranceF.amdCode.errors.required">AmdCode is required</mat-error>
                </ng-container>
            </div>
        </form>
    </div>
</div>
<div mat-dialog-actions style="justify-content: end;">
    <button class="btn btn-secondary" style="margin-right: 5px" mat-dialog-close>Close</button>
    <button class="btn btn-primary" [disabled]="insuranceForm.invalid" (click)="save()">
        <ng-container *ngIf="!isSaving && !isEdit">Add</ng-container>
        <ng-container *ngIf="isSaving && !isEdit">Adding...</ng-container>
        <ng-container *ngIf="!isSaving && isEdit">Update</ng-container>
        <ng-container *ngIf="isSaving && isEdit">Updating...</ng-container>
    </button>
</div>