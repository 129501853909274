<div class="row">
    <div>
        <h1 style="width:auto;padding-left:15px">Action Set Setup</h1>
    </div>
</div>

<div class="row" style=" border-top: 5px solid #fbaf03; background-color:white;">
    <div class="col-md-3 col-sm-12" style="margin:15px 0px">

        <div *ngIf="isLoading">
            <mat-spinner></mat-spinner>
        </div>
        <div *ngIf="!isLoading && bulkActionSets.length==0">
            <span>No saved action set</span>
        </div>

        <div *ngIf="!isLoading && bulkActionSets.length>0">
            <div *ngFor="let item of bulkActionSets" class="bulkActionSets-table" [ngClass]="{ 'table-item': true, 'active': selectedSetId == item.id }" (click)="changeBulkAction(item)">
                <span class="item-name">{{ item.name }}</span>
            </div>
        </div>
    </div>

    <div class="col-md-8 col-md-offset-1 col-sm-12" style="margin:15px 0px">
        <div class="box-header with-border">
            <div class="col-lg-9 align-self-end mt-1">
                <button mat-raised-button mat-button (click)="addAction()">
                    Add
                    Action
                </button>
            </div>
            <div class="col-lg-12" style="margin-top:10px;" *ngIf="arrAction.length > 0">
                <table style="width: 100%">
                    <tbody>
                        <tr style="border-bottom: 1px solid #e8e5e5; display:flex; padding:10px 0px" *ngFor="let item of arrAction; let i = index">
                            <td style="width: 10%">
                                <div style="display: flex;align-items: center; justify-content: center">
                                    <button mat-icon-button color="warn"
                                            (click)="deleteItem(i)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                    <button mat-icon-button color="primary"
                                            (click)="refreshItem(i)">
                                        <mat-icon>refresh</mat-icon>
                                    </button>
                                </div>
                            </td>
                            <td style="width: 28%; margin-right:2%;">
                                <mat-form-field appearance="fill" style="width: 100%">
                                    <mat-label>Action</mat-label>
                                    <mat-select (ngModelChange)="changeAction($event, i)"
                                                [(ngModel)]="item.value">
                                        <mat-option value="addMod">Add Modifier</mat-option>
                                        <mat-option value="delMod">
                                            Delete Modifier
                                        </mat-option>
                                        <mat-option value="addCpt">Add CPT</mat-option>
                                        <mat-option value="delCpt">Delete CPT</mat-option>
                                        <mat-option value="addIcd">Add ICD</mat-option>
                                        <mat-option value="delIcd">Delete ICD</mat-option>
                                        <mat-option value="addNote">Add Note</mat-option>
                                        <mat-option value="updPhys">
                                            Update Physician
                                        </mat-option>
                                        <mat-option value="updClinic">
                                            Update Clinic
                                        </mat-option>
                                        <mat-option value="updOfficeKey">
                                            Update OfficeKey
                                        </mat-option>
                                        <mat-option value="updFinClass">
                                            Update Fin Class
                                        </mat-option>
                                        <mat-option value="unFlag">Clear Flag</mat-option>
                                        <mat-option value="unTag">Clear Tags</mat-option>
                                        <mat-option value="unRule">Clear Rule</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                            <td style="width: 58%; margin-left: 2%; display: flex; justify-content: space-between; ">
                                <ng-container *ngIf="item.value ==  'addMod' || item.value == 'delMod' ">
                                    <mat-form-field class="example-full-width" appearance="fill" style="width:48%">
                                        <mat-label>Code Name</mat-label>
                                        <input matInput [(ngModel)]="item.codeName">
                                    </mat-form-field>
                                    <mat-form-field class="example-full-width" appearance="fill" style="width:48%">
                                        <mat-label>Modifier</mat-label>
                                        <input matInput [(ngModel)]="item.modifier"
                                               (input)="checkModifierValidation(item)"
                                               pattern="^[A-Za-z0-9\,]*$">
                                        <mat-error *ngIf="!item.isValid">
                                            Modifier input allow chars, digits and ',' only
                                        </mat-error>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngIf="item.value == 'addCpt' || item.value == 'delCpt'">
                                    <mat-form-field class="example-full-width" appearance="fill" style="width:100%">
                                        <mat-label>Code Name</mat-label>
                                        <input matInput [(ngModel)]="item.codeName">
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngIf="item.value == 'addIcd' || item.value == 'delIcd'">
                                    <mat-form-field class="example-full-width"
                                                    appearance="fill" style="width: 100%">
                                        <mat-label>Code Name</mat-label>
                                        <input matInput [(ngModel)]="item.codeName"
                                               (keyup)="searchIcd($event)" debounce="600"
                                               [matAutocomplete]="auto">
                                        <mat-autocomplete autoActiveFirstOption
                                                          #auto="matAutocomplete">
                                            <mat-option *ngFor="let option of arrIcd"
                                                        [value]="option[0]">
                                                <small><b>Code: </b> </small>
                                                <span>{{option[0]}}</span> |
                                                <small><b>Name: </b> </small>
                                                <span>{{option[1]}}</span>
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngIf="item.value == 'updPhys'">
                                    <mat-form-field appearance="fill" style="width: 100%">
                                        <mat-label>Physician</mat-label>
                                        <mat-select [(ngModel)]="item.physician">
                                            <mat-option *ngFor="let o of physicians"
                                                        [value]="o.id">
                                                {{o.text}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngIf="item.value == 'updClinic'">
                                    <mat-form-field appearance="fill" style="width: 9100%">
                                        <mat-label>Clinic</mat-label>
                                        <mat-select [(ngModel)]="item.clinic">
                                            <mat-option *ngFor="let o of clinics"
                                                        [value]="o.text">
                                                {{o.text}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngIf="item.value == 'updOfficeKey'">
                                    <mat-form-field appearance="fill" style="width: 100%">
                                        <mat-label>Office Key</mat-label>
                                        <mat-select [(ngModel)]="item.officeKey">
                                            <mat-option *ngFor="let o of officeKeys"
                                                        [value]="o">
                                                {{o}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngIf="item.value == 'unTag'">
                                    <mat-form-field appearance="fill" style="width: 100%">
                                        <mat-label>Value</mat-label>
                                        <mat-select multiple [(ngModel)]="item.valueTags">
                                            <mat-option *ngFor="let o of visitTags"
                                                        [value]="o">
                                                {{o}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngIf="item.value == 'unRule'">
                                    <mat-form-field appearance="fill" style="width: 100%">
                                        <mat-label>Value Rule</mat-label>
                                        <mat-select multiple [(ngModel)]="item.valueRules">
                                            <mat-option *ngFor="let rule of codeReviewRules"
                                                        [value]="rule.name">
                                                {{rule.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngIf="item.value == 'updFinClass'">
                                    <mat-form-field class="example-full-width"
                                                    appearance="fill" style="width:100%">
                                        <mat-label>Financial Class</mat-label>
                                        <!-- <input matInput [(ngModel)]="item.finClass"> -->
                                        <mat-select [(ngModel)]="item.finClass">
                                            <mat-option *ngFor="let fc of dataFilter.finClasses" [value]="fc.text">
                                                {{fc.text}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngIf="item.value == 'addNote'">
                                    <mat-form-field class="example-full-width w-100" appearance="fill" style="width:100%">
                                        <mat-label>Notes: </mat-label>
                                        <textarea matInput [(ngModel)]="item.note"></textarea>
                                    </mat-form-field>
                                </ng-container>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <ng-container *ngIf="arrAction.length> 0">
                <div class="col-lg-12 mt-1" style="text-align: end">
                    <mat-progress-bar mode="determinate" [value]="currentMsg_ExecuteActBulkUpdate.Value">
                    </mat-progress-bar>
                    <br />
                    <div>
                        {{currentMsg_ExecuteActBulkUpdate.Message}}
                    </div>
                </div>
                <div class="col-lg-12 mt-1" style="text-align: end">
                    <button mat-raised-button class="mr-2 btn-save-set" (click)="showInputName()"> Save Set</button>
                    <button mat-raised-button class="mr-2 btn-clear-all-action" (click)="clearAllAction()"> Clear All Actions</button>
                    <button mat-raised-button color="warn" class="mr-2" (click)="deleteSet()">Delete</button>
                </div>          
            </ng-container>
        </div>
    </div>

    <div class="toast" *ngIf="isShowToast" [ngClass]="classToast">
        <p>{{stringToast}}</p>
    </div>

    <div class="modal-backdrop-custom" [ngClass]="isShowInput ? 'd-block' : 'd-none'">
        <div class="modal-content">
            <!-- Modal content goes here -->
            <mat-form-field class="w-100">
                <mat-label>Name Action Set</mat-label>
                <input matInput placeholder="Set 1" [(ngModel)]="nameBulkActionSet" autofocus>
                <mat-hint class="required" color="warn">Please name the action set</mat-hint>
            </mat-form-field>

            <div style="text-align: end; padding: 5px 10px">
                <button mat-raised-button class="mr-1" (click)="hiddenInputName()">Close</button>
                <button mat-raised-button class="mr-1" color="primary" [disabled]="nameBulkActionSet == ''" (click)="saveSet()">Save</button>
            </div>
        </div>
    </div>


</div>
