import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnotifyService } from 'ng-snotify';

import { ApiService } from '../../../services/api.service';

import * as moment from 'moment';
import * as _ from 'lodash';
import { type } from 'os';

@Component({
    selector: 'app-message-physician-modal',
    templateUrl: './message-physician-modal.component.html',
    styleUrls: ['./message-physician-modal.component.scss']

})
export class MessagePhysicianModalComponent implements OnInit {
    public isLoading: boolean = false
    public userEmail: string = ""
    public additionalEmail: string = ""
    public visitId = 0
    public attchChart: boolean = false
    public files: any = []
    public classMsg = "";
    public msg = "";
    public physiciansEmails=[];
    public selectedPhysicianEmails = "";
    public validForm: boolean
    public emailPattern = new RegExp('^([a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@([Cc][Mm][Uu][Cc][Ss]\.[Cc][Oo][Mm])+)*$')
    public validEmail: boolean

    public validMessage: boolean

    emailModel: any = { subject: '', message: '' }

    sentToPhysicianId: null;

    constructor(
        private matDialog: MatDialog,
        public dialogRef: MatDialogRef<MessagePhysicianModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private apiService: ApiService,
        private snotifyService: SnotifyService,
    ) {
  
    }

    ngOnInit(): void {
        this.visitId = this.data.visitId   
        this.userEmail = this.data.userEmail
        this.emailModel.subject = this.data.emailSubject
        this.emailModel.message = this.data.emailDescription
        this.loadPhysiciansEmails()

        if (this.data.visit) {
            const formattedServiceDate = moment(this.data.visit.serviceDate).format('MM/DD/YYYY');
            this.emailModel.message += `\n- Patient Name: ${this.data.visit.patFirstName} ${this.data.visit.patLastName}.\n` +
                `- DOB: ${this.data.visit.birthday}.\n` +
                `- Service Date: ${formattedServiceDate}.\n` +
                `- Physician Name: ${this.data.visit.physicianName}.\n` +
                `- Clinic: ${this.data.visit.clinicId}.`;

            this.emailModel.message += "\n\n";
            this.emailModel.message +="View chart queue: https://2.txmedreg.com/practitioner-chart-queue/"
        }
    }

    handleFileInput(files: FileList) {
        this.files = files
    }

    loadPhysiciansEmails() {
        this.apiService.getPhysiciansEmails(this.data.officeKey, this.data.visitId).subscribe((r) => {
            this.physiciansEmails = r

            this.physiciansEmails.sort((a, b) => a.amdDisplayName.localeCompare(b.amdDisplayName));
            var results = this.physiciansEmails.filter((e) => { return e.isSigningPhysician == true });
           
            //if (results != null) {
            //    _.each(results, (e) => {
            //        this.selectedPhysicianEmails.push(e.email);
            //    })
            //}

            if (this.data.physicianEmail) {
                this.selectedPhysicianEmails = this.data.physicianEmail
                const physician = _.find(results, r => r.email == this.data.physicianEmail);
                if (physician) {
                    this.sentToPhysicianId = physician.pvPhysicianId;
                }
            }
        });
    }

    checkValid() {
        if (this.additionalEmail) {
            this.validEmail = this.emailPattern.test(this.additionalEmail);
        }

        return !!this.emailModel.subject && !!this.emailModel.message && (this.selectedPhysicianEmails || this.validEmail)
           
    }

    
    //validateForm() {
    //   
    //    this.validForm =  (this.selectedPhysicianEmails.length > 0 || (this.validEmail && !!this.additionalEmail)) ;
    //}

    //blurEvent(event: any) {
    //    this.validateForm();
    //}



    save() {
      this.msg = ""
      this.classMsg = ""
        if (confirm("By pressing send button an email contains changes will be sent to the physican, are you sure to do this?")) {
          this.isLoading = true
          var formData = new FormData();
          for (const file of this.files) {
              formData.append('files', file, file.name)
            }

           //for (const phEmail of this.selectedPhysicianEmails) {
           //   formData.append('toEmails', phEmail);
           //}

            if (Array.isArray(this.selectedPhysicianEmails)) {
                for (const phEmail of this.selectedPhysicianEmails) {
                    formData.append('toEmails', phEmail);
                }
            } else if (typeof this.selectedPhysicianEmails == 'string') {
                formData.append('toEmails', this.selectedPhysicianEmails);       
            }

            formData.append('toEmails', this.additionalEmail);
            formData.append('fromEmail', this.userEmail);
            formData.append('subject', this.emailModel.subject);
            formData.append('body', this.emailModel.message);
            formData.append('defaultChart', JSON.stringify(this.attchChart));
            formData.append('visitId', JSON.stringify(this.visitId));
            formData.append('sentToPhysicianId', JSON.stringify(this.sentToPhysicianId) )

            //console.log(this.emailModel)
          this.apiService.sendNotification(formData).subscribe((r) => {
              console.log('r', r)
             //this.dialogRef.close('saved')
              if (r.success) {
                  this.msg = "Email Sent Successfully!"
                  this.classMsg = "alert-success"
              }
              this.isLoading = false
          }, err => {
              this.msg = "Email Sent Unsuccessfully!"
              this.classMsg = "alert-danger"
          })
      }
    }

    selectPhysicianEmail(physician) {
        this.sentToPhysicianId = physician.pvPhysicianId;
    }
}
