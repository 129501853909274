<h1 mat-dialog-title>
    Visit Return Update
</h1>
<div mat-dialog-content>
    <div class="modal-body mx-3 row" *ngIf="isLoading">
        <div class="col-lg-12">
            <span>Loading...</span>
        </div>
    </div>
    <div class="modal-body" *ngIf="!isLoading">

        <div *ngIf="visit_PvUpdate && visit_PvUpdate?.id">
            <div style="width:100%;">
                <h5 style="font-weight:700">
                    Update Procedure Codes
                </h5>
                <div class="row" style="width:100%; display:inline-flex" *ngIf="oldProcCodes.length !=0 || newProcodes.length !=0">
                    <div style="padding: 0px 15px; width:50%; border-right: 1px solid">
                        <div>
                            <p>Old procCodes</p>
                        </div>
                        <ul style="list-style:none">
                            <li *ngFor="let procCode of oldProcCodes">{{procCode}}</li>
                        </ul>
                    </div>
                    <div style="padding: 0px 15px; width:50%">
                        <div>
                            <p>New procCodes</p>
                        </div>
                        <ul style="list-style:none">
                            <li *ngFor="let procCode of newProcodes">{{procCode}}</li>
                        </ul>
                    </div>
                </div>
                <div class="row" style="width:100%; display:inline-flex; padding:15px;" *ngIf="oldProcCodes.length==0 && newProcodes.length==0">
                    Visit ProcCodes have not changed.
                </div>
            </div>

            <div style="width:100%;">
                <h5 style="font-weight:700">
                    Update ICD Codes
                </h5>
                <div class="row" style="width:100%; display:inline-flex" *ngIf="old_IcdCodes.length !=0 || new_IcdCodes.length !=0">
                    <div style="padding: 0px 15px; width:50%; border-right: 1px solid">
                        <div>
                            <p>Old ICDCodes</p>
                        </div>
                        <ul style="list-style:none">
                            <li *ngFor="let code of old_IcdCodes">{{code}}</li>
                        </ul>
                    </div>
                    <div style="padding: 0px 15px; width:50%">
                        <div>
                            <p>New ICDCodes</p>
                        </div>
                        <ul style="list-style:none">
                            <li *ngFor="let code of new_IcdCodes">{{code}}</li>
                        </ul>
                    </div>
                </div>
                <div class="row" style="width:100%; display:inline-flex; padding:15px;" *ngIf="old_IcdCodes.length ==0 && new_IcdCodes.length ==0">
                    Visit ICDCodes have not changed.
                </div>
            </div>


            <div style="width:100%;">
                <h5 style="font-weight:700">
                    Update Diag Codes
                </h5>
                <div class="row" style="width:100%; display:inline-flex" *ngIf="oldDiagCodes.length !=0 || newDiagCodes.length !=0">
                    <div style="padding: 0px 15px; width:50%; border-right: 1px solid">
                        <div>
                            <p>Old DiagCodes</p>
                        </div>
                        <ul style="list-style:none">
                            <li *ngFor="let code of oldDiagCodes">{{code}}</li>
                        </ul>
                    </div>
                    <div style="padding: 0px 15px; width:50%">
                        <div>
                            <p>New DiagCodes</p>
                        </div>
                        <ul style="list-style:none">
                            <li *ngFor="let code of newDiagCodes">{{code}}</li>
                        </ul>
                    </div>
                </div>
                <div class="row" style="width:100%; display:inline-flex; padding:15px;" *ngIf="oldDiagCodes.length ==0 && newDiagCodes.length ==0">
                    Visit DiagCodes have not changed.
                </div>
            </div>
        </div>      

        <div *ngIf="!visit_PvUpdate || !visit_PvUpdate.id">
            Visit ProcCodes, Visit DiagCodes, Visit ICDCodes have not changed
        </div>
    </div>
</div>
<div mat-dialog-actions style="justify-content: end;">
    <button class="btn btn-secondary" style="margin-right: 5px" mat-dialog-close>Close</button>
</div>
