<h1></h1>

<div class="row">
    <div class="col-12" style="padding: 0px 20px">
        <h1>Activity Log</h1>
    </div>
</div>

<div class="row top-buffer">
    <div class="col-md-12">
        <div class="box box-widget">
            <div class="box-header with-border">
                <h3 class="box-title">Filters</h3>
            </div>
            <div class="box-body">
                <div class="row">
                    <div class="col-md-3">
                        <mat-form-field appearance="fill" style="width: 100%">
                            <mat-label>Search VisitId</mat-label>
                            <input matInput type="text" [(ngModel)]="searchText" placeholder="123456"
                                (input)="searchVisitId()">
                            <button *ngIf="searchText" matSuffix mat-icon-button aria-label="Clear"
                                (click)="searchText=''; clearSearch()">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field appearance="fill" style="width: 100%">
                            <mat-label>Search Patient Name</mat-label>
                            <input matInput type="text" [(ngModel)]="searchPatientName" placeholder="FirstName LastName"
                                (input)="searchVisitId()">
                            <button *ngIf="searchText" matSuffix mat-icon-button aria-label="Clear"
                                (click)="searchText=''; clearSearch()">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field appearance="fill" style="width: 100%">
                            <mat-label>Start Date</mat-label>
                            <input matInput [matDatepicker]="pickerStartDate" [(ngModel)]="startDate">
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            <mat-datepicker-toggle matSuffix [for]="pickerStartDate"></mat-datepicker-toggle>
                            <mat-datepicker #pickerStartDate></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field appearance="fill" style="width: 100%">
                            <mat-label>End Date</mat-label>
                            <input matInput [matDatepicker]="pickerEndDate" [(ngModel)]="endDate">
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            <mat-datepicker-toggle matSuffix [for]="pickerEndDate"></mat-datepicker-toggle>
                            <mat-datepicker #pickerEndDate></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12" style="text-align: right">
                        <button mat-raised-button color="primary" class="mr-1" (click)="filterDate()">Filter</button>
                        <button class="mr-1" mat-raised-button (click)="refetch()">Refetch</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<p *ngIf="isLoading">Loading...</p>
<ng-container *ngIf="!isLoading">
    <section class="timeline-activity" *ngIf="activityLogs.length > 0">
        <dl>
            <ng-container *ngFor="let item of activityLogs; let ind = index">
                <!--[ngClass]="selectedActivityIndex === ind ? 'open' : ''"-->
                <dt class="has-notes open" (click)="viewMore(ind)">
                    <time>
                        <span class="day-month">{{item.createdDateLocal | date : 'HH:mm'}}</span>
                        <span class="badge time badge-time">{{item.createdDateLocal | date : 'MMM dd yyyy'}}</span>
                    </time>
                    <span class="name">
                        <span *ngIf="!!item.firstName"> {{item.firstName}} {{item.lastName}}</span>
                        <span *ngIf="!item.firstName"> {{item.email}}</span>
                        {{item.action}}
                    </span>
                </dt>
                <!-- *ngIf="selectedActivityIndex === ind" -->
                <dd>
                    <ng-container *ngIf="item.methodName == 'ExecuteActBulkUpdate'">
                        <h5>VisitIds: </h5>
                        <div>
                            <span class="badge" *ngFor="let visit of item.visitInfoList" style="position: relative;cursor: pointer;"
                                (mouseout)="mouseOut(visit)" (mouseover)="mouseOver(visit)">
                                {{visit.visitId}} <br>
                                <div class="visit-patient" *ngIf="visit.isVisible">
                                    <span>{{visit.patientName}}</span>
                                </div>
                            </span>
                        </div>
                        <h5>Actions: </h5>
                        <ul class="list-bulk-action">
                            <li *ngFor="let ac of item.parseRequest.DataActions">
                                <strong>{{ac.nameAction}}</strong>{{getValueAction(ac, item.parseRequest)}}
                                <ng-container *ngIf="ac.value == 'unTag'">
                                    <span class="tag" *ngFor="let tag of ac.valueTags">
                                        {{tag}}
                                    </span>
                                </ng-container>
                            </li>
                        </ul>
                    </ng-container>
                </dd>
            </ng-container>
        </dl>
    </section>
    <ng-container *ngIf="activityLogs.length <= 0">
        <div class="row">
            <div class="col-12" style="text-align: center">
                <span style="font-size: 2rem; font-weight: bold;">No Data</span>
            </div>
        </div>
    </ng-container>
</ng-container>
<mat-paginator class="pagination" [length]="total" [pageSize]="pageSize" (page)="changePage($event)">
</mat-paginator>