import { Component, OnInit, Inject } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ApiService } from '../../../services/api.service';

import * as moment from 'moment';
import * as _ from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';
declare var Tiff: any;
declare var $: any;
@Component({
  selector: 'app-view-doc-modal',
    templateUrl: './view-doc-modal.component.html',
    styleUrls: ['./view-doc-modal.component.scss']
})
export class ViewDocModalComponent implements OnInit {
  public separatorKeysCodes = [ENTER, COMMA];
  public visitTags: any = []
  public visitId: number = 0;
    public isLoading: boolean = false;
    public srcChartPdf: any = ""
    public hasImage: boolean = true;
    public srcChartImage: string = ""
    chartId: any;
    chartDocument: any;
  constructor(
    private matDialog: MatDialog,
      public dialogRef: MatDialogRef<ViewDocModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
      private _snackBar: MatSnackBar,
      public sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
      this.visitTags = this.data.visitTags,
          this.visitId = this.data.visitId;
      this.chartId = this.data.chartId;
      console.log(this.chartId)
      this.loadDoc();
    }

    loadDoc() {

        this.srcChartImage = ""
        this.srcChartPdf = ""
        this.isLoading = true;
        this.apiService.loadChartDoc(this.chartId).subscribe(r => {
            this.isLoading = false;
            var blob = new Blob([r], { type: "image/tif" });
            if (blob.size > 0) {
                blob.arrayBuffer().then(r2 => {
                    const tiff = new Tiff({ buffer: r2 });
                    const canvas = tiff.toCanvas();
                    $('.modal-body').append(canvas); // beautify!
                })
            }
            else {
                this.hasImage = false;
            }

        })
    }


  showSnackBar(message) {
    this._snackBar.open(message)
    setTimeout(() => {
      this._snackBar.dismiss()
    }, 1000);
  }

}
