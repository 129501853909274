import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/services/api.service';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DialogLoadingComponent } from '../../dialog-loading/dialog-loading.component';

@Component({
  selector: 'app-update-amd-code-insurance-modal',
  templateUrl: './update-amd-code-insurance-modal.component.html',
  styleUrls: ['./update-amd-code-insurance-modal.component.scss']
})
export class UpdateAmdCodeInsuranceModalComponent implements OnInit {
  public insurances: any;
  public insuranceForm: FormGroup;
  public isSaving: boolean = false;
  public isSubmitted: boolean = false;
  public selectedInsurance: any;
    public carrier = {
        sameOfficeKeys: [],
        differentOfficeKeys : []
    };
    visit = {} as any;

    isLoading = false;
    public isNewInsurance = false;
    public isUpdateInsurance = false;
    public isEditInsName = false;

    public newInsuranceObj = {} as any;
    public payerInformationTypes = [];
    public errorAddPayerInformation = [];
    searchControl1 = new FormControl();
    listInsurances = [] as any;
    searchInsurances: Observable<any[]> | null = null;
    searchMemberIds: Observable<any[]> | null = null;
    closeWithChange = false;

    currentInsEditName = {} as any;
    lookUpInsName = "";
    lookupResponse = {} as any;
    respartyIdSaved = "";
    islookUpResParty = false;

    payerErrorType = '';
    searchCarrierName = {
        keyword: '',
        results: [],
        isShowResults: false,
        isLoading: false,
        messageNotFound : ''
    }

    insuranceOldName: string = '';
    dialogLoading: any;

  constructor(
    public dialogRef: MatDialogRef<UpdateAmdCodeInsuranceModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private fb: FormBuilder,
      private _snackBar: MatSnackBar,
      private matDialog: MatDialog,
  ) {
      //this.setupAutocompleteInsurance();
      //this.setupAutocompleteMemberIds();

      this.payerErrorType = data.payerErrorType
      this.visit = this.data.visit;
  }

    ngOnInit() {
      this.getInsurances();
      this.getInsuranceByVisit();
      this.initForm();
      this.getPayerInformationTypes();
      
    }


    getInsuranceByVisit() {
        this.isLoading = true;
        this.apiService.getInsuranceInformationByVisitId(this.visit.visitId, this.visit.officeKey).subscribe((r: any) => {
            this.insurances = r.sort((a, b) => {
                if (a.isExclude === b.isExclude) {
                    return a.priority - b.priority;
                }
                return a.isExclude ? 1 : -1;
            });
            this.isLoading = false;
        }, err => {
            this.insurances = [];
            this.isLoading = false;
        })
    }

    getPayerInformationTypes() {
        this.apiService.getPayerInformationTypes().subscribe(r => {
            this.payerInformationTypes = r;
        })
    }

    getInsurances() {
        this.searchControl1.valueChanges.subscribe(value => {
            if (!value) {
                this.newInsuranceObj.insuranceId = null;
            }
        });
        this.apiService.getInsurances().subscribe(r => {
            this.listInsurances = r;
            if (this.listInsurances && this.listInsurances.length > 0) {
                this.searchInsurances = this.searchControl1.valueChanges.pipe(startWith(''), map(val => this.filter(val)))
            }
        })
    }


    filter(val: string): string[] {
        console.log(val)
        return _.filter(this.listInsurances, item => item.primaryName.toLowerCase().indexOf(val.toLowerCase()) === 0)
    }

    //private setupAutocompleteInsurance() {
    //    this.searchInsurances = this.searchControl1.valueChanges.pipe(
    //        //debounceTime(300),
    //        //distinctUntilChanged(),
    //        switchMap((key) => {
    //                return this.apiService.searchInsurances(key);
    //        }),
    //        catchError(() => of([]))
    //    );
    //}

    //private setupAutocompleteMemberIds() {
    //    this.searchMemberIds = this.searchControl2.valueChanges.pipe(
    //        debounceTime(300),
    //        distinctUntilChanged(),
    //        switchMap((key) => {
    //            return this.apiService.searchPayerInformationMemberIds(key);
    //        }),
    //        catchError(() => of([]))
    //    );
    //}
    


    initForm() {
    this.insuranceForm = this.fb.group({
      primaryName: ['', [Validators.required, Validators.maxLength(150)]],
      primaryPhone: [''],
      primaryAddress1: ['', [Validators.required]],
      primaryAddress2: [''],
      primaryCity: ['', ],
      primaryState: ['',],
      primaryZip: ['',],
      amdCode: [''],
      isExclude: [false]
    })
    }

    get insuranceF() { return this.insuranceForm.controls }

    editInsurance(insurance) {
        this.isEditInsName = false;
        this.isNewInsurance = false;
        this.isUpdateInsurance = true;

        this.selectedInsurance = insurance;
        this.insuranceOldName = _.clone(insurance.primaryName);

    this.insurances.forEach(e => {
        e.isActive = e.payerInfoId == insurance.payerInfoId ? true : false
    });
    this.selectedInsurance.isFinding = true;
    if (insurance) {
      this.insuranceForm.patchValue({
        primaryName: insurance.primaryName,
        primaryPhone: insurance.primaryPhone,
        primaryAddress1: insurance.primaryAddress1,
        primaryAddress2: insurance.primaryAddress2,
        primaryCity: insurance.primaryCity,
        primaryState: insurance.primaryState,
        primaryZip: insurance.primaryZip,
        amdCode: insurance.amdCode,
        isExclude: insurance.isExclude
      })
    }
    /*  if(!insurance.amdCode){*/
    this.findAmdCodeFormAddress(insurance.primaryAddress1)
    //}
    }

    updateInsuranceExclude() {
        this.isSaving = false
        this.isSubmitted = false
        _.each(this.insurances,insurance => {
            if (insurance.payerInfoId == this.selectedInsurance.payerInfoId) {
                insurance.isExclude = this.insuranceForm.value.isExclude;
                this.apiService.updateInsuranceExlude({ payerInfoId: insurance.payerInfoId, isExclude: insurance.isExclude }).subscribe((r) => {
                    this.isSaving = false
                    this.isSubmitted = false
                    this.showSnackBar('Saved Succesffuly!')
                }, err => {
                    this.isSaving = false
                    this.isSubmitted = false
                    this.showSnackBar('Saved Unsuccesffuly!')
                })
            }
        })
    }   

    findAmdCodeFormAddress(address) {
        this.apiService.findAmdCodeFormAddress(address, this.visit.officeKey).subscribe((r: any) => {
            const sameOfficeKeys = r.filter((item: any) => item.licenseKey.toString() == this.visit.officeKey);
            const differentOfficeKeys = r.filter((item: any) => item.licenseKey.toString() != this.visit.officeKey);

            this.carrier = {
                sameOfficeKeys: sameOfficeKeys,
                differentOfficeKeys: differentOfficeKeys
            };
            this.selectedInsurance.isFinding = false;
            }, err => {
                this.carrier = {
                    sameOfficeKeys: [],
                    differentOfficeKeys: []
                };
            this.selectedInsurance.isFinding = false
        })
    }

    fillAmdCode(amdCode) {
        console.log(amdCode)
        this.searchCarrierName.isShowResults = false;
        this.insuranceForm.patchValue({
          amdCode: amdCode
        })
    }

    onSearchAmdCode(event) {
    this.selectedInsurance.isFinding = true
    this.insuranceForm.patchValue({
      amdCode: null
    })
    this.findAmdCodeFormAddress(this.insuranceForm.value.primaryAddress1)
    }

    onCarrierNameSelected(event: MouseEvent): void {
        this.searchCarrierName.keyword = '';
        const inputElement = event.target as HTMLInputElement;
        const selectedText = inputElement.value.substring(inputElement.selectionStart, inputElement.selectionEnd);
        this.searchCarrierName.keyword = selectedText;
    }

    onInputInsuranceNameChange() {
        this.searchCarrierName.keyword = '';
    }

    onSearchCarrierName() {
        if (!this.searchCarrierName.keyword || this.searchCarrierName.keyword.trim() == '') {
            this.searchCarrierName.keyword = this.insuranceForm.get('primaryName')?.value;
        }
        if (!this.searchCarrierName.keyword || this.searchCarrierName.keyword.trim() == '') {
            return;
        }
        this.searchCarrierName.isLoading = true;
        this.searchCarrierName.isShowResults = true;
        this.apiService.findAmdCodeFromName(this.searchCarrierName.keyword, this.visit.officeKey).subscribe((r: any) => {
            this.searchCarrierName.results = r;
            this.searchCarrierName.isLoading = false;
            this.searchCarrierName.messageNotFound = ''
            if (this.searchCarrierName.results.length == 0) {
                this.searchCarrierName.messageNotFound = `Not found carrier that match keyword: '${this.searchCarrierName.keyword}'`
                this.searchCarrierName.keyword = '';
            }
        }, err => {
            this.searchCarrierName.isShowResults = false;
            this.searchCarrierName.isLoading = false;      
        })
    }

    copySelectedToFrom(carrier) {
        this.insuranceForm.patchValue({
            primaryName: carrier.carrierName,
            primaryAddress1: carrier.address,
            primaryAddress2: carrier.aptSte,
            primaryCity: carrier.city,
            primaryState: carrier.state,
            primaryZip: carrier.zipCode,
            amdCode: carrier.carrierCode,
            isExclude: false,
        });
        this.searchCarrierName.isShowResults = false;
    }

    saveUpdate() {
        this.openLoading();
        this.isSaving = true
        this.isSubmitted = true
        let payload = {
          primaryName: this.insuranceForm.value.primaryName,
          primaryPhone: this.insuranceForm.value.primaryPhone,
          primaryAddress1: this.insuranceForm.value.primaryAddress1,
          primaryAddress2: this.insuranceForm.value.primaryAddress2,
          primaryCity: this.insuranceForm.value.primaryCity,
          primaryState: this.insuranceForm.value.primaryState,
          primaryZip: this.insuranceForm.value.primaryZip,
          amdCode: this.insuranceForm.value.amdCode,
          insuranceId: this.selectedInsurance.insuranceId,
          payerInfoId: this.selectedInsurance.payerInfoId,
          isExclude: this.insuranceForm.value.isExclude,
          officeKey: this.visit.officeKey,
          importedFromEmrId: this.visit.emR_ID,
          insuranceOldName: this.insuranceOldName
        }  

        this.apiService.createAndUpdateInsurance(payload, this.visit.visitId).subscribe((r) => {
            this.isSaving = false
            this.isSubmitted = false
            localStorage.setItem('highlight-visitId', this.visit.visitId)
            this.showSnackBar('Saved Succesffuly!')
            //this.dialogRef.close('saved')
            this.closeWithChange = true;
            this.newInsuranceObj = {} as any;
            this.selectedInsurance = {} as any;
            this.isUpdateInsurance = false;
            this.isNewInsurance = false;
            //this.getInsuranceByVisit();
            this.closeLoading();
            this.dialogRef.close(true);
        }, err => {
            this.isSaving = false
            this.isSubmitted = false
            this.showSnackBar('Saved Unsuccesffuly!');
            this.closeLoading();
        })
    }


  showSnackBar(message) {
    this._snackBar.open(message)
    setTimeout(() => {
      this._snackBar.dismiss()
    }, 1000);
    }

    changeViewAddInsurance() {
        this.newInsuranceObj.visitId = this.visit.visitId;
        this.newInsuranceObj.class = this.visit.finClass;
        this.newInsuranceObj.payerNum = 0;
        this.newInsuranceObj.priority = 1;
        this.newInsuranceObj.isExclude = false;    
        this.newInsuranceObj.patientEMRID = this.visit.patientEMRID;
        this.newInsuranceObj.emR_Id = this.visit.emR_ID;
        this.newInsuranceObj.insName = "";
        this.newInsuranceObj.insuranceId = null;
        this.searchCarrierName.isShowResults = false;
        this.selectedInsurance = {} as any;
        this.insurances.forEach(e => {
            e.isActive = false
        });
        this.isNewInsurance = true;
        this.isUpdateInsurance = false;      
        this.isEditInsName = false;
    }

    selectInsurance(value) {
        this.newInsuranceObj.insuranceId = value.insuranceId;
        this.newInsuranceObj.insName = value.primaryName;
    }

    //selectMemberId(value) {
    //    this.newInsuranceObj.memberId = value;
    //}

    saveAddInsurance() {
        console.log("newInsuranceObj", this.newInsuranceObj)
         this.isSaving = true;
        this.isSubmitted = true;
        this.errorAddPayerInformation = [];
        if (!this.newInsuranceObj.insName) {
            this.isSaving = false;
            return;
        }       
        if (!this.newInsuranceObj.type) {
            this.isSaving = false;
            return;
        }
        if (!this.newInsuranceObj.class) {
            this.isSaving = false;
            return;
        }   
        if (!this.newInsuranceObj.priority) {
            this.isSaving = false;
            return;
        }   
        if (!this.newInsuranceObj.memberId) {
            this.newInsuranceObj.memberId = "";
        }

        this.apiService.addNewPayerInformation(this.newInsuranceObj, this.visit.officeKey).subscribe(r => {
            this.isSaving = false;
            this.isSubmitted = false;
            this.showSnackBar('Saved Succesffuly!')
            this.isNewInsurance = false;
            this.newInsuranceObj = {} as any;
            this.selectedInsurance = {} as any;
            this.closeWithChange = true;
            this.isUpdateInsurance = false;  
            this.getInsuranceByVisit();

           this.dialogRef.close(true);
        }, err => {
            this.isSaving = false
            this.isSubmitted = false
            this.showSnackBar('Saved Unsuccesffuly!')
        })
    }

    closeDialog() {
        this.searchCarrierName.isShowResults = false;
        this.dialogRef.close(this.closeWithChange);
    }

    editInsuranceName(item) {
        this.currentInsEditName = item;
        this.lookUpInsName = item.insName;
        this.searchCarrierName.isShowResults = false;
        this.selectedInsurance = {} as any;
        this.isNewInsurance = false;
        this.isUpdateInsurance = false;
        this.isEditInsName = true;
        this.insurances.forEach(e => {
            console.log(e)
            e.isActive = e.payerInfoId == item.payerInfoId ? true : false
        });

        this.apiService.getGuarantorImportLog(this.visit.visitId).subscribe(r => {
            this.respartyIdSaved = r.amdResponsiblePartyId;
        })
    }

    lookUpResPartyByInsName() {
        if (!this.visit || !this.lookUpInsName) {
            return;
        }
        this.islookUpResParty = true;
        this.apiService.getResLookUpResPartyByName(this.lookUpInsName, this.visit.officeKey).subscribe((response: any) => {
            this.lookupResponse = response;
            this.islookUpResParty = false;
        }, err => {
            this.lookupResponse = {}
            this.islookUpResParty = false;
        });
    }

    saveUpdateGuarantorImportLog(resparty) {
        var payload = {
            visitId: this.visit.visitId,
            respartyId: resparty.Id,
            payerNumber: this.visit.guarantorPayerId
        };
        this.apiService.updateResPartyGuarantorImportLog(payload).subscribe(r => {
            this.respartyIdSaved = r.amdResponsiblePartyId
        })
    }


    dropToUpdatePayersPriority(event: CdkDragDrop<any[]>) {
        moveItemInArray(this.insurances, event.previousIndex, event.currentIndex);
        this.insurances.forEach((item, index) => {
            item.priority = index + 1; 
        });
        this.updatePayersriority();
    }

    updatePayersriority() {
        const payload = {
            visitId: this.visit.visitId, 
            payersPriority: this.insurances.map(item => ({
                id: item.payerInfoId, 
                priority: item.priority
            }))
        };
        this.apiService.updatePayersPriority(payload).subscribe(r => {
            console.log(r);
        }, err => {
            console.log(err);
        })
    }


    openLoading() {
        this.dialogLoading = this.matDialog.open(DialogLoadingComponent, {
            minWidth: '150px',
            disableClose: true
        });
    }

    closeLoading() {
        this.dialogLoading.close()
    }
}
