<h2 mat-dialog-title>Payment Import Log Error</h2>

<mat-dialog-content class="mat-typography">
    <p>Date: {{responseRaw.Lst | date : 'MMM dd yyyy, HH:mm'}}</p>
    <p>Result: {{responseRaw.Results}}</p>
    <p>Error: {{responseRaw.Error}}</p>
    <p>S: {{responseRaw.S}}</p>
    <p *ngIf="responseRaw_XML">responseRaw_XML: {{responseRaw_XML}}</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>