<h1 mat-dialog-title>
  <ng-container *ngIf="!isEdit">
    Add New Physician
  </ng-container>
  <ng-container *ngIf="isEdit">
    Edit Physician
  </ng-container>
</h1>
<div mat-dialog-content>
  <div class="modal-body mx-3" *ngIf="!isLoading">
    <form [formGroup]="physicianForm">
      <div class="form-group" *ngIf="!provider">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>AMD Provider Name</mat-label>
          <mat-select formControlName="amProviderId" (ngModelChange)="changeProvider($event)" required>
            <mat-option *ngFor="let item of mappedProviders" [value]="item.value">
              {{item.text}} | {{item.value}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-group" *ngIf="provider">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>AMD Provider Name</mat-label>
          <input matInput readonly value="{{provider.name}}">
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>AMD Provider Code</mat-label>
          <input matInput formControlName="amdProviderCode" required (input)="checkProviderCode()">
          <mat-error *ngIf="!amdProviderCode.valid">Mismatch ProviderCode with AMD API</mat-error>
            <mat-hint *ngIf="isWarningPCode" align="start" class="warning"><strong>Mismatch ProviderCode with AMD API</strong> </mat-hint>
            <mat-hint *ngIf="isWarningPCode" align="end" class="warning">{{amdProviderCode.value}} (database) | {{codeAPI}} (AMD API)</mat-hint>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>First Name</mat-label>
          <input matInput formControlName="pvFirstName" required (input)="checkPvPhysicianByPvName()">
          <mat-error *ngIf="pvFirstName.errors?.required && pvFirstName.touched">You must enter a value</mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Last Name</mat-label>
          <input matInput formControlName="pvLastName" required (input)="checkPvPhysicianByPvName()">
          <mat-error *ngIf="pvLastName.errors?.required && pvLastName.touched">You must enter a value</mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>PV Physician Number</mat-label>
          <input type="number" matInput formControlName="pvPhysicianId" required (input)="checkPhysicianId()">
          <mat-error *ngIf="pvPhysicianId.errors?.required && pvPhysicianId.touched">You must enter a value</mat-error>
          <mat-error *ngIf="pvPhysicianId.errors?.isExist">PV physician number already exists</mat-error>
          <mat-hint *ngIf="isCheckPvId">Loading...</mat-hint>
        </mat-form-field>
      </div>
      <div class="form-group" class="w-100">
        <mat-checkbox formControlName="isDefault">Default Provider</mat-checkbox>
      </div>
    </form>
  </div>
  <div *ngIf="isLoading">
    <span class="text-gray">Loading...</span>
  </div>
</div>
<div mat-dialog-actions style="justify-content: end;">
  <button class="btn btn-secondary" style="margin-right: 5px" mat-dialog-close>Close</button>
  <button class="btn btn-primary" [disabled]="physicianForm.invalid || isLoading || isCheckPvId" (click)="save()">
    <ng-container *ngIf="!isSaving && !isEdit">Add</ng-container>
    <ng-container *ngIf="isSaving && !isEdit">Adding...</ng-container>
    <ng-container *ngIf="!isSaving && isEdit">Update</ng-container>
    <ng-container *ngIf="isSaving && isEdit">Updating...</ng-container>
  </button>
</div>