import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnotifyService } from 'ng-snotify';

import { ApiService } from '../../../services/api.service';

import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'app-alert-modal',
    templateUrl: './alert-modal.component.html',
    styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent implements OnInit {
  public type: string = ''
  public title: string = ''
  public message: string = ''
  constructor(
    private matDialog: MatDialog,
    public dialogRef: MatDialogRef<AlertModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.type = this.data.type
    this.title = this.data.title
    this.message = this.data.message
  }

}
