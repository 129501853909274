import { ChangeDetectorRef, Component, OnInit, HostListener } from '@angular/core';
import { ApiService } from '../../services/api.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { interval } from 'rxjs';
import { map, retryWhen, take } from 'rxjs/operators';
import { MqttService } from 'ngx-mqtt';
import { MatDialog } from '@angular/material/dialog';
import { AlertModalComponent } from '../modal/alert-modal/alert-modal.component';


@Component({
    selector: 'app-bulk-update',
    templateUrl: './bulk-update.component.html',
    styleUrls: ['./bulk-update.component.scss']
})
export class BulkUpdateComponent implements OnInit {
    finClasses = [];
    officeKeys = [];
    clinics = [];
    physicians = [];
    codeReviewRules = [];
    selectedRules = []
    arrAction = [];
    arrIcd = [];
    selectedSetId = -1
    insInfos = [];

    executeActionDisabled = false;
    progressBarValue = 0;
    dataFilter: any;
    selectedAll: any;

    page: any = { CurrentPage: 0 };
    rowsBulkUpdate = new Array<any>();

    filter = {
        visitStartDate: moment().subtract(1, 'year').toDate(),
        visitEndDate: new Date(),
        clinicIds: [],
        finClassIds: [],
        physicianIds: [],
        officeKeys: [],
        ruleIds: [],
        ruleNames: [],
        tags: [],
        insInfoIds: [],
    };
    arrCheckedRow = [];
    //selectedOfficeKey: string;
    //isShowUpdate: boolean = false;
    isShowSearch: boolean = true;
    isShowToast: boolean = false;
    stringToast: string = "";
    classToast: string = "";
    isLoading: boolean = false;
    loadingTable: boolean = false;
    isAlert: boolean = false;
    messageAlert: string = "";
    classAlert: string = "";
    nameBulkActionSet: string = "";
    isShowInput: boolean = false;
    listActionExecuted = []
    listActions = [
        { value: "addMod", name: "Add Modifier" },
        { value: "delMod", name: "Delete Modifier" },
        { value: "addCpt", name: "Add CPT" },
        { value: "delCpt", name: "Delete CPT" },
        { value: "addIcd", name: "Add ICD" },
        { value: "delIcd", name: "Delete ICD" },
        { value: "updPhys", name: "Update Physicin" },
        { value: "updOfficeKey", name: "Update OfficeKey" },
        { value: "unFlag", name: "Clear Flag" },
        { value: "updClinic", name: "Update Clinic" },
        { value: "unTag", name: "Clear Tag" },
        { value: "unRule", name: "Clear Rule" },
        { value: "updFinClass", name: "Update Fin Class" },
        { value: "addNote", name: "Add Note" },
    ]
    bulkActionSets = []
    selectedBulkAction: any;
    visitTags = []
    userId: string = "";
    currentMsg_saveBulkUpdate = "";
    currentMsg_ExecuteActBulkUpdate: any = {value:0,message :""};
    public bulkVisitLogId: string = '';
    public currentTabIndex = 0;

    @HostListener('window:beforeunload', ['$event'])

    unloadNotification($event: any) {
        if (this.listActionExecuted.length > 0) {
            $event.preventDefault();
            $event.returnValue = "";
            return $event;
        }
    }

    isRulesAutomationRunning = false;

    constructor(
        private apiService: ApiService, private cdr: ChangeDetectorRef,
        private _mqttService: MqttService, private matDialog: MatDialog,
    ) {
        this.page.PageSize = 500
        this.userId = localStorage.getItem('userId')
    }

    ngOnInit(): void {
        //this.getFinClass()
        this.getDataAction();
        this.getDataFilter();
        this.getBulkActionSet();
        this.checkRuleAutomationRunning();
        this.subscribeToMQTT();
    }

    subscribeToMQTT() {
        this._mqttService.observe("SaveBulkUpdateRoom").subscribe(r => {
            if (r) {
                this.currentMsg_saveBulkUpdate = r.payload.toString();
                if (this.currentMsg_saveBulkUpdate == "BulkSave Success!") {
                    this.currentMsg_saveBulkUpdate = '';
                }
            }

        });
        this._mqttService.observe("ExecuteActBulkUpdateRoom").subscribe(r => {
            if (r) {
                this.currentMsg_ExecuteActBulkUpdate = JSON.parse(r.payload.toString());
                console.log(this.currentMsg_ExecuteActBulkUpdate)

            }

        });
    }

    onTabChange(event: any) {
        if (!this.currentTabIndex || event.index != this.currentTabIndex) {
            this.currentTabIndex = event.index;
        }
    }

    //getFinClass() {
    //    this.apiService.getFinClasses().subscribe((r) => {
    //        this.finClasses = r;
    //        this.finClasses = _.orderBy(this.finClasses,'text')
    //        this.isLoading = false
    //    });
    //}
    gotoLinkedRecord(visitId) {
        window.open("/record/loadcodeNew?visitId=" + visitId, "_blank")
    }

    getDataFilter() {
        this.isLoading = true
        this.apiService.getDataBulkUpdate().subscribe((r) => {
            this.dataFilter = r

            this.dataFilter.finClasses = _.uniqBy(this.dataFilter.finClasses, 'text');
            
            this.isLoading = false
        })
    }

    getDataAction() {
        this.apiService.getAllOfficeKeys().subscribe((r) => {
            this.officeKeys = r || []
        })

        this.apiService.getClinics().subscribe((r) => {
            this.clinics = r || []
        })

        this.apiService.getAllPhysicians().subscribe((r) => {
            this.physicians = r || []
        })
        this.apiService.getCodeReviewRuleAll().subscribe((res) => {
            let groupRuleName = _.groupBy(res, 'ruleName')
            let ruleName = Object.keys(groupRuleName)

            this.codeReviewRules = []
            let itemNoRule = {
                name: 'No Rules',
                ruleIds: [-1],
                isSelected: false
            }
            this.codeReviewRules.push(itemNoRule)

            _.each(ruleName, (name) => {
                let itemRule = {
                    name: name,
                    ruleIds: _.map(groupRuleName[name], 'id'),
                    isSelected: false
                }
                this.codeReviewRules.push(itemRule)
            })
        })
        this.apiService.getVisitTags().subscribe(res => {
            this.visitTags = res || []
        })
         //this.apiService.getCodeReviewRuleByUser().subscribe((res) => {
        //    let groupRuleName = _.groupBy(res, 'ruleName')
        //    let ruleName = Object.keys(groupRuleName)
        //    this.codeReviewRules = []
        //    _.each(ruleName, (name) => {
        //        let itemRule = {
        //            name: name,
        //            ruleIds: _.map(groupRuleName[name], 'ruleId'),
        //            isSelected: false
        //        }
        //        this.codeReviewRules.push(itemRule)
        //    })
        //})
    }

    getBulkActionSet() {
        this.apiService.getAllBulkActionSet().subscribe((r) => {
            this.bulkActionSets = r || []
        })
    }

    loadData(pageInfo) {
        this.loadingTable = true
        this.apiService.getRecordVisit(this.filter, pageInfo).subscribe((r) => {
            console.log("r", r)
            this.rowsBulkUpdate = _.each(r, (e) => {
                e.isChecked = false
            })
            this.loadingTable = false
        })
    }

    //loadRowCount(pageInfo) {
    //    this.apiService.getRecordVisit(this.filter, pageInfo).subscribe((r) => {
    //        this.page.RowCount = r.total;
    //    })
    //}

    refetch() {
        //this.isShowSearch = false;
        this.filter = {
            visitStartDate: moment().subtract(1, 'year').toDate(),
            visitEndDate: new Date(),
            clinicIds: [],
            finClassIds: [],
            physicianIds: [],
            officeKeys: [],
            ruleIds: [],
            ruleNames: [],
            tags: [],
            insInfoIds: [],
        };
        this.searchClass()
    }

    searchClass() {
        var pageInfo = {
            count: 0,
            limit: 500,
            offset: 0,
            pageSize: 500
        }
        let ruleIds = []
        // if (this.selectedRules.length > 0) {
        //     _.each(this.selectedRules, (e) => {
        //         ruleIds = ruleIds.concat(e.ruleIds)
        //     })
        // }
        // this.filter.ruleNames = this.selectedRules?.length > 0 ? _.map(this.selectedRules, 'name') : []
        this.filter.ruleNames = _.filter(this.selectedRules, (e) => {return e != 'all'})
        this.page.CurrentPage = 0
        this.loadData(pageInfo)
        //this.loadRowCount(pageInfo)
    }

    //getPage(event) {
    //    this.page.CurrentPage = event.offset
    //    this.loadData(event)
    //}

    checkedRow(event, row) {
        row.isChecked = event;
        if (row.isChecked == true) {
            this.arrCheckedRow.push(row)
        } else if (this.arrCheckedRow.length > 0) {
            this.arrCheckedRow = this.arrCheckedRow.filter((e) => { return e.isChecked == true })
        }

    }

    selectedAllRow(event) {
        if (event == 1) {
            this.rowsBulkUpdate = _.each(this.rowsBulkUpdate, (e) => {
                e.isChecked = true
            })

            this.arrCheckedRow = this.rowsBulkUpdate

        } else if (event == 2) {
            this.rowsBulkUpdate = _.each(this.rowsBulkUpdate, (e) => {
                e.isChecked = false
            })

            this.arrCheckedRow = []
        }
    }

    addAction() {
        var item = {
            value: ""
        }
        this.arrAction.push(item);
    }

    deleteItem(index) {
        this.arrAction = this.arrAction.filter((e, i) => {
            return i != index
        })
    }

    refreshItem(index) {
        this.arrAction = _.each(this.arrAction, (e, i) => {
            if (i == index) {
                e.value = ""
                e.codeName = "";
                e.modifier = "";
                e.physician = "";
                e.clinic = "";
                e.officeKey = "";
                e.valueTags = [];
                e.valueRules = []
            }
        })
    }

    changeAction(event, index) {
        this.arrAction.forEach((e, i) => {
            if (i == index) {
                e.id = index;
                e.value = event
                e.codeName = "";
                e.modifier = "";
                e.codeName = "";
                e.physician = "";
                e.clinic = "";
                e.officeKey = "";
                e.finClass = "";
                e.valueTags = [];
                e.valueRules = [];
            }         
        })
    }

    searchIcd(event) {
        if (event.target.value.length > 0) {
            this.apiService.getIcds(event.target.value).subscribe((r) => {
                if (r) {
                    this.arrIcd = r[3]
                }
            })
        }
    }

    showInputName() {
        if (this.checkValidationArr()) {
            return;
        }
        this.isShowInput = true;
    }
    hiddenInputName() {
        this.isShowInput = false;
    }

    saveSet() {
        var payload = {
            actionJson: JSON.stringify(this.arrAction),
            name: this.nameBulkActionSet,
            userId: this.userId,
            bulkVisitLogId: this.bulkVisitLogId 
        }
        this.apiService.saveBulkActionSet(payload).subscribe((r) => {
            if (r.success) {
                this.showToast(r.message, 'success')
                this.getBulkActionSet()
                this.hiddenInputName()
            } else {
                this.showToast(r.message, 'danger')
                this.hiddenInputName()
            }
        })
    }

    deleteSet() {
        if (confirm('You are about to delete an action set, are you sure?')) {
            var payload = {
                id: this.selectedSetId,
                userId: this.userId
            }
            this.apiService.deleteBulkActionSet(payload).subscribe((r) => {
                if (r.success) {
                    this.showToast(r.message, 'success')
                    this.getBulkActionSet()
                    this.hiddenInputName()
                } else {
                    this.showToast(r.message, 'danger')
                    this.hiddenInputName()
                }
            })
        }

    }

    changeBulkAction() {
        let actions = this.selectedBulkAction ? JSON.parse(this.selectedBulkAction.actionJson) : []
        this.arrAction = actions;
        this.selectedSetId = this.selectedBulkAction ? JSON.parse(this.selectedBulkAction.id) : -1
    }

    checkModifierValidation(item) {
        let pattern = /^[A-Za-z0-9\,]*$/g;
        item.isValid = item.modifier !== '' && pattern.test(item.modifier) ? true : false
    }

    checkValidationArr() {
        let modifier = _.find(this.arrAction, (e) => {
            return (e.value == "addMod" || e.value == "delMod") && !e.isValid
        })
        console.log(modifier)
        return !!modifier ? true : false
    }

    executeAction() {
        this.listActionExecuted = _.cloneDeep(this.arrAction)
        _.each(this.listActionExecuted, (e) => {
            var infoAction = _.find(this.listActions, (action) => { return action.value == e.value })
            e.nameAction = infoAction ? infoAction.name : ""
        })
        this.listActionExecuted = _.unionBy(this.listActionExecuted, 'value')
        if (this.arrCheckedRow.length > 0 && this.arrAction.length > 0) {
            var visitIds = _.map(this.arrCheckedRow, 'visitId')

            this.loadingTable = true
            //const visitIds_chunks = _.chunk(visitIds, 20);
            //var apiCount = 0;
            this.executeActionDisabled = true;
            this.checkRuleAutomationRunning();
            this.progressBarValue = 5;
            //for (let i = 0; i < visitIds_chunks.length; i++) {
            //_.each(visitIds_chunks, visitIdschunked => {

                var payloadChunked = {
                    visitIds: visitIds,
                    dataActions: this.arrAction,
                    userId: this.userId
                }
                this.apiService.executeActBulkUpdate(payloadChunked).subscribe((r) => {
                    //this.progressBarValue = apiCount / visitIds_chunks.length * 100
                    // this.cdr.detectChanges();
                    //if (apiCount == visitIds_chunks.length) {
                        this.executeActionDisabled = false;
                        this.loadingTable = false
                        // this.showToast(r.message, 'success')
                        if(r?.data){
                           this.bulkVisitLogId = r.data.id
                           let status = r.data.countVisitIdSelected == r.data.countVisitIdExecute ? 'success' : 'warning' 
                           this.showAlertModal("Execute Bulk Update", `${r.data.countVisitIdSelected} visits selected, ${r.data.countVisitIdExecute} visits saved`, status)
                        }
                    //}
                }, err => {
                    this.loadingTable = false
                    this.executeActionDisabled = false;
                })
            //});

        } else {
            this.showAlert("Please select the data to update", "warning")
        }
    }

    saveBulk() {
        if (this.arrCheckedRow.length > 0) {
            var visitIds = _.map(this.arrCheckedRow, 'visitId')


            this.loadingTable = true

            this.cdr.detectChanges();
            let payload = {
                visitIds: visitIds,
                bulkVisitLogId: this.bulkVisitLogId
            }
            this.apiService.saveBulkUpdate(payload).subscribe((r) => {
                this.listActionExecuted = []
                this.currentMsg_saveBulkUpdate = '';
                this.arrAction = [];
                this.arrCheckedRow = []
                this.cdr.detectChanges();
                this.searchClass()

                this.page.CurrentPage = 0
                if (r?.success && r?.data) {
                    this.bulkVisitLogId = ''
                    let status = r.data.countVisitIdSelected == r.data.countVisitBulkSave ? 'success' : 'warning'
                    this.showAlertModal("Bulk Save", `${r.data.countVisitIdSelected} visits selected, ${r.data.countVisitBulkSave} visits saved`, status)
                }
            })
        } else {
            this.showAlert("Please select the data to update", "warning")
        }
    }

    showAlert(message, style) {
        switch (style) {
            case "info":
                this.classAlert = "alert-info"
                break;
            case "success":
                this.classAlert = "alert-success"
                break;
            case "danger":
                this.classAlert = "alert-danger"
                break;
            case "warning":
                this.classAlert = "alert-warning"
                break;
            default:
                this.classAlert = "alert-light"
                break;
        }
        this.isAlert = true
        let retrySeconds = 3;
        this.messageAlert = message
        interval(1000).pipe(
            map(i => { retrySeconds - i; retrySeconds--; }),
            take(retrySeconds)
        ).subscribe(i => {
            if (retrySeconds == 0) {
                this.isAlert = false;
                this.messageAlert = "";
                this.classAlert = "alert-light"
            }
        });
    }

    showToast(message, style) {
        switch (style) {
            case "info":
                this.classToast = "toast-info"
                break;
            case "success":
                this.classToast = "toast-success"
                break;
            case "danger":
                this.classToast = "toast-danger"
                break;
            case "warning":
                this.classToast = "toast-warning"
                break;
            default:
                this.classToast = "toast-light"
                break;
        }
        this.isShowToast = true
        this.stringToast = message;
        setTimeout(() => {
            this.isShowToast = false;
            this.stringToast = "";
            this.classAlert = "toast-light";
        }, 3000)
    }


    validateActions() {
        var result = true;
        _.each(this.arrAction, item => {
            if (item.value == 'updOfficeKey' && !item.officeKey) {
                result = false;
            }
            if (item.value == '' && this.arrAction.length == 1) {
                result = false;
            }
            if ((item.value == "addMod" || item.value == "delMod") && !item.isValid) {
                result = false;
            }
        })
        return result;
    }

    selectCodeRule(event) {
        if (_.includes(this.selectedRules, 'all')) {
            this.selectedRules = _.uniq(this.selectedRules.concat(_.map(this.codeReviewRules, 'name')))
            //this.selectedRules = _.uniq([...this.selectedRules, ..._.map(this.codeReviewRules, 'name')]);
        } else {
          this.selectedRules = []
        }
    }

    clearAllAction(){
        if (confirm('You are about to delete all actions, are you sure?')) {
            this.arrAction = []
        }
    }

    showAlertModal(title, message, status) {
        const dialogRef = this.matDialog.open(AlertModalComponent, {
            minWidth: '600px',
            data: {
                title: title,
                type: status,
                message: message
            },
        });

        dialogRef.afterClosed().subscribe(result => {

        });
    }

    checkRuleAutomationRunning() {
        this.apiService.isRunningAutomation().subscribe((r: any) => {
            this.isRulesAutomationRunning = r.running;
            console.log("check run...", this.isRulesAutomationRunning)
        });
    }
}
