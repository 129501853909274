<h1 mat-dialog-title>Update Visit</h1>
<div mat-dialog-content>
    <div style="width: 100%; display: flex; justify-content: center" *ngIf="isLoading">
        <mat-spinner></mat-spinner>
    </div>

    <div style="width: 100%;" *ngIf="!isLoading">
        <div style="width: 100%; display: flex; justify-content: space-between;">
            <p style="color:red" *ngIf="error">{{error}}</p>
        </div>
            <div style="width: 100%; display: flex; justify-content: space-between;">
                <div style="width:45%">
                    <label>Current Physician: {{oldFinClass.displayName}}</label>
                    <mat-form-field appearance="fill" style="width: 100%">
                        <mat-label>Physician</mat-label>
                        <mat-select [(ngModel)]="currentPhysicianId">
                            <mat-option *ngFor="let p of allPhysicians" [value]="p.pvPhysicianId">
                                {{p.displayName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div style="width:45%">
                    <label>Current FinClass : {{data.visit.finClass}}</label>
                    <mat-form-field appearance="fill" style="width: 100%">
                        <mat-label>New FinClass</mat-label>
                        <mat-select [(ngModel)]="currentFinClass">
                            <mat-option *ngFor="let f of allFinClasses" [value]="f.text">
                                {{f.text}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>

    </div>
<div mat-dialog-actions style="justify-content: end;">
    <button class="btn btn-secondary" style="margin-right: 5px" mat-dialog-close>Close</button>
    <button class="btn btn-primary" [disabled]="isLoading" (click)="saveVisit()">
        <ng-container *ngIf="!isLoading" >Save</ng-container>
        <ng-container  *ngIf="isLoading" >Saving...</ng-container>
    </button>
</div>