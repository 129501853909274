import { error } from '@angular/compiler/src/util';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import { SnotifyService } from 'ng-snotify';
import { ApiService } from '../../../services/api.service';

@Component({
    selector: 'app-add-clinic-modal',
    templateUrl: './add-clinic-modal.component.html',
    styleUrls: ['./add-clinic-modal.component.scss']

})
export class AddClinicModalComponent implements OnInit {

    public isLoading = false;
    clinicId = '';
    clinicFullName = '';
    amdCodeName = '';
    public warning = '';
    public allOfficeKeys = [];
    public allFacilities = [];
    constructor(public dialogRef: MatDialogRef<AddClinicModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private apiService: ApiService,
                private snotifyService: SnotifyService
    ) { }

    ngOnInit() {
        //console.log(this.data)
        this.allOfficeKeys = this.data.allOfficeKeys;  
        //_.filter(this.data.allOfficeKeys, x => x.value != '136989');
        this.allFacilities = this.data.allAMDCodes;
    }


    saveAdd() {
        this.warning = '';
        if (!this.clinicId || this.clinicId == '') {
            this.warning = 'Clinic can not be null';
            return;
        }
        if (!this.amdCodeName || this.amdCodeName == '') {
            this.warning = 'Office Key Location can not be null';
            return;
        }
        this.isLoading = true;
        var clinicProfiles = [];
        _.each(this.allOfficeKeys, o => {
            if (o.value != '136989') {
                clinicProfiles.push(
                    {
                        clinicId: o.value == '132677' ? ('FP' + this.clinicId) : this.clinicId,
                        clinicFullName: this.clinicFullName,
                        amdCodeName: this.amdCodeName,
                        officeKey: o.value
                    }
                );
            }
        });

        this.apiService.addNewClinics(clinicProfiles).subscribe(r => {
            if (r) {
                this.isLoading = false;
                this.dialogRef.close(true);
                this.snotifyService.success("Add Clinic Successfully!");
            }
        }, error => {
            console.log(error);
            this.warning = error.error.Message;
        })
        //console.log(this.newClinic);
       
    }

}
