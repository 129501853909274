<h1 mat-dialog-title>Tag</h1>
<div mat-dialog-content>
    <div class="modal-body mx-3">
        <mat-form-field style="width: 100%">
            <mat-chip-list #chipList appearance="fill">
                <mat-chip *ngFor="let item of visitTags; let i=index" [selectable]="true" [removable]="true"
                    (removed)="removeTagChip(item, i)" name="chips">{{item}}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input placeholder="Enter Tag" [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes " [matChipInputAddOnBlur]="true "
                    (matChipInputTokenEnd)="addTag($event) " />
            </mat-chip-list>
        </mat-form-field>
    </div>
</div>
<div mat-dialog-actions style="justify-content: end;">
    <button class="btn btn-secondary" style="margin-right: 5px" mat-dialog-close>Close</button>
    <button class="btn btn-primary" (click)="saveVisitTag()" [disabled]="isLoading">
        <ng-container *ngIf="!isLoading">Save</ng-container>
        <ng-container *ngIf="isLoading">Saving...</ng-container>
    </button>
</div>