import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';

//module
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter'
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { ChartsModule } from 'ng2-charts';
import { ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatMenuModule } from '@angular/material/menu'
import { MatCardModule } from '@angular/material/card';

//page
import { ActivityLogComponent } from '../components/activity-log/activity-log.component';
import { RulesAutomationComponent } from '../components/rules-automation/rules-automation.component';
import { EmailAutomationComponent } from '../components/email-automation/email-automation.component';
import { MissingChartFilesComponent } from '../components/missing-chart-files/missing-chart-files.component';
import { ImportLogsComponent } from '../components/import-logs/import-logs.component';
import { DebugToolComponent } from '../components/debug-tool/debug-tool.component';
import { BulkUpdateComponent } from '../components/bulk-update/bulk-update.component'
import { ImportRecordComponent } from '../components/import-record/import-record.component';
import { CodeRecordViewComponent } from '../components/code-record-view/code-record-view.component';
import { CodeReviewRuleComponent } from '../components/code-review-rule/code-review-rule.component';
import { DashboardComponent } from '../components/dashboard/dashboard.component';
import { PhysiciansComponent } from '../components/physicians/physicians.component';
import { InsuranceInformationComponent } from 'src/components/insurance-information/insurance-information.component';

//dialog
import { DialogLoadingComponent } from '../components/dialog-loading/dialog-loading.component';
import { DialogImportLogComponent } from '../components/dialog-payment-import-log/dialog-payment-import-log.component';
import { AddCPTCodeRecordModalComponent } from '../components/modal/add-cpt-code-record-modal/add-cpt-code-record-modal.component';
import { AddICDCodeRecordModalComponent } from '../components/modal/add-icd-code-record-modal/add-icd-code-record-modal.component';
import { MessagePhysicianModalComponent } from '../components/modal/message-physician-modal/message-physician-modal.component';
import { UploadChartModalComponent } from '../components/modal/upload-chart-modal/upload-chart-modal.component';
import { AddPhysicianModalComponent } from '../components/modal/add-physician-modal/add-physician-modal.component';
import { ProcessLogErrorDetailModalComponent } from '../components/modal/process-log-error-detail-modal/process-log-error-detail-modal.component';
import { VisitTagModalComponent } from 'src/components/modal/visit-tag-modal/visit-tag-modal.component';
import { NewUpdateInsuranceInformationComponent } from 'src/components/modal/new-update-insurance-information/new-update-insurance-information.component';
import { UpdateAmdCodeInsuranceModalComponent } from 'src/components/modal/update-amd-code-insurance-modal/update-amd-code-insurance-modal.component';
import { UpdateVisitProcCodeModalComponent } from 'src/components/modal/update-visit-proc-code-modal/update-visit-proc-code-modal.component';
import { VisitRuleSetModalComponent } from 'src/components/modal/visit-rule-set-modal/visit-rule-set-modal.component';
import { UpdateVisitChartDocModalComponent } from 'src/components/modal/update-visit-chart-doc-modal/update-visit-chart-doc-modal.component';
import { UpdateProcConfirmComponent } from '../components/modal/update-visit-proc-code-modal/update-proc-confirm/update-proc-confirm.component';
import { UpdateDiagCodeModalComponent } from '../components/modal/update-diag-code-modal/update-diag-code-modal.component';
import { UpdateDiagConfirmComponent } from '../components/modal/update-diag-code-modal/update-diag-confirm/update-diag-confirm.component';
import { UpdateModifierCodeModalComponent } from '../components/modal/update-modifier-code-modal/update-modifier-code-modal.component';
import { UpdateModifierConfirmComponent } from '../components/modal/update-modifier-code-modal/update-modifier-confirm/update-modifier-confirm.component';
import { ResetVisitModalComponent } from '../components/modal/reset-visit-modal/reset-visit-modal.component';
import { ViewDocModalComponent } from '../components/modal/view-doc-modal/view-doc-modal.component';
import { AlertModalComponent } from '../components/modal/alert-modal/alert-modal.component';
import { ConfirmUpdateRulesDialog } from '../components/code-review-rule/confirm-update-rules/confirm-update-rules-dialog.component';

import { IMqttServiceOptions, MqttModule } from 'ngx-mqtt';
import { environment } from '../environments/environment';
import { ManageClinicComponent } from '../components/manage-clinic/manage-clinic.component';
import { AddClinicModalComponent } from '../components/modal/add-clinic-modal/add-clinic-modal.component';
import { SettingClinicComponent } from '../components/setting-clinic/setting-clinic.component';
import { UpdateClinicModalComponent } from '../components/modal/update-clinic-modal/update-clinic-modal.component';
import { EditVisitModalComponent } from '../components/modal/edit-visit-modal/edit-visit-modal.component';
import { ActionSetupManagementComponent } from '../components/action-setup-management/action-setup-management.component';
import { AddendumSettingModalComponent } from '../components/modal/addendum-setting/addendum-setting.component';
import { AddendumSetupComponent } from '../components/addendum-setup/addendum-setup.component';
import { AddEditAddendumDialogComponent } from '../components/modal/add-edit-addendum-dialog/add-edit-addendum-dialog.component';
import { ViewVisitPvUpdateModalComponent } from '../components/import-record/view-visit-pvUpdate-modal/view-visit-pvUpdate-modal.component';
import { ConfirmModalComponent } from '../components/modal/confirm-modal/confirm-modal.component';
import { UpdateGuarantorInformationModalComponent } from '../components/modal/update-guarantor-information-modal/update-guarantor-information-modal.component';

export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
    hostname: "mrm.cmucs.com".replace("https://", "").replace("http://", "").split(':')[0],
    path: '/mqtt',
    protocol: "wss",
    port: "mrm.cmucs.com/mqtt".indexOf('localhost') > -1 ? 44376 : 443
}
export const MQTT = MqttModule.forRoot(MQTT_SERVICE_OPTIONS);


@NgModule({
    declarations: [
        AppComponent,
        ImportRecordComponent,
        ImportLogsComponent,
        DebugToolComponent,
        BulkUpdateComponent,
        CodeReviewRuleComponent,
        DialogImportLogComponent,
        ActivityLogComponent,
        DialogLoadingComponent,
        CodeRecordViewComponent,
        AddCPTCodeRecordModalComponent,
        AddICDCodeRecordModalComponent,
        MessagePhysicianModalComponent,
        UploadChartModalComponent,
        DashboardComponent,
        PhysiciansComponent,
        AddPhysicianModalComponent,
        ProcessLogErrorDetailModalComponent,
        VisitTagModalComponent,
        InsuranceInformationComponent,
        NewUpdateInsuranceInformationComponent,
        UpdateAmdCodeInsuranceModalComponent,
        UpdateVisitProcCodeModalComponent, UpdateProcConfirmComponent,
        UpdateDiagCodeModalComponent, UpdateDiagConfirmComponent,
        UpdateModifierCodeModalComponent, UpdateModifierConfirmComponent,
        VisitRuleSetModalComponent,
        UpdateVisitChartDocModalComponent,
        ResetVisitModalComponent,
        ViewDocModalComponent,
        RulesAutomationComponent,
        EmailAutomationComponent,
        MissingChartFilesComponent,
        AlertModalComponent,
        ConfirmUpdateRulesDialog,
        ManageClinicComponent,
        AddClinicModalComponent,
        SettingClinicComponent,
        UpdateClinicModalComponent,
        EditVisitModalComponent,
        ActionSetupManagementComponent,
        AddendumSettingModalComponent,
        AddendumSetupComponent,
        AddEditAddendumDialogComponent,
        ViewVisitPvUpdateModalComponent,
        ConfirmModalComponent,
        UpdateGuarantorInformationModalComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        AppRoutingModule,
        NgxDatatableModule,
        NoopAnimationsModule,
        MatSelectModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, MatNativeDateModule, MatTabsModule, MatButtonModule, MatIconModule,
        MatCheckboxModule, MatTooltipModule, MatDialogModule, MatProgressSpinnerModule, MatSlideToggleModule, MatRadioModule, MatAutocompleteModule,
        MatDividerModule, MatMenuModule, MatCardModule,
        MatProgressBarModule, MatPaginatorModule, MatMomentDateModule,
        DragDropModule, MatSnackBarModule,
        SnotifyModule.forRoot(),
        ChartsModule,
        ReactiveFormsModule,
        MatChipsModule,
        BrowserAnimationsModule,
        MQTT
    ],
    exports: [
        DialogImportLogComponent,
        DialogLoadingComponent,
        AddCPTCodeRecordModalComponent,
        AddICDCodeRecordModalComponent,
        MessagePhysicianModalComponent,
        UploadChartModalComponent,
        AddPhysicianModalComponent,
        ProcessLogErrorDetailModalComponent,
        VisitTagModalComponent,
        NewUpdateInsuranceInformationComponent,
        UpdateAmdCodeInsuranceModalComponent,
        UpdateVisitProcCodeModalComponent, UpdateProcConfirmComponent,
        UpdateDiagCodeModalComponent, UpdateDiagConfirmComponent,
        UpdateModifierCodeModalComponent, UpdateModifierConfirmComponent,
        VisitRuleSetModalComponent,
        UpdateVisitChartDocModalComponent,
        ResetVisitModalComponent,
        ViewDocModalComponent,
        AlertModalComponent,
        ViewVisitPvUpdateModalComponent
    ],
    providers: [
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        SnotifyService,
        { provide: 'SnotifyToastConfig', useValue: ToastDefaults },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
