<div class="row">
    <div class="col-md-3 col-sm-12 tab-menu">
        <div *ngFor="let tab of tabs;" class="tab-button" [ngClass]="{ 'tab-item': true, 'active': tabActiveId == tab.id }" (click)="selectTab(tab.id)">
            <span class="item-name">{{ tab.displayName }}</span>
        </div>
    </div>

    <div class="col-md-9 col-sm-12 tab-content">
        <!-- Visit Report -->
        <ng-container *ngIf="tabActiveId == 'visit-report'">
            <div style=" background: #fff; padding: 10px">
                <div class="row" style="min-height:75px">
                    <div class="col-md-3">
                        <mat-form-field>
                            <mat-label>Start Date</mat-label>
                            <input matInput [matDatepicker]="picker" [(ngModel)]="filterVisitReport.startDate" [max]="filterVisitReport.endDate" [readonly]="true">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field>
                            <mat-label>End Date</mat-label>
                            <input matInput [matDatepicker]="pickerEnd" [(ngModel)]="filterVisitReport.endDate" [min]="filterVisitReport.startDate" [max]="today" [readonly]="true">
                            <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                            <mat-datepicker #pickerEnd></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field appearance="fill" style="width: 100%">
                            <mat-label>Send to MRM Status</mat-label>
                            <mat-select multiple [(ngModel)]="filterVisitReport.lstSendToMRMStatus">
                                <mat-option *ngFor="let sendMrmStatusOpt of sendToMrmStatusOptions" [value]="sendMrmStatusOpt">{{sendMrmStatusOpt}} </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field appearance="fill" style="width: 100%">
                            <mat-label>Send To AMD Status</mat-label>
                            <mat-select multiple [(ngModel)]="filterVisitReport.lstsendToAMDStatus">
                                <mat-option *ngFor="let sendAMDStatusOpt of sendToAMDStatusOptions" [value]="sendAMDStatusOpt">{{sendAMDStatusOpt}} </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 d-flex">
                        <button type="submit" class="btn btn-primary" style="float:right; margin:0px 5px" (click)="downloadVisitReport()">Download Report</button>
                        <button type="submit" class="btn btn-primary" style="float: right; margin: 0px 5px " (click)="previewVisitReport()">Preview Report</button>
                    </div>
                </div>
            </div>

            <div style="background: transparent; margin: 0px; padding: 0" *ngIf="reportVisitResult.pending">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>

            <div style="background: white;margin-top:10px;" class="preview-visit-report">
                <table>
                    <thead>
                        <tr>
                            <th>VisitId</th>
                            <th>UIQVisitId</th>
                            <th>DOS</th>
                            <th>PatientName</th>
                            <th>VisitIdCode</th>
                            <th>ImportToMRMStatus</th>
                            <th>ImportToMRMError</th>
                            <th>SendToAMDStatus</th>
                            <th>SendToAMDError</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngIf="reportVisitResult.prviewTotalData.length>0">
                            <tr *ngFor="let report of reportVisitResult.previewPage.paginatedData">
                                <td [ngClass]="{'empty-cell': !report.visitId}">{{ report.visitId || '--' }} </td>
                                <td [ngClass]="{'empty-cell': !report.uiqVisitId}"><a [href]="'https://app.urgentiq.com/charting/'+report.uiqVisitId" target="_blank">{{ report.uiqVisitId}}</a></td>
                                <td [ngClass]="{'empty-cell': !report.dos}">{{ report.dos || '--' }}</td>
                                <td [ngClass]="{'empty-cell': !report.patientName}">{{ report.patientName || '--' }}</td>
                                <td [ngClass]="{'empty-cell': !report.visitIdCode}">{{ report.visitIdCode || '--' }}</td>
                                <td>{{ report.importToMRMStatus || '--' }}</td>
                                <td [ngClass]="{'empty-cell': !report.importToMRMError}">{{ report.importToMRMError || '--' }}</td>
                                <td>{{ report.sendToAMDStatus || '--' }}</td>
                                <td [ngClass]="{'empty-cell': !report.sendToAMDError}">{{ report.sendToAMDError || '--' }}</td>
                            </tr>
                        </ng-container>

                    </tbody>
                </table>

                <div style="display: flex; justify-content: center; margin-top: 10px;" *ngIf="reportVisitResult.prviewTotalData && reportVisitResult.prviewTotalData.length > 25">
                    <button [disabled]="reportVisitResult.previewPage.currentPage === 1" (click)="changePage(-1, reportVisitResult)" style="margin-right: 10px;">Previous</button>
                    <span>Page {{ reportVisitResult.previewPage.currentPage }} of {{ reportVisitResult.previewPage.totalPages }}</span>
                    <button [disabled]="reportVisitResult.previewPage.currentPage == reportVisitResult.previewPage.totalPages" (click)="changePage(1, reportVisitResult)" style="margin-left: 10px;">Next</button>
                </div>
            </div>

            <div style="background: white;margin-top:10px; padding: 10px" *ngIf="!reportVisitResult.pending && (reportVisitResult.messageError || reportVisitResult.messageWarning)">
                <p *ngIf="reportVisitResult.messageError" style="color:red">{{reportVisitResult.messageError}}</p>
                <p *ngIf="reportVisitResult.messageWarning" style="color: #fabf03">{{reportVisitResult.messageWarning}}</p>
            </div>
        </ng-container>


        <!-- Visit Not Finalized Reason -->
        <ng-container *ngIf="tabActiveId == 'visit-notFinalized-reason'">
            <div style=" background: #fff; padding: 10px">
                <div class="row" style="min-height:75px">
                    <div class="col-md-3">
                        <mat-form-field>
                            <mat-label>Start Date</mat-label>
                            <input matInput [matDatepicker]="picker" [(ngModel)]="filterNotFinalized.startDate" [max]="filterNotFinalized.endDate" [readonly]="true">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field>
                            <mat-label>End Date</mat-label>
                            <input matInput [matDatepicker]="pickerEnd" [(ngModel)]="filterNotFinalized.endDate" [min]="filterNotFinalized.startDate" [max]="today" [readonly]="true">
                            <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                            <mat-datepicker #pickerEnd></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 d-flex">
                        <button type="submit" class="btn btn-primary" style="float: right; margin: 0px 5px" (click)="previewVisitNotFinalizedReason()">Preview Report</button>
                    </div>
                </div>
            </div>

            <div style="background: transparent; margin: 0px; padding: 0" *ngIf="reportNotFinalizedResult.pending">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>

            <div style="background: white;margin-top:10px;" class="preview-visit-finalized">
                <table>
                    <thead>
                        <tr>
                            <th>UIQVisitId</th>
                            <th>DOS</th>
                            <th>VisitIdCode</th>
                            <th>Error Message</th>
                            <th>LastCheck</th>
                            <!--<th>Note</th>-->
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngIf="reportNotFinalizedResult.prviewTotalData.length>0">
                            <tr *ngFor="let report of reportNotFinalizedResult.previewPage.paginatedData" style="cursor:pointer" >
                                <td [ngClass]="{'empty-cell': !report.visitEMRId}"><a [href]="'https://app.urgentiq.com/charting/'+report.visitEMRId" target="_blank">{{ report.visitEMRId}}</a></td>
                                <td [ngClass]="{'empty-cell': !report.visitDateTime}">{{ report.visitDateTime | date:'MM-dd-yyyy' || '--' }}</td>
                                <td [ngClass]="{'empty-cell': !report.visitIdCode}">{{ report.visitIdCode || '--' }}</td>
                                <td [ngClass]="{'empty-cell': !report.visitError && !report.Notes && report.isLock == true}">
                                    {{report.visitError ? report.visitError : (report.notes ? report.notes : (report.isLock == false ? 'Not Locked' : '--'))}}
                                </td>

                                <td [ngClass]="{'empty-cell': !report.lastCheckDateTime}">{{ report.lastCheckDateTime ? formatDateToCST(report.lastCheckDateTime) : '--' }}</td>
                                <!--<td [ngClass]="{'warning': report.notes}">{{ report.notes || '--' }}</td>-->
                            </tr>
                        </ng-container>
                    </tbody>
                </table>

                <div style="display: flex; justify-content: center; margin-top: 10px;" *ngIf="reportNotFinalizedResult.prviewTotalData && reportNotFinalizedResult.prviewTotalData.length > 25">
                    <button [disabled]="reportNotFinalizedResult.previewPage.currentPage === 1" (click)="changePage(-1, reportNotFinalizedResult)" style="margin-right: 10px;">Previous</button>
                    <span>Page {{ reportNotFinalizedResult.previewPage.currentPage }} of {{ reportNotFinalizedResult.previewPage.totalPages }}</span>
                    <button [disabled]="reportNotFinalizedResult.previewPage.currentPage == reportNotFinalizedResult.previewPage.totalPages" (click)="changePage(1, reportNotFinalizedResult)" style="margin-left: 10px;">Next</button>
                </div>
            </div>

            <div style="background: white;margin-top:10px; padding: 10px" *ngIf="!reportNotFinalizedResult.pending && (reportNotFinalizedResult.messageError || reportNotFinalizedResult.messageWarning)">
                <p *ngIf="reportNotFinalizedResult.messageError" style="color:red">{{reportNotFinalizedResult.messageError}}</p>
                <p *ngIf="reportNotFinalizedResult.messageWarning" style="color:#fabf03">{{reportNotFinalizedResult.messageWarning}}</p>
            </div>
        </ng-container>

    </div>
</div>
