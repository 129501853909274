import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnotifyService } from 'ng-snotify';

import { ApiService } from '../../../services/api.service';

import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
    selector: 'app-upload-chart-modal',
    templateUrl: './upload-chart-modal.component.html',
    styleUrls: ['./upload-chart-modal.component.scss']

})
export class UploadChartModalComponent implements OnInit {
    public isLoading: boolean = false;
    public file: any;
    public visitId: number = 0;
    public imageBase64: string = ""
    public isSuccess: boolean = false

    constructor(
        private matDialog: MatDialog,
        public dialogRef: MatDialogRef<UploadChartModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private apiService: ApiService,
        private snotifyService: SnotifyService,
    ) {
  
    }

    ngOnInit(): void {
        this.visitId = this.data.visitId
    }

    handleFileInput(files: FileList) {
        this.file = files
    }

    save() {
        this.isLoading = true
        this.apiService.uploadChart(this.file, this.visitId).subscribe((r) => {
            console.log('r', r)
            if (r) {
                this.imageBase64 = r
                this.isSuccess = true
                this.isLoading = false
              //this.dialogRef.close('saved')
            }
        }, err => {
            alert('File not uploaded');
        })
    }

}
