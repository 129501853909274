import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { DialogLoadingComponent } from 'src/components/dialog-loading/dialog-loading.component';

import { ApiService } from 'src/services/api.service';

import * as _ from 'lodash';

@Component({
    selector: 'app-update-modifier-confirm',
    templateUrl: './update-modifier-confirm.component.html',
    styleUrls: ['./update-modifier-confirm.component.scss']
})
export class UpdateModifierConfirmComponent implements OnInit {
  public isLoading: boolean = false
  public isSaving: boolean = false
  public isSubmitted: boolean = false

    visitProcCodeId: number;
  public visitId: number;
  public officeKey: string = '';
  public visitModifierCodes: any = []
  public visitModifierCodeForm: FormGroup;
  public selectedModifierCode: any;
  public dialogLoading: any;
    public isFinish: boolean = false;
    oldModifierCode = '';
    newModifierCode = '';
    checkOk = false;
    isCheckOnly;
  constructor(
    private apiService: ApiService,
      public dialogRef: MatDialogRef<UpdateModifierConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private matDialog: MatDialog,
  ) { }

  ngOnInit() {
     this.visitId = this.data.visitId
      this.officeKey = this.data.officeKey;
      this.oldModifierCode = this.data.oldModifierCode;
      this.isCheckOnly = this.data.isCheckOnly;
      this.visitProcCodeId = this.data.visitProcCodeId
     this.initForm()
     this.loadData()
    }


  loadData(){
    this.isLoading = true
    this.apiService.getVisitProcCodesByVisitId(this.visitId, this.officeKey).subscribe((r) => {
        this.visitModifierCodes = r
        this.isLoading = false
        this.isFinish = _.filter(this.visitModifierCodes, (e) => {return e.isMissingAmd}).length > 0 ? false : true
    })
  }

  initForm() {
    this.visitModifierCodeForm = this.fb.group({
      ModifierCode: ['', [Validators.required]],
    })
  }

  get visitF() { return this.visitModifierCodeForm.controls }

  editVisitModifierCode(item){
    this.selectedModifierCode = item;
    this.visitModifierCodes.forEach(e => {
      e.isActive = e.visitModifierCodeId == item.visitModifierCodeId ? true : false
    });
    if (item) {
      this.visitModifierCodeForm.patchValue({
        ModifierCode: item.ModifierCode,
      })
    }
  }


  save(){
    this.isSaving = true
    this.isSubmitted = true
    let payload = {
       visitId: this.visitId,
       ModifierCode: this.visitModifierCodeForm.value.ModifierCode,
       visitModifierCodeId: this.selectedModifierCode.visitModifierCodeId
    }
    this.apiService.updateVisitModifierCode(payload).subscribe((r) => {
      this.isSaving = false
      this.isSubmitted = false
      this.showSnackBar('Updated Succesffuly!')
      localStorage.setItem('highlight-visitId', this.visitId.toString())
      this.dialogRef.close('saved')
    }, err => {
      this.isSaving = false
      this.isSubmitted = false
      this.showSnackBar('Updated Unsuccesffuly!')
    })
  }

  openLoading() {
    this.dialogLoading = this.matDialog.open(DialogLoadingComponent, {
         minWidth: '150px',
         disableClose: true
     });
 }

 closeLoading() {
     this.dialogLoading.close()
 }

 checkOnAMD(){


    var  modifierCode= (this.isCheckOnly ? this.oldModifierCode : this.newModifierCode)
  
     this.apiService.checkVisitModifierCodeWithAmd(modifierCode, this.officeKey).subscribe((r) => {
    console.log(r)
      if (r) {
          this.showSnackBar('YAY! found on AMD. You can proceed now.')
          this.checkOk = true;
      } else {
          this.checkOk = false;
          this.showSnackBar('NOT found on AMD')
      }
  }, err => {
      console.log('er', err)
      this.checkOk = false;
      this.showSnackBar('NOT found on AMD')
  })
    }

    proceed() {
        let payload = {
            visitProcCodeId: this.visitProcCodeId,
            newModifierCode: this.newModifierCode,
            visitId: this.visitId
        }

        this.apiService.updateModifierCodeForRefVisits(payload).subscribe((r) => {

            if (r) {
                this.showSnackBar('Proceeded the visit. You can close this popup')
               
            }
        }, err => {
            console.log('er', err)
        })
    
    }

 showSnackBar(message) {
  this._snackBar.open(message)
  setTimeout(() => {
      this._snackBar.dismiss()
  }, 4000); 
  
}
}
