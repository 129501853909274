import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/services/api.service';
import { zip } from 'rxjs'

import * as _ from 'lodash';
@Component({
  selector: 'app-update-visit-chart-doc-modal',
  templateUrl: './update-visit-chart-doc-modal.component.html',
  styleUrls: ['./update-visit-chart-doc-modal.component.scss']
})
export class UpdateVisitChartDocModalComponent implements OnInit {
  public isLoading: boolean = false
  public isSaving: boolean = false
  public isSubmitted: boolean = false
  public dialogLoading: any;

  public visitId: number;
  public chartId: number;
  public charts: any = [];

  constructor(
    private apiService: ApiService,
    public dialogRef: MatDialogRef<UpdateVisitChartDocModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private matDialog: MatDialog,
  ) { }

  ngOnInit() {
     this.visitId = this.data.visitId
     this.chartId = this.data.chartId
     this.loadData()
  }

  loadData(){
    this.isLoading = true
    this.apiService.getChartFilesByChartId(this.chartId).subscribe((charts:any) => {
      this.charts = charts;
      if(charts?.length > 0){
        const reqArr = [] 
        _.each(charts, (e) => {
          let obs = this.apiService.checkFileChartExist(e.fileName, e.chartDocId);
          reqArr.push(obs)
        }) 
        zip(...reqArr).subscribe((reqCheckFiles) => {
          _.each(this.charts, (e) => {
             let checkFile = _.find(reqCheckFiles, (r) => {return r.chartDocId == e.chartDocId})
             e.isExist = checkFile?.isExist ?? false
          })
          this.isLoading = false
        })
      }else{
        this.isLoading = false
      }
    })

  }

  uploadFiles(e, chartDoc){
    chartDoc.isUploading = true
    const files = <FileList>e.target.files;
    if (!files && files?.length > 0) {
      return;
    }
    const file = files[0];
      this.apiService.uploadFileChartDoc(file, chartDoc.chartDocId, this.visitId).subscribe((r ) => {
      chartDoc.isUploading = false;
      if(r){
          this.dialogRef.close('saved')
        this.showSnackBar("Upload File Successfully")
      }
    }, err => {
      chartDoc.isUploading = false;
        this.showSnackBar("Opp! Error")
    })
  }

  requeueVisitChartDoc(){
    this.isSaving = true
    this.apiService.requeueVisitChartDoc(this.visitId).subscribe((r) => {
      this.isSaving = false;
      if(!!r){
        this.showSnackBar("Update Successfully")
        this.dialogRef.close('saved')
      }else{
          this.showSnackBar("Opp! Error")
      }
    }, err => {
      this.showSnackBar("Opp! Error")
    })
  }

  public showSnackBar(message) {
    this._snackBar.open(message)
    setTimeout(() => {
        this._snackBar.dismiss()
    }, 4000);
  } 
}
