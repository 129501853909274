<h1></h1>

<div class="row">
    <div class="col-12" style="padding: 0px 20px">
        <h1>Rules Automation Activity</h1>
    </div>
</div>



<div class="row top-buffer" style="margin-bottom:15px">
    <div class="col-md-12" style="text-align: right">
        <button mat-raised-button color="primary" (click)="getRulesAutomationActivity()" [disabled]="!isExecuting" class="mr-1">Refresh</button>
    </div>
</div>

<p *ngIf="isExecuting"
   style="text-align:center; background-color:salmon; font-size:large;font-weight:bold">{{message}}</p>
<p *ngIf="isLoading">Loading...</p>

<ng-container *ngIf="!isLoading">
    <ngx-datatable class="material" [rows]="activityViewModelPages" [headerHeight]="50" [footerHeight]="50"
                   rowHeight="auto" [scrollbarH]="true" [loadingIndicator]="isLoading">
        <ngx-datatable-column name="Rule Name" [width]="250">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.ruleName }}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="No Of Visits" [width]="100">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.visitsCount }}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="No Of Processed Visits" [width]="150">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.processedVisitsCount }}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Action Set" [width]="250">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.actionSetName }}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Status" [width]="200">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.currentStatus }}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Type" [width]="200">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.activityTypeDesc }}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Created On" [width]="200">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.createdTime }}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Processing Results" [width]="100">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <div matTooltip="{{row.executionStatus == 7 ? 'Navigate to bulk update to handle incomplete processed visits':''}}"
                        class="importStatus-indicator {{row.executionStatus == 8 ? '' : row.executionStatus == 7 ? 'has-error' : 'not-run'}}">
                </div>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>

    <ng-container *ngIf="activityViewModels.length <= 0">
        <div class="row">
            <div class="col-12" style="text-align: center">
                <span style="font-size: 2rem; font-weight: bold;">No Data</span>
            </div>
        </div>
    </ng-container>

</ng-container>
<mat-paginator class="pagination" [length]="total" [pageSize]="pageSize" (page)="changePage($event)">
</mat-paginator>

<div class="toast" *ngIf="isShowToast" [ngClass]="classToast">
    <p>{{stringToast}}</p>
</div>

