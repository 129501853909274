<h1 mat-dialog-title>
   Error Log Details
</h1>
<div mat-dialog-content>
    <div class="modal-body mx-3" class="table-scroll">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Log_Num</th>
            <th scope="col">Patient Name</th>
            <th scope="col">Clinic</th>
            <!--<th scope="col">Physican </th>-->
            <!--<th scope="col">Error</th>-->
            <!--<th scope="col">Action</th>-->
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="isLoading">
            <td colspan="5" style="text-align: center;color: gray;">Loading...</td>
          </tr>
          <ng-container *ngIf="!isLoading">
            <tr *ngFor="let e of listError">
              <td>{{e.log_Num }}</td>
              <td>{{e.patient_Information.first_Name}}, {{e.patient_Information.last_Name}}</td>
              <td>{{e.clinic}}</td>
              <!--<td>
                <ng-container *ngIf="e.patientInfo">
                  {{e.patientInfo.firstName}} {{e.patientInfo.lastName}}
                </ng-container>
              </td>
              <td>
                <div style="max-height: 100px;overflow-y: auto;max-width: 400px;">
                  {{e.error}}
                </div>
              </td>
              <td>
                <button type="button" class="btn btn-primary" (click)="reImportError(e)"> 
                  <div style="display: flex;align-items: center;justify-content: center;">
                    <ng-container *ngIf="e.isLoading">
                      <mat-spinner color="accent" [diameter]="15" style="margin-right: 5px;"></mat-spinner>
                    </ng-container>
                    Re-import
                  </div>
                </button>
              </td>-->
            </tr>
          </ng-container>
          <div *ngIf="!isLoading">
              <p>There is(are) {{listError.length}} records imported failed.</p>
              <p *ngIf="listDuplicates.length > 0">
                  Duplicate Log Number(s):<span *ngFor="let logNum of listDuplicates; let last = last">{{ logNum }}<ng-container *ngIf="!last">, </ng-container></span> are found in the import file.</p>

              <button type="button" class="btn btn-primary" (click)="reImportError()">
                  <div style="display: flex;align-items: center;justify-content: center;">
                      <ng-container *ngIf="isLoading">
                          <mat-spinner color="accent" [diameter]="15" style="margin-right: 5px;"></mat-spinner>
                      </ng-container>
                      Re-import all failed
                  </div>
              </button>
          </div>
        </tbody>
      </table>
    </div>
</div>
<div mat-dialog-actions style="justify-content: end;">
    <button class="btn btn-secondary" style="margin-right: 5px" mat-dialog-close>Close</button>
</div>