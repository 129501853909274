import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ApiService } from '../../services/api.service';
import { MqttService } from 'ngx-mqtt';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-action-setup-management',
    templateUrl: './action-setup-management.component.html',
    styleUrls: ['./action-setup-management.component.scss']

})
export class ActionSetupManagementComponent implements OnInit {
    public userId;
    officeKeys = [];
    public isLoading = true;
    public bulkActionSets = [];
    codeReviewRules = [];
    public selectedBulkAction: any;
    public arrAction = [];
    public selectedSetId = -1;
    finClasses = [];
    dataFilter: any;
    currentMsg_ExecuteActBulkUpdate: any = { value: 0, message: "" };
    isShowInput: boolean = false;

    isShowToast: boolean = false;
    stringToast: string = "";
    classToast: string = "";
    nameBulkActionSet = "";

    constructor(private apiService: ApiService, private _mqttService: MqttService,) {
        this.userId = localStorage.getItem('userId')
    }

    ngOnInit() {
        this.getDataSetup();
        this.apiService.getAllOfficeKeys().subscribe((r) => {
            console.log(r)

            this.officeKeys = r || []
        })
        this.apiService.getCodeReviewRuleAll().subscribe((res) => {
            let groupRuleName = _.groupBy(res, 'ruleName')
            let ruleName = Object.keys(groupRuleName)
            this.codeReviewRules = []
            let itemNoRule = {
                name: 'No Rules',
                ruleIds: [-1],
                isSelected: false
            }
            this.codeReviewRules.push(itemNoRule)

            _.each(ruleName, (name) => {
                let itemRule = {
                    name: name,
                    ruleIds: _.map(groupRuleName[name], 'id'),
                    isSelected: false
                }
                this.codeReviewRules.push(itemRule)
            })
        })
        this._mqttService.observe("ExecuteActBulkUpdateRoom").subscribe(r => {
            if (r) {
                this.currentMsg_ExecuteActBulkUpdate = JSON.parse(r.payload.toString());
                console.log(this.currentMsg_ExecuteActBulkUpdate)
            }
        });
    }


    getDataSetup() {
        this.isLoading = true;
        forkJoin([this.apiService.getDataBulkUpdate(), this.apiService.getAllBulkActionSet()]).subscribe(
            ([data1, data2]) => {
                this.dataFilter = data1;
                this.dataFilter.finClasses = _.uniqBy(this.dataFilter.finClasses, 'text');
                this.bulkActionSets = data2 || []
                this.isLoading = false;
            }
        );
    }

    //getDataFilter() {
    //    this.isLoading = true
    //    this.apiService.getDataBulkUpdate().subscribe((r) => {
    //        this.dataFilter = r

    //        this.dataFilter.finClasses = _.uniqBy(this.dataFilter.finClasses, 'text');

    //        this.isLoading = false
    //    })
    //}

    getBulkActionSet() {
        this.isLoading = true;
        this.apiService.getAllBulkActionSet().subscribe((r) => {
            this.bulkActionSets = r || []
            this.isLoading = false;
        })
    }

    changeBulkAction(actionSet) {
        this.selectedBulkAction = actionSet; 
        this.arrAction = JSON.parse(this.selectedBulkAction.actionJson) ?? [];
        this.selectedSetId = this.selectedBulkAction.id ?? -1
    }

    addAction() {
        var item = {
            value: ""
        }
        this.arrAction.push(item);
    }

    deleteItem(index) {
        this.arrAction = this.arrAction.filter((e, i) => {
            return i != index
        })
    }

    refreshItem(index) {
        this.arrAction = _.each(this.arrAction, (e, i) => {
            if (i == index) {
                e.value = ""
                e.codeName = "";
                e.modifier = "";
                e.physician = "";
                e.clinic = "";
                e.officeKey = "";
                e.valueTags = [];
                e.valueRules = []
            }
        })
    }

    showInputName() {
        if (this.checkValidationArr()) {
            return;
        }
        this.isShowInput = true;
    }
    hiddenInputName() {
        this.isShowInput = false;
    }

    checkValidationArr() {
        let modifier = _.find(this.arrAction, (e) => {
            return (e.value == "addMod" || e.value == "delMod") && !e.isValid
        })
        console.log(modifier)
        return !!modifier ? true : false
    }

    clearAllAction() {
        if (confirm('You are about to delete all actions, are you sure?')) {
            this.arrAction = []
        }
    }

    deleteSet() {
        if (confirm('You are about to delete an action set, are you sure?')) {
            var payload = {
                id: this.selectedSetId,
                userId: this.userId
            }
            this.apiService.deleteBulkActionSet(payload).subscribe((r) => {
                if (r.success) {
                    this.arrAction = [];
                    this.showToast(r.message, 'success')
                    this.getBulkActionSet()
                    this.hiddenInputName()
                } else {
                    this.showToast(r.message, 'danger')
                    this.hiddenInputName()
                }
            })
        }
    }

    showToast(message, style) {
        switch (style) {
            case "info":
                this.classToast = "toast-info"
                break;
            case "success":
                this.classToast = "toast-success"
                break;
            case "danger":
                this.classToast = "toast-danger"
                break;
            case "warning":
                this.classToast = "toast-warning"
                break;
            default:
                this.classToast = "toast-light"
                break;
        }
        this.isShowToast = true
        this.stringToast = message;
        setTimeout(() => {
            this.isShowToast = false;
            this.stringToast = "";
        //    this.classAlert = "toast-light";
        }, 3000)
    }

    saveSet() {
        var payload = {
            actionJson: JSON.stringify(this.arrAction),
            name: this.nameBulkActionSet,
            userId: this.userId,
        }
        this.apiService.saveBulkActionSet(payload).subscribe((r) => {
            if (r.success) {
                this.showToast(r.message, 'success')
                this.getBulkActionSet()
                this.hiddenInputName()
            } else {
                this.showToast(r.message, 'danger')
                this.hiddenInputName()
            }
        })
    }

    changeAction(event, index) {
        this.arrAction.forEach((e, i) => {
            if (i == index) {
                e.id = index;
                e.value = event
                e.codeName = "";
                e.modifier = "";
                e.codeName = "";
                e.physician = "";
                e.clinic = "";
                e.officeKey = "";
                e.finClass = "";
                e.valueTags = [];
                e.valueRules = [];
            }
        })
    }

    checkModifierValidation(item) {
        let pattern = /^[A-Za-z0-9\,]*$/g;
        item.isValid = item.modifier !== '' && pattern.test(item.modifier) ? true : false
    }

}
