
<div mat-dialog-content>

    <mat-form-field>
        <mat-label>Current ProcCode</mat-label>
        <input matInput [(ngModel)]="oldProcCode" readonly />
    </mat-form-field>

    <mat-form-field *ngIf="!isCheckOnly">
        <mat-label>New ProcCode</mat-label>
        <input matInput (ngModelChange)="checkOk= false" [(ngModel)]="newProcCode"  />

    </mat-form-field>
    <button mat-raised-button (click)="checkOnAMD()">Check exist on AMD</button>
</div>
<div mat-dialog-actions style="justify-content: end;">
    <mat-label >Proceed btn will update all visit reference with this current ProcCode</mat-label>
    <br />
    <button mat-raised-button color="primary" [disabled]="!checkOk" (click)="proceed()">Proceed</button>
</div>