import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { DialogLoadingComponent } from 'src/components/dialog-loading/dialog-loading.component';

import { ApiService } from 'src/services/api.service';

import * as _ from 'lodash';

@Component({
    selector: 'app-update-diag-confirm',
    templateUrl: './update-diag-confirm.component.html',
    styleUrls: ['./update-diag-confirm.component.scss']
})
export class UpdateDiagConfirmComponent implements OnInit {
  public isLoading: boolean = false
  public isSaving: boolean = false
  public isSubmitted: boolean = false

  public visitId: number;
  public officeKey: string = '';
  public visitDiagCodes: any = []
  public visitDiagCodeForm: FormGroup;
  public selectedDiagCode: any;
  public dialogLoading: any;
    public isFinish: boolean = false;
    oldDiagCode = '';
    newDiagCode = '';
    checkOk = false;
    isCheckOnly;
  constructor(
    private apiService: ApiService,
      public dialogRef: MatDialogRef<UpdateDiagConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private matDialog: MatDialog,
  ) { }

  ngOnInit() {
     this.visitId = this.data.visitId
      this.officeKey = this.data.officeKey;
      this.oldDiagCode = this.data.oldDiagCode;
      this.isCheckOnly = this.data.isCheckOnly;
     this.initForm()
     this.loadData()
    }


  loadData(){
    this.isLoading = true
    this.apiService.getVisitDiagCodesByVisitId(this.visitId, this.officeKey).subscribe((r) => {
        this.visitDiagCodes = r
        this.isLoading = false
        this.isFinish = _.filter(this.visitDiagCodes, (e) => {return e.isMissingAmd}).length > 0 ? false : true
    })
  }

  initForm() {
    this.visitDiagCodeForm = this.fb.group({
      DiagCode: ['', [Validators.required]],
    })
  }

  get visitF() { return this.visitDiagCodeForm.controls }

  editVisitDiagCode(item){
    this.selectedDiagCode = item;
    this.visitDiagCodes.forEach(e => {
      e.isActive = e.visitDiagCodeId == item.visitDiagCodeId ? true : false
    });
    if (item) {
      this.visitDiagCodeForm.patchValue({
        DiagCode: item.DiagCode,
      })
    }
  }

  deleteVisitDiagCode(item){
    if (confirm('You are about to delete a visit DiagCode, are you sure?')) {
      this.openLoading()
      this.apiService.deleteVisitDiagCodeById(item.visitDiagCodeId).subscribe((r ) => {
        this.closeLoading()  
        if(r){
          this.visitDiagCodes = _.filter(this.visitDiagCodes, (e) => {return e.visitDiagCodeId != item.visitDiagCodeId})
          this.selectedDiagCode = null
          this.showSnackBar("Delete Visit DiagCode Successfully!")
        }else{
          this.showSnackBar("Not Found Visit DiagCode!")
        }          
      })
    }
  }

  save(){
    this.isSaving = true
    this.isSubmitted = true
    let payload = {
       visitId: this.visitId,
       DiagCode: this.visitDiagCodeForm.value.DiagCode,
       visitDiagCodeId: this.selectedDiagCode.visitDiagCodeId
    }
    this.apiService.updateVisitDiagCode(payload).subscribe((r) => {
      this.isSaving = false
      this.isSubmitted = false
      this.showSnackBar('Updated Succesffuly!')
      localStorage.setItem('highlight-visitId', this.visitId.toString())
      this.dialogRef.close('saved')
    }, err => {
      this.isSaving = false
      this.isSubmitted = false
      this.showSnackBar('Updated Unsuccesffuly!')
    })
  }

  openLoading() {
    this.dialogLoading = this.matDialog.open(DialogLoadingComponent, {
         minWidth: '150px',
         disableClose: true
     });
 }

 closeLoading() {
     this.dialogLoading.close()
 }

 checkOnAMD(){


    var  diagCode= (this.isCheckOnly ? this.oldDiagCode : this.newDiagCode)
  
     this.apiService.checkVisitDiagCodeWithAmd(diagCode, this.officeKey).subscribe((r) => {
    console.log(r)
      if (r) {
          this.showSnackBar('YAY! found on AMD. You can proceed now.')
          this.checkOk = true;
      } else {
          this.checkOk = false;
          this.showSnackBar('NOT found on AMD')
      }
  }, err => {
      console.log('er', err)
      this.checkOk = false;
      this.showSnackBar('NOT found on AMD')
  })
    }

    proceed() {
        let payload = {
            officeKey: this.officeKey,
            oldDiagCode: this.oldDiagCode ,
            newDiagCode: this.newDiagCode
        }

        this.apiService.updateDiagCodeForRefVisits(payload).subscribe((r) => {

            if (r) {
                this.showSnackBar('Proceeded '+ r + 'visits. You can close this popup')
               
            }
        }, err => {
            console.log('er', err)
        })
    
    }

 showSnackBar(message) {
  this._snackBar.open(message)
  setTimeout(() => {
      this._snackBar.dismiss()
  }, 4000); 
  
}
}
