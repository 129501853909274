import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/services/api.service';
import * as _ from 'lodash';

@Component({
    selector: 'app-view-visit-pvUpdate-modal',
    templateUrl: './view-visit-pvUpdate-modal.component.html',
    styleUrls: ['./view-visit-pvUpdate-modal.component.scss']
})
export class ViewVisitPvUpdateModalComponent implements OnInit {
    public isLoading: boolean = false
    public visit: any;

    public visit_PvUpdate: any;

    public oldProcCodes = [];
    public newProcodes = [];

    public oldDiagCodes = [];
    public newDiagCodes = [];

    public old_IcdCodes = [];
    public new_IcdCodes = [];


    constructor(private apiService: ApiService,
        public dialogRef: MatDialogRef<ViewVisitPvUpdateModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
        this.initData();
    }

    initData() {
        this.isLoading = true;
        this.visit = this.data.errVisit.visit;
        this.apiService.getVisit_PvUpdate(this.visit.visitId).subscribe(r => {
            if (r && r.id) {
                console.log(r)
                this.visit_PvUpdate = r;
                this.oldProcCodes = this.mapProcCodesToArray(r.oldProcCodes)
                this.newProcodes = this.mapProcCodesToArray(r.newProcCodes)

                this.oldDiagCodes = this.mapProcCodesToArray(r.oldDiagCodes)
                this.newDiagCodes = this.mapProcCodesToArray(r.newDiagCodes)

                this.old_IcdCodes = this.mapProcCodesToArray(r.oldICDCodes)
                this.new_IcdCodes = this.mapProcCodesToArray(r.newICDCodes)
            }
            this.isLoading = false;
        }, err => {
            console.log(err);
            this.isLoading = false;
        })     
    }

    mapProcCodesToArray(codeString: string): string[] {
        let procCodeArr: string[] = [];
        if (codeString && codeString.trim() !== "") {
            procCodeArr = codeString.split('|');
            for (let i = 0; i < procCodeArr.length; i++) {
                if (procCodeArr[i].trim() != "") {
                    procCodeArr[i] += '|';
                }
            }
        }
        return procCodeArr;
    }

}
