<div class="row">
    <div>
        <h1 style="width:auto;padding-left:15px">Addendum Setup</h1>
    </div>
</div>

<div class="row" style=" border-top: 3px solid #fbaf03; background-color:white;">
    <div class="col-12" *ngIf="isLoading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <div class="col-12" *ngIf="!isLoading">
        <div class="row addendum-settings">
            <div class="col-12">
                <span *ngIf="!addendumSettings || addendumSettings.length==0">No addendum settings yet.</span>
                <table *ngIf="addendumSettings && addendumSettings.length>0">
                    <tr *ngFor="let addendum of addendumSettings; let i= index;">
                        <td width="10%" style="display:flex">{{addendum.label}}:</td>
                        <td width="90%">{{addendum.text}}</td>
                    </tr>
                </table>
            </div>
        </div>

        <div class="row addendum-items">
            <div class="btn-add-action" style="width: 100%; text-align:end; padding: 15px;">
                <button mat-raised-button style="background-color: #4ac94a;" (click)="openAddEditAddendum({})">New Item</button>
            </div>
            <ngx-datatable class="material" [rows]="addendumItems" [headerHeight]="50" [footerHeight]="50" rowHeight="auto"
                           [scrollbarH]="true" [loadingIndicator]="false" [count]="addendumItems.length" offset="0"
                           [limit]="addendumItems.length" [externalPaging]="true">

                <ngx-datatable-column name="Label" [width]="150">
                    <ng-template let-row="row" ngx-datatable-cell-template style="display:flex; align-items: center; justify-content: center">
                        {{ row.label }}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Additional Inputs" [width]="150">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <mat-checkbox [checked]="row.additionalInputs > 0"></mat-checkbox>
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Input Label" [width]="150">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.inputLabels}}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Document Text" [width]="800">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.documentText}}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Actions" [width]="150">
                    <ng-template let-row="row" ngx-datatable-cell-template>

                        <div style="display: flex; justify-content: space-between; align-items: center;"> 
                            <button class="btn" style="background-color: white; color: dimgrey; border: 1px solid dimgrey; margin-right: 10px" (click)="openAddEditAddendum(row)">
                                <ng-container>Edit</ng-container>
                            </button>

                            <button class="btn" style="background-color: white; color: red; border: 1px solid red; margin-right:10px" (click)="removeAddendumItem(row)">
                                <ng-container>Delete</ng-container>
                            </button>
                        </div>

                        <!--<span style="margin: 10px; text-decoration:underline; cursor: pointer" (click)="openAddEditAddendum(row)">Edit</span>-->
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>
        </div>
    </div>              

</div>
