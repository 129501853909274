<h1 mat-dialog-title>Add New Clinic</h1>
<div mat-dialog-content>
    <div style="width:100%" *ngIf="!isLoading">
        <div style="margin-bottom: 15px;">
            <mat-form-field appearance="fill" style="width: 100%">
                <mat-label>Clinic</mat-label>
                <input matInput type="text" [(ngModel)]="clinicId">
                <span matSuffix style="opacity:0.7;">133358</span>
            </mat-form-field>
            <span matSuffix style="opacity:0.7;">132677: </span> <span *ngIf="clinicId">FP{{clinicId}}</span>
        </div>
        <div style="margin-bottom: 15px;">
            <mat-form-field appearance="fill" style="width: 100%">
                <mat-label>Clinic Full Name</mat-label>
                <input matInput type="text" [(ngModel)]="clinicFullName" placeholder="Clinic Full Name">
            </mat-form-field>
        </div>
        <div style="margin-bottom: 15px;">
            <mat-form-field appearance="fill" style="width: 100%">
                <mat-label>Office Key Location</mat-label>
                <mat-select [(ngModel)]="amdCodeName">
                    <mat-option *ngFor="let a of allFacilities"
                                [value]="a.value">
                        {{a.text}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div *ngIf="warning">
        <p style="color:red">Error: {{warning}}</p>
    </div>
    <div style="width: 100%; display: flex; justify-content: center" *ngIf="isLoading">
        <mat-spinner></mat-spinner>
    </div>
</div>
<div mat-dialog-actions style="justify-content: end;">
    <button class="btn btn-secondary" style="margin-right: 5px" mat-dialog-close>Close</button>
    <button class="btn btn-primary" (click)="saveAdd()" [disabled]="isLoading">
        <ng-container *ngIf="!isLoading">Add</ng-container>
        <ng-container *ngIf="isLoading">Adding...</ng-container>
    </button>
</div>
