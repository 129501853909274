import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from '../../services/api.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { interval } from 'rxjs';

@Component({
    selector: 'app-rules-automation',
    templateUrl: './rules-automation.component.html',
    styleUrls: ['./rules-automation.component.scss']
})
export class RulesAutomationComponent implements OnInit {
    public total: number;
    public totalVisits: number;
    public pageSize: number = 100;
    public pageNumber = 0;

    userId: string = "";
    activityViewModels = new Array<any>();
    activityViewModelPages = new Array<any>();
    activityViewModels_readyToProcess = new Array<any>();
    flaggedVisitIds = new Array<any>();
    public isExecuting: boolean = false;
    public isLoading: boolean = false;
    public message: string = "";
    progressBarValue: number = 0;
    executeActionDisabled = false;
    isShowSearch: boolean = true;
    isShowToast: boolean = false;
    stringToast: string = "";
    classToast: string = "";
    classAlert: string = "";
    sub: any;

    constructor(
        private apiService: ApiService
    ) {
        this.userId = localStorage.getItem('userId')
    }

    ngOnInit(): void {
        this.isLoading = true;
        this.getRulesAutomationActivity();
        this.startInterval();    
    }

    ngOnDestroy(): void {
        this.stopInterval()
    }

    startInterval() {
        this.sub = interval(15000).subscribe((val) => {
            this.getRulesAutomationActivity();
        });     
    }

    stopInterval() {
        this.sub.unsubscribe();
        
    }

    isAutomationRunning() {
        this.apiService.isRunningAutomation().subscribe((r: any) => {
            this.isExecuting = r.running;
            if (!r.running) {
                this.message = "Nothing new to execute!"
            } else {
                this.message = "READY FOR PROCESSING..."
            }

        });
    }

    getRulesAutomationActivity() {
        var payload = {
            pageSize: this.pageSize,
            pageNumber: 0,
        }
        this.apiService.getRulesAutomationActivity(payload).subscribe((r) => {
            this.activityViewModels = r.activityViewModels || [];
            this.total = r.total;

            if (this.activityViewModels.length > 0) {
                this.changePage({ pageIndex: this.pageNumber })
            }

            this.isAutomationRunning();
            this.isLoading = false;
        }, err => {
            console.log("err", err);
            this.isLoading = false;
        });
    }

    showToast(message, style) {
        switch (style) {
            case "info":
                this.classToast = "toast-info"
                break;
            case "success":
                this.classToast = "toast-success"
                break;
            case "danger":
                this.classToast = "toast-danger"
                break;
            case "warning":
                this.classToast = "toast-warning"
                break;
            default:
                this.classToast = "toast-light"
                break;
        }
        this.isShowToast = true
        this.stringToast = message;
        setTimeout(() => {
            this.isShowToast = false;
            this.stringToast = "";
            this.classAlert = "toast-light";
        }, 3000)
    }

    changePage(event) {
        this.pageNumber = event.pageIndex;
        this.isLoading = true;
        this.activityViewModelPages = _.slice(this.activityViewModels, this.pageNumber * this.pageSize, (this.pageNumber * this.pageSize) + this.pageSize);
        this.isLoading = false;
    }
}
