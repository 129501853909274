<h1 mat-dialog-title>File upload form</h1>
<div mat-dialog-content>
    <div class="modal-body mx-3">
        <!-- Form -->
        <div class='alert alert-success' role='alert' *ngIf="isSuccess">File uploaded successfully see the preview. If it is ok, please close the dialog.</div>

        <form method='post' action='' enctype="multipart/form-data">
            <div class="form-group">
                <label for="file">Select file : </label>
                <input type='file' name='file' id='file' class='form-control' (change)="handleFileInput($event.target.files)"><br>
            </div>
        </form>
        <!-- Preview-->
        <div class="row" *ngIf="imageBase64 != ''" style="padding: 0 20px">
           <img [src]="imageBase64" width='100' height='100' style="border: 1px solid gray; padding: 5px;border-radius: 5px;display: inline-block;">
        </div>
    </div>
</div>
<div mat-dialog-actions style="justify-content: end;">
    <button class="btn btn-secondary" style="margin-right: 5px" mat-dialog-close>Close</button>
    <button class="btn btn-primary" (click)="save()" [disabled]="isLoading">
        <ng-container *ngIf="!isLoading">Upload</ng-container>
        <ng-container *ngIf="isLoading">Uploading...</ng-container>
    </button>
</div>