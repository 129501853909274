<h1></h1>

<div class="row">
    <div class="col-12" style="padding: 0px 20px">
        <h1>Email Automation Activity</h1>
    </div>
</div>


<p *ngIf="isExecuting"
   style="text-align:center; background-color:salmon; font-size:large;font-weight:bold">{{message}}</p>
<p *ngIf="isLoading">Loading...</p>

<ng-container *ngIf="!isLoading">
    <ngx-datatable class="material" [rows]="activityViewModels" [headerHeight]="50" [footerHeight]="50"
                   rowHeight="auto" [scrollbarH]="true" [loadingIndicator]="isLoading">
        <ngx-datatable-column name="Send To" [width]="250">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.toEmails }}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Visit Id" [width]="100">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.visitId }}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Email Subject" [width]="400">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.subject }}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Created On" [width]="200">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.createdTime }}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Sent On" [width]="200">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.sentOn }}
            </ng-template>
        </ngx-datatable-column>
        <!--<ngx-datatable-column name="Action Set" [width]="50">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.actionSetName }}
        </ng-template>
    </ngx-datatable-column>-->
        <ngx-datatable-column name="Status" [width]="100">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.processingStatus }}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Email Results" [width]="100">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <div matTooltip="{{row.processingStatus != 'success' && row.processingStatus != 'queued' ? 'Navigate to code review to handle incomplete processed visits':''}}"
                     class="importStatus-indicator {{row.processingStatus == 'success' ? '' : row.processingStatus == 'error' ? 'has-error' : 'not-run'}}">
                </div>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Link" [width]="100">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <div>
                    <button class="btn btn-secondary"
                            aria-label="Link" matTooltip="Link to Record"
                            (click)="gotoLinkedRecord(row.visitId)">
                        Coding
                    </button>
                </div>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>

    <ng-container *ngIf="activityViewModels.length <= 0">
        <div class="row">
            <div class="col-12" style="text-align: center">
                <span style="font-size: 2rem; font-weight: bold;">No Data</span>
            </div>
        </div>
    </ng-container>

</ng-container>

<div class="toast" *ngIf="isShowToast" [ngClass]="classToast">
    <p>{{stringToast}}</p>
</div>

