<h1 mat-dialog-title>Add Icd Code Record</h1>
<div mat-dialog-content>
    <div class="modal-body mx-3">

        <div class="md-form mb-5">
            <div class="form-group">
                <mat-form-field class="example-full-width" appearance="fill" style="width: 100%">
                    <mat-label>Code Name</mat-label>
                    <input matInput [(ngModel)]="model.code" (keyup)="searchIcd($event)" debounce="600" [matAutocomplete]="auto">
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                        <mat-option *ngFor="let option of arrIcd" [value]="option[0]">
                            <small><b>Code: </b> </small> <span>{{option[0]}}</span> |
                            <small><b>Name: </b> </small> <span>{{option[1]}}</span>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>
        
    </div>

    <div *ngIf="msgAlert !== ''" class="alert alert-danger" role="alert">{{msgAlert}}</div>
</div>
<div mat-dialog-actions style="justify-content: end;">
    <button class="btn btn-secondary" style="margin-right: 5px" mat-dialog-close>Close</button>
    <button class="btn btn-primary" (click)="save()" [disabled]="isLoading">
        <ng-container *ngIf="!isLoading">Add</ng-container>
        <ng-container *ngIf="isLoading">Adding...</ng-container>
    </button>
</div>