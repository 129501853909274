
<div mat-dialog-content>

    <mat-form-field>
        <mat-label>Current Modifier</mat-label>
        <input matInput [(ngModel)]="oldModifierCode" readonly />
    </mat-form-field>

    <mat-form-field *ngIf="!isCheckOnly">
        <mat-label>New Modifier</mat-label>
        <input matInput (ngModelChange)="checkOk= false" [(ngModel)]="newModifierCode"  />

    </mat-form-field>
    <button mat-raised-button (click)="checkOnAMD()">Check exist on AMD</button>
</div>
<div mat-dialog-actions style="justify-content: end;">
    <mat-label >Proceed btn will update only this visit</mat-label>
    <br />
    <button mat-raised-button color="primary" [disabled]="!checkOk" (click)="proceed()">Proceed</button>
</div>