import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import { SnotifyService } from 'ng-snotify';
import { ApiService } from '../../../services/api.service';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';

@Component({
    selector: 'update-add-clinic-modal',
    templateUrl: './update-clinic-modal.component.html',
    styleUrls: ['./update-clinic-modal.component.scss']

})
export class UpdateClinicModalComponent implements OnInit {

    public isLoading = false;
    public isUpdate = false;
    public allAMDCodes = [];
    public allOfficeKeys = [];


    public currentOfficeKey = '';
    public currentAmdCodeName = '';
    public currentAmdCodePrefix = '';
    public currentClinicId = '';

    public errorAction = "";

    public clinicId133358 = "";
    public clinicId132677 = "";
    public clinicFullName = "";
    public amdCodeName = "";

    constructor(public dialogRef: MatDialogRef<UpdateClinicModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private apiService: ApiService,
                private snotifyService: SnotifyService,
                private matDialog: MatDialog
    ) { }

    ngOnInit() {
        this.isLoading = true;
        if (this.data && this.data.clinic) {
            this.isUpdate = true;
            this.currentClinicId = this.data.clinic.clinicId;
            this.currentAmdCodeName = this.data.clinic.amdcodeName;
            this.currentOfficeKey = this.data.clinic.officeKey;
            this.currentAmdCodePrefix = this.data.clinic.amdcodePrefix;

            this.apiService.getClinicOffices().subscribe(r => {
                this.allAMDCodes = r.allFacilities;
                this.allOfficeKeys = r.allOfficeKeys;
                this.isLoading = false;
            })
        } else {
            this.isUpdate = false;
            this.allAMDCodes = [];
            this.apiService.getClinicOffices().subscribe(r => {
                this.allOfficeKeys = r.allOfficeKeys
                this.isLoading = false;
            })
        }
    }

    saveUpdate() {
        if (!this.currentAmdCodeName || this.currentAmdCodeName == '') {
            this.errorAction = 'AMD Code Name can not be null.';
            return;
        }
        this.isLoading = true;
        var clinicUpdate = {
            id: this.data.clinic.id,
            clinicId: this.currentClinicId,
            amdcodeName: this.currentAmdCodeName,
            officeKey: this.currentOfficeKey,
            amdCodePrefix: this.currentAmdCodePrefix
        };
        this.apiService.updateClinic(clinicUpdate).subscribe(r => {
            this.isLoading = false;
            this.dialogRef.close(true);
            this.showAlertModal('Update Clinic', `Update Clinic ${this.currentClinicId} Successfully!`, 'success' );
        })
    }

    saveAddClinic() {
        this.errorAction = '';
        if (!this.clinicId133358 || this.clinicId133358 == '') {
            this.errorAction = 'Clinic can not be null.';
            return;
        }
        if (!this.clinicId132677 || this.clinicId132677 == '') {
            this.errorAction = 'Clinic can not be null.';
            return;
        }
        if (!this.amdCodeName || this.amdCodeName == '') {
            this.errorAction = 'AMD Code Name can not be null.';
            return;
        }
        this.isLoading = true;
        var clinicProfiles = [];
        _.each(this.allOfficeKeys, o => {
            if (o.value != '136989') {
                clinicProfiles.push(
                    {
                        clinicId: o.value == 133358 ? this.clinicId133358 : this.clinicId132677,
                        clinicFullName: this.clinicFullName,
                        amdCodeName: this.amdCodeName,
                        officeKey: o.value
                    }
                );
            }
        });
        //console.log(clinicProfiles)
        this.apiService.addNewClinics(clinicProfiles).subscribe(r => {
            if (r) {
                this.isLoading = false;
                this.dialogRef.close(true);
                this.showAlertModal('Add Clinic', `Add Clinic Successfully!`, 'success');
            }
        }, error => {
            console.log(error);
            this.errorAction = error.error.Message;
            this.isLoading = false;
        })
    }

    showAlertModal(title, message, status) {
        const dialogRef = this.matDialog.open(AlertModalComponent, {
            minWidth: '600px',
            data: {
                title: title,
                type: status,
                message: message
            },
        });

        dialogRef.afterClosed().subscribe(result => {

        });
    }



}
