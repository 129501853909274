import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'confirm-update-rules-dialog',
    templateUrl: './confirm-update-rules-dialog.component.html',
    styleUrls: ['./confirm-update-rules-dialog.component.scss']

})
export class ConfirmUpdateRulesDialog implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ConfirmUpdateRulesDialog>) { }

    ngOnInit(): void {
    }


    closeWithAction(confirm = false) {
        this.dialogRef.close(confirm);
    }

}
