import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from '../../services/api.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { interval } from 'rxjs';

@Component({
    selector: 'app-missing-chart-files',
    templateUrl: './missing-chart-files.component.html',
    styleUrls: ['./missing-chart-files.component.scss']
})
export class MissingChartFilesComponent implements OnInit {
    public total: number;
    public pageSize: number = 20;
    page: any = { CurrentPage: 0 };
    userId: string = "";
    missingChartsInfo = new Array<any>();
    flaggedVisitIds = new Array<any>();
    public isLoading: boolean = true;
    public message: string = "";
    isShowToast: boolean = false;
    stringToast: string = "";
    classToast: string = "";
    classAlert: string = "";

    constructor(
        private apiService: ApiService
    ) {
        this.page.PageSize = 10000
        this.userId = localStorage.getItem('userId')
    }

    ngOnInit(): void {
        console.log("Im in!");
        var payload = {
            pageSize: this.pageSize,
            pageNumber: 0,
        }

        this.loadData(payload);
    }


    loadData(payload) {
        this.isLoading = true;
        this.getMissingChartsData();
    }


    getMissingChartsData() {
        var payload = {
            pageSize: this.pageSize,
            pageNumber: 0,
        }

        this.apiService.getMissingChartsData(payload).subscribe((r) => {
            this.missingChartsInfo = r.missingChartsInfo || []
            this.total = r.total
            this.isLoading = false;
        });
    }


    gotoLinkedRecord(visitId) {
        window.open("/record/loadcodeNew?visitId=" + visitId, "_blank")
    }

    showToast(message, style) {
        switch (style) {
            case "info":
                this.classToast = "toast-info"
                break;
            case "success":
                this.classToast = "toast-success"
                break;
            case "danger":
                this.classToast = "toast-danger"
                break;
            case "warning":
                this.classToast = "toast-warning"
                break;
            default:
                this.classToast = "toast-light"
                break;
        }
        this.isShowToast = true
        this.stringToast = message;
        setTimeout(() => {
            this.isShowToast = false;
            this.stringToast = "";
            this.classAlert = "toast-light";
        }, 3000)
    }
}
