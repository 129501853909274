<div class="row">
    <div>
        <h1 style="width:auto;">Clinic Setting</h1>
    </div>
</div>

<div style=" border-top: 5px solid #fbaf03; background-color:white;">
    <div class="btn-add-action" style="width: 100%; text-align:end; padding: 15px;">
        <button mat-raised-button style="background-color: #4ac94a;" (click)="openModalNewClinic()">New Clinic</button>
    </div>
    <ngx-datatable class="material" [rows]="clinicProfiles" [headerHeight]="50" [footerHeight]="50" rowHeight="auto"
                   [scrollbarH]="true" [loadingIndicator]="isLoading" [count]="total" offset="0"
                   [limit]="total" [externalPaging]="true">

        <ngx-datatable-column name="PV ID" [width]="350">
            <ng-template let-row="row" ngx-datatable-cell-template style="display:flex; align-items: center; justify-content: center">
                {{ row.groupId }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="AMD ID" [width]="350">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <div *ngFor="let clinic of row.clinics">
                    <span style="margin: 10px">{{ clinic.amdcodeName }}</span>
                </div>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="OFFICE KEY" [width]="350">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <div *ngFor="let clinic of row.clinics">
                    <span style="margin: 10px">{{ clinic.officeKey }}</span>
                </div>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="ACTIONS" [width]="250">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <div *ngFor="let clinic of row.clinics">
                    <span style="margin: 10px; text-decoration:underline; cursor: pointer" (click)="openUpdateClinic(clinic)">Edit</span>
                </div>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>

</div>
