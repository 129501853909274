import { Injectable } from '@angular/core';
import { Subject } from "rxjs";
import * as _ from 'lodash'

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private emitVisitRuleSets = new Subject<any>();
  visitRuleSetsEmitted = this.emitVisitRuleSets.asObservable();

  constructor() { }
  
  changeVisitRuleSet(visitRuleSets: any) {
    this.emitVisitRuleSets.next(visitRuleSets);
  }
}
