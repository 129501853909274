import { error } from '@angular/compiler/src/util';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import { SnotifyService } from 'ng-snotify';
import { ApiService } from '../../../services/api.service';

@Component({
    selector: 'app-addendum-setting-modal',
    templateUrl: './addendum-setting.component.html',
    styleUrls: ['./addendum-setting.component.scss']

})
export class AddendumSettingModalComponent implements OnInit {

    isLoading = true;
    addendumItems = [];
    currentVisitId = 0;
    error = "";
    constructor(public dialogRef: MatDialogRef<AddendumSettingModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private apiService: ApiService,
                private snotifyService: SnotifyService
    ) { }

    ngOnInit() {           
        this.getData();
    }

    getData() {
        this.isLoading = true;
        this.currentVisitId = this.data.visitId;
        this.apiService.getAddendumItems().subscribe(r => {
            console.log(r)
            this.addendumItems = r;
            _.each(this.addendumItems, item => {
                item.checked = false;
                if (item.additionalInputs > 0 && item.inputLabels) {               
                    var linputLabelJson = JSON.parse(item.inputLabels);
                    item.inputLabels = Object.entries(linputLabelJson).map(([key, value]) => ({index:key, keyLabel: value, value: null }));
                }
            })
            this.isLoading = false;   

        }, error => {
            console.log(error);
            this.isLoading = false;   
        })
    }

    submitAddendum() {
        this.error = "";
        const noneChecked = _.every(this.addendumItems, item => !item.checked);
        if (noneChecked) {
            this.error = "Please check at least one item."
            return;
        }

        const hasInvalidValues = _.some(this.addendumItems,item =>
            item.checked && item.inputLabels && item.inputLabels.some(label => !label.value)
        );
        if (hasInvalidValues) {
            this.error = "Invalid value. Please fill in all required fields."
            return;
        }

        this.isLoading = true;
        var contents = [];
        _.each(this.addendumItems, item => {
            if (item.checked) {
                var content = this.replacePlaceholders(item.documentText, item.inputLabels);
                contents.push(content);
            }
        });


        this.apiService.submitAddendumVisit({ visitId: this.currentVisitId, documentsTexts: contents }).subscribe(response => {

            this.isLoading = false;
            this.dialogRef.close(response);
            this.snotifyService.success("Successfully!");
            //this.openPDFInNewTab(response);
        }, error => {
            this.isLoading = false;
        })
    }

    replacePlaceholders(text: string, dataArray: any[]): string {
        _.each(dataArray, (item) => {
            const placeholder = `{${item.index}}`;
            text = text.replace(placeholder, " "+item.value.toString());
        });

        return text;
    }

    openPDFInNewTab(pdfBytes) {
        const byteArray = new Uint8Array(
            atob(pdfBytes)
                .split('')
                .map((char) => char.charCodeAt(0)));
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);

        const newTab = window.open();
        newTab.document.write(`<iframe width='100%' height='100%' src='${blobUrl}'></iframe`);
    }

    closeDialog() {
        this.dialogRef.close();
    }


}
