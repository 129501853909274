import { Component, OnInit, Inject } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ApiService } from '../../../services/api.service';

import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'app-visit-tag-modal',
  templateUrl: './visit-tag-modal.component.html',
  styleUrls: ['./visit-tag-modal.component.scss']
})
export class VisitTagModalComponent implements OnInit {
  public separatorKeysCodes = [ENTER, COMMA];
  public visitTags: any = []
  public visitId: number = 0;
  public isLoading: boolean = false;

  constructor(
    private matDialog: MatDialog,
    public dialogRef: MatDialogRef<VisitTagModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.visitTags = this.data.visitTags,
    this.visitId = this.data.visitId
  }

  addTag(event) {
    if (event.value) {
      this.visitTags.push(event.value.trim());
    }
    if (event.input) {
      event.input.value = '';
    }
  }

  removeTagChip(tag, index) {
    this.visitTags = _.filter(this.visitTags, (v, i) => {
      return i != index
    })
  }

  saveVisitTag() {
    this.isLoading = true
    var payload = {
      visitId: this.visitId,
      tag: this.visitTags?.length > 0 ? JSON.stringify(this.visitTags) : ""
    }
    this.apiService.updateVisitTag(payload).subscribe((r) => {
      if (r) {
        this.showSnackBar(r.message)
        this.isLoading = false
      }
    })
  }

  showSnackBar(message) {
    this._snackBar.open(message)
    setTimeout(() => {
      this._snackBar.dismiss()
    }, 1000);
  }

}
