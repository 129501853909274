import { Component } from '@angular/core';
import { SnotifyService, ToastDefaults, SnotifyDefaults } from 'ng-snotify';
import { Observable, interval } from 'rxjs';
import { ApiService } from 'src/services/api.service';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
  `,
  styles: []
})
export class AppComponent {
    title = 'MRM-ang';
    constructor(
        private snotify: SnotifyService,
        private apiService: ApiService
    ) {
       this.snotify.setDefaults(ToastDefaults as any);
       //this.getUserId()
      //  const numbers = interval(60000);
      //  numbers.pipe().subscribe(x => 
      //   this.checkTokenExpired()
      //  );

    }

    getUserId(){
      this.apiService.getUserId().subscribe((r:any) => {
        if(r) {
          localStorage.setItem('userId', r.userId)
        }
      })
    }

    checkTokenExpired(){
      this.apiService.checkTokenExpired().subscribe((r) => {
        if(!!r){
           window.location.reload()
        }
       })
    }
}
