
<mat-label>Office Key</mat-label>
<input matInput [(ngModel)]="model.officeKey" />

<mat-label>patient Id</mat-label>
<input matInput [(ngModel)]="model.patId" />

<button mat-raised-button (click)="getHistory()">Get Finanical History</button>


<br />
example, officeKey: 132677 patId: pat1692879
<br />

<div>
    <p>
        {{result}}
    </p>
</div>