import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ColumnMode } from '@swimlane/ngx-datatable';
import * as _ from 'lodash';
import { ApiService } from '../../services/api.service';
import { UpdateClinicModalComponent } from '../modal/update-clinic-modal/update-clinic-modal.component';


@Component({
    selector: 'app-setting-clinic',
    templateUrl: './setting-clinic.component.html',
    styleUrls: ['./setting-clinic.component.scss']

})
export class SettingClinicComponent implements OnInit {

    public isLoading = true;
    public clinicProfiles = [];
    public total = 0;
    ColumnMode = ColumnMode;

    constructor(private apiService: ApiService,
                private matDialog: MatDialog) { }

    ngOnInit() {
        this.getClinics();
    }

    getClinics() {
        this.isLoading = true;
        this.apiService.getAdminClinics().subscribe(r => {

            var clinics = r;
            _.each(clinics, clinic => {
                var id = clinic.clinicId;
                //if (id.startsWith("FP")) {
                //    id = id.replace("FP", "");
                //}
                clinic.groupId = id;
            })

            const groupedClinics = clinics.reduce((acc, clinic) => {
                const groupId = clinic.groupId;
                if (!acc[groupId]) {
                    acc[groupId] = [];
                }

                acc[groupId].push(clinic);
                return acc;
            }, {});

            this.clinicProfiles = Object.entries(groupedClinics).map(([groupId, clinics]) => {
                return { groupId, clinics };
            });
            _.each(this.clinicProfiles, clinic => {
                clinic.groupId = clinic.clinics[0].clinicId;
            })
            this.total = this.clinicProfiles.length;
            this.isLoading = false;            
        })
    }

    openUpdateClinic(clinic) {
        var dialog = this.matDialog.open(UpdateClinicModalComponent, {
            minWidth: '800px',
            disableClose: true,
            data: { clinic: clinic }
        });

        dialog.afterClosed().subscribe(async r => {
            if (r) {
                this.getClinics();
            }
        })
    }

    openModalNewClinic() {
        var dialog = this.matDialog.open(UpdateClinicModalComponent, {
            minWidth: '800px',
            disableClose: true,
            data: null
        });

        dialog.afterClosed().subscribe(async r => {
            if (r) {
                this.getClinics();
            }
        })
    }

}
