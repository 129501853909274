<div mat-dialog-content>
    <div class="modal-body mx-3">
        <div class="icon-header">
            <span *ngIf="type == 'success'" class="glyphicon glyphicon-ok-circle success" arial-hidden="true" data-></span>
            <span *ngIf="type == 'warning'" class="glyphicon glyphicon-warning-sign warning" arial-hidden="true" data-></span>
            <span *ngIf="type == 'error'" class="glyphicon glyphicon-remove error" arial-hidden="true" data-></span>
            <span *ngIf="type == 'info'" class="glyphicon glyphicon-info-sign info" arial-hidden="true" data-></span>
        </div>
        <div class="body">
            <h4>{{title}}</h4>
            <span>{{message}}</span>
        </div>
        <div class="footer">
            <button class="btn btn-secondary" style="margin-right: 5px" mat-dialog-close>Close</button>
        </div>
    </div>
</div>
