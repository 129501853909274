import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'app-activity-log',
    templateUrl: './activity-log.component.html',
    styleUrls: ['./activity-log.component.scss']
})
export class ActivityLogComponent implements OnInit {
    public total: number;
    public activityLogs: any = [];
    public selectedActivityIndex: any;
    public isLoading: boolean = false;

    public listActions = [
        { value: "addMod", name: "Add Modifier" },
        { value: "delMod", name: "Delete Modifier" },
        { value: "addCpt", name: "Add CPT" },
        { value: "delCpt", name: "Delete CPT" },
        { value: "addIcd", name: "Add ICD" },
        { value: "delIcd", name: "Delete ICD" },
        { value: "updPhys", name: "Update Physicin" },
        { value: "updOfficeKey", name: "Update OfficeKey" },
        { value: "unFlag", name: "Clear Flag" },
        { value: "updClinic", name: "Update Clinic" },
        { value: "unTag", name: "Clear Tag" },
        { value: "unRule", name: "Clear Rule" },
        { value: "updFinClass", name: "Update Fin Class" },
        { value: "addNote", name: "Add Note" },
    ]

    public pageSize: number = 20;
    public searchText: string = ''
    public searchPatientName: string = ''
    public startDate: any;
    public endDate: any;

    constructor(
        private apiService: ApiService
    ) {
    }

    ngOnInit(): void {
        this.startDate = moment().startOf('month').format();
        this.endDate = moment().endOf('month').format();

        var payload = {
            pageSize: this.pageSize,
            pageNumber: 0,
            startDate: this.startDate,
            endDate: this.endDate
        }
        this.loadData(payload);
        this.searchVisitId = _.debounce(this.searchVisitId, 1500)
    }

    loadData(payload) {
        this.isLoading = true
        this.apiService.getActivityLogTimeLine(payload).subscribe((r) => {
            this.activityLogs = r.activityLogs
            this.total = r.total

            _.each(this.activityLogs, (e) => {
                var body = e.requestJson.split('Body: ')
                if (body[1]) {
                    e.parseRequest = JSON.parse(body[1])
                }
                e.action = this.getActionMethod(e.methodName)

                if (e.methodName == 'ExecuteActBulkUpdate') {
                    _.each(e.parseRequest.DataActions, (ac) => {
                        var infoAction = _.find(this.listActions, (action) => { return action.value == ac.value })
                        console.log('infoAction', infoAction)
                        ac.nameAction = infoAction ? infoAction.name : ""
                    })
                }
            })
            this.isLoading = false;
        })
    }

    getActionMethod(methodName) {
        switch (methodName) {
            case "ExecuteActBulkUpdate":
                return "Execute Action Bulk Update For List Visit"
            default:
                return "";
        }
    }

    getValueAction(ac, parseRequest) {
        switch (ac.value) {
            case "addMod":
                return `: Code Name: ${ac.codeName}, Modifier: ${ac.modifier}`
            case "delMod":
                return `: Code Name: ${ac.codeName}, Modifier: ${ac.modifier}`
            case "addCpt":
                return `: Code Name: ${ac.codeName}`
            case "delCpt":
                return `: Code Name: ${ac.codeName}`
            case "addIcd":
                return `: Code Name: ${ac.codeName}`
            case "delIcd":
                return `: Code Name: ${ac.codeName}`
            case "updPhys":
              if(parseRequest?.DataBeforeUpdates){
                let context = this.showValueBeforeUpdate(parseRequest.DataBeforeUpdates, ac.value, ac.physician)
                return context
              }else{
                return `: ${ac.physician}`
              }
            case "updClinic":
                if(parseRequest?.DataBeforeUpdates){
                   let content =  this.showValueBeforeUpdate(parseRequest.DataBeforeUpdates, ac.value, ac.clinic)
                   return content
                }else{
                    return `: ${ac.clinic}`
                }
            case "updOfficeKey":
                if(parseRequest?.DataBeforeUpdates){
                    let content = this.showValueBeforeUpdate(parseRequest.DataBeforeUpdates, ac.value, ac.officeKey)
                    return content
                }else{
                    return `: ${ac.officeKey}`
                }
            case "unFlag":
                return ''
            case "unTag":
                    return ': Value'
            default:
                return "";
        }
    }

    showValueBeforeUpdate(dataBeforeUpdates, value, newValue){
       var content = ""
        _.each(dataBeforeUpdates, (e, index) => {
            content += index == 0? ": " : ""
            content += `Update VisitId: ${e.visitId} From ${value == 'updPhys' ? e.physicanId : value == "updClinic" ? e.clinicId : e.officeKey} to ${newValue}`
            content += dataBeforeUpdates.length - 1 == index ? "." : ", "
       })

       return content
    }

    viewMore(index) {
        this.selectedActivityIndex = (this.selectedActivityIndex !== index) ? index : null;
    }

    changePage(event) {
        var payload = { 
            pageSize: this.pageSize,
            pageNumber: event.pageIndex,
            startDate: moment(this.startDate).format(), 
            endDate: moment(this.endDate).format(),
            searchText: this.searchText,
            searchPatientName: this.searchPatientName
        }
        this.loadData(payload);
    }

    searchVisitId() {
        var payload = {
            pageSize: this.pageSize,
            pageNumber: 0,
            searchText: this.searchText,
            searchPatientName: this.searchPatientName,
            startDate: moment(this.startDate).format(),
            endDate: moment(this.endDate).format()
        }
        this.loadData(payload);
    }

    clearSearch() {
        var payload = {
            pageSize: this.pageSize,
            pageNumber: 0,
            searchText: '',
            searchPatientName: "",
            startDate: moment(this.startDate).format(),
            endDate: moment(this.endDate).format()
        }
        this.loadData(payload);
    }

    refetch() {
        this.startDate = moment().startOf('month').format();
        this.endDate = moment().endOf('month').format();
        this.searchText = ''
        this.searchPatientName = ''
        var payload = {
            pageSize: this.pageSize,
            pageNumber: 0,
            startDate: this.startDate,
            endDate: this.endDate,
            searchText: '',
            searchPatientName: ''
        }
        this.loadData(payload);
    }

    filterDate() {
        var payload = {
            pageSize: this.pageSize,
            pageNumber: 0,
            startDate: moment(this.startDate).format(),
            endDate: moment(this.endDate).format(),
            searchText: this.searchText,
            searchPatientName: this.searchPatientName
        }
        this.loadData(payload);
    }
   
    mouseOver(visit){
      visit.isVisible = true
    }

    mouseOut(visit){
      visit.isVisible = false
    }
}
