import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { DialogLoadingComponent } from 'src/components/dialog-loading/dialog-loading.component';

import { ApiService } from 'src/services/api.service';

import * as _ from 'lodash';

@Component({
    selector: 'app-update-proc-confirm',
    templateUrl: './update-proc-confirm.component.html',
    styleUrls: ['./update-proc-confirm.component.scss']
})
export class UpdateProcConfirmComponent implements OnInit {
  public isLoading: boolean = false
  public isSaving: boolean = false
  public isSubmitted: boolean = false

  public visitId: number;
  public officeKey: string = '';
  public visitProcCodes: any = []
  public visitProcCodeForm: FormGroup;
  public selectedProcCode: any;
  public dialogLoading: any;
    public isFinish: boolean = false;
    oldProcCode = '';
    newProcCode = '';
    checkOk = false;
    isCheckOnly;
  constructor(
    private apiService: ApiService,
      public dialogRef: MatDialogRef<UpdateProcConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private matDialog: MatDialog,
  ) { }

  ngOnInit() {
     this.visitId = this.data.visitId
      this.officeKey = this.data.officeKey;
      this.oldProcCode = this.data.oldProcCode;
      this.isCheckOnly = this.data.isCheckOnly;
     this.initForm()
     this.loadData()
    }


  loadData(){
    this.isLoading = true
    this.apiService.getVisitProcCodesByVisitId(this.visitId, this.officeKey).subscribe((r) => {
        this.visitProcCodes = r
        this.isLoading = false
        this.isFinish = _.filter(this.visitProcCodes, (e) => {return e.isMissingAmd}).length > 0 ? false : true
    })
  }

  initForm() {
    this.visitProcCodeForm = this.fb.group({
      procCode: ['', [Validators.required]],
    })
  }

  get visitF() { return this.visitProcCodeForm.controls }

  editVisitProcCode(item){
    this.selectedProcCode = item;
    this.visitProcCodes.forEach(e => {
      e.isActive = e.visitProcCodeId == item.visitProcCodeId ? true : false
    });
    if (item) {
      this.visitProcCodeForm.patchValue({
        procCode: item.procCode,
      })
    }
  }

  deleteVisitProcCode(item){
    if (confirm('You are about to delete a visit procCode, are you sure?')) {
      this.openLoading()
      this.apiService.deleteVisitProcCodeById(item.visitProcCodeId).subscribe((r ) => {
        this.closeLoading()  
        if(r){
          this.visitProcCodes = _.filter(this.visitProcCodes, (e) => {return e.visitProcCodeId != item.visitProcCodeId})
          this.selectedProcCode = null
          this.showSnackBar("Delete Visit ProcCode Successfully!")
        }else{
          this.showSnackBar("Not Found Visit ProcCode!")
        }          
      })
    }
  }

  save(){
    this.isSaving = true
    this.isSubmitted = true
    let payload = {
       visitId: this.visitId,
       procCode: this.visitProcCodeForm.value.procCode,
       visitProcCodeId: this.selectedProcCode.visitProcCodeId
    }
    this.apiService.updateVisitProcCode(payload).subscribe((r) => {
      this.isSaving = false
      this.isSubmitted = false
      this.showSnackBar('Updated Succesffuly!')
      localStorage.setItem('highlight-visitId', this.visitId.toString())
      this.dialogRef.close('saved')
    }, err => {
      this.isSaving = false
      this.isSubmitted = false
      this.showSnackBar('Updated Unsuccesffuly!')
    })
  }

  openLoading() {
    this.dialogLoading = this.matDialog.open(DialogLoadingComponent, {
         minWidth: '150px',
         disableClose: true
     });
 }

 closeLoading() {
     this.dialogLoading.close()
 }

 checkOnAMD(){

  let payload = {
    visitId: this.visitId,
      officeKey: this.officeKey,
      procCode: (this.isCheckOnly ? this.oldProcCode : this.newProcCode)
  }
  this.apiService.checkVisitProcodeWithAmd(payload).subscribe((r) => {
    console.log(r)
      if (r.procCodeAMDId && r.procCodeAMDId != 'NotFound') {
          this.showSnackBar('YAY! found on AMD. You can proceed now.')
          this.checkOk = true;
      } else {
          this.checkOk = false;
          this.showSnackBar('NOT found on AMD')
      }
  }, err => {
      console.log('er', err)
      this.checkOk = false;
      this.showSnackBar('NOT found on AMD')
  })
    }

    proceed() {
        let payload = {
            officeKey: this.officeKey,
            oldProcCode: this.oldProcCode ,
            newProcCode: this.newProcCode
        }

        this.apiService.updateProcCodeForRefVisits(payload).subscribe((r) => {

            if (r) {
                this.showSnackBar('Proceeded '+ r + 'visits. You can close this popup')
               
            }
        }, err => {
            console.log('er', err)
        })
    
    }

 showSnackBar(message) {
  this._snackBar.open(message)
  setTimeout(() => {
      this._snackBar.dismiss()
  }, 4000); 
  
}
}
