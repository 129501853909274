<h1 mat-dialog-title>
  Visit Chart Doc
</h1>
<div>
  VisitId: {{visitId}}
</div>
<div mat-dialog-content>
  <div class="modal-body mx-3 row" *ngIf="isLoading">
    <div class="col-lg-12">
      <span>Loading...</span>
    </div>
  </div>
  <div class="modal-body mx-3 row" *ngIf="!isLoading">
    <div class="col-lg-12" *ngIf="charts?.length == 0">
      <span>No Data!</span>
    </div>
    <div class="col-lg-12">
      <div class="chart-row" *ngFor="let item of charts">
        <div class="chart-left">
          <div class="chart-icon">
            <span class="glyphicon glyphicon-paperclip" [ngClass]="item.isExist? 'exist' : 'missing' "></span>
          </div>
          <div class="chart-info">
            <p>{{item.fileName}}</p>
            <span [ngClass]="item.isExist? 'exist' : 'missing' ">{{item.isExist ? 'Exist' : 'Missing'}}</span>
          </div>
        </div>
        <div class="chart-right" *ngIf="!item.isExist">
          <button class="btn btn-primary" [disabled]="item.isUploading">
             <ng-container *ngIf="!item.isUploading">
              Upload File
             </ng-container>
             <ng-container *ngIf="item.isUploading">
              Uploading...
             </ng-container>
          </button>
          <input type="file" class="upload" (change)="uploadFiles($event, item)" [disabled]="item.isUploading"/>
        </div>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions style="justify-content: end;">
  <button class="btn btn-secondary" style="margin-right: 5px" mat-dialog-close>Close</button>
  <button class="btn btn-primary" (click)="requeueVisitChartDoc()" [disabled]="isSaving">
    <ng-container *ngIf="!isSaving">Re-queue</ng-container>
    <ng-container *ngIf="isSaving">Saving...</ng-container>
  </button>
</div>