import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ApiService } from '../../services/api.service';
import * as moment from 'moment';


@Component({
    selector: 'app-general-reports',
    templateUrl: './general-reports.component.html',
    styleUrls: ['./general-reports.component.scss']

})
export class GeneralReportsComponent implements OnInit {
    companyEMR: any;
    today = new Date();
    tabActiveId = 'visit-report';
    tabs = [
        { id: 'visit-report', displayName: 'Visit Report', forCompanyEmrId: 2},
        { id: 'visit-notFinalized-reason', displayName: 'Not Finalized Visits', forCompanyEmrId: 2 }
    ]
   
    //visit report
    sendToMrmStatusOptions = ["PENDING", "DONE", "ERROR"];
    sendToAMDStatusOptions = ["DONE", "ERROR", "FAILED", "PROCESSING", "PROCESSING_TO_AMD", "QUEUED", "QUEUED_OLD"];

    filterVisitReport = {
        startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
        endDate: new Date(),
        lstSendToMRMStatus: [...this.sendToMrmStatusOptions],
        lstsendToAMDStatus: [...this.sendToAMDStatusOptions]
    }
    reportVisitResult = {
        pending: false,
        messageError: "",
        messageWarning: "",
        prviewTotalData: [],
        previewPage : {
            paginatedData: [],
            currentPage: 1,
            totalPages: 1
        }
    };

    //Visit not finalized
    filterNotFinalized = {
        startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
        endDate: new Date(),
    }
    reportNotFinalizedResult = {
        pending: false,
        messageError: "",
        messageWarning: "",
        prviewTotalData: [],
        previewPage: {
            paginatedData: [],
            currentPage: 1,
            totalPages: 1
        }
    };
   
    constructor(private apiService: ApiService) {}

    ngOnInit() {
        //this.getUserCompanyEMR();
    }

    //getUserCompanyEMR() {
    //    this.apiService.getUserCompanyEMR().subscribe(result => {
    //        this.companyEMR = result;
    //        this.tabs = _.filter(this.tabs, tab => tab.forCompanyEmrId == this.companyEMR.emR_Id)
    //    })
    //}

    selectTab(tabId){
        this.tabActiveId = tabId;
    }


    downloadVisitReport() {
        const today = new Date();
        if (this.filterVisitReport.startDate > today || this.filterVisitReport.endDate > today) {
            this.reportVisitResult.messageError = "Invalid Date: Start date or End date cannot be greater than today.";
            return;
        }

        this.reportVisitResult.pending = true;
        this.reportVisitResult.messageError = "";
        this.reportVisitResult.messageWarning = "";

        this.apiService.getFileDownloadVisitReport(this.filterVisitReport).subscribe(response => {
            const blob: Blob = response.body as Blob;
            const contentDisposition = response.headers.get('Content-Disposition');
            let fileName = `VisitReport_${moment(this.filterVisitReport.startDate).format('MM-DD-YYYY')}_${moment(this.filterVisitReport.endDate).format('MM-DD-YYYY')}.xlsx`;

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();

            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
            this.reportVisitResult.pending = false;
            this.reportVisitResult.messageError = "";
        }, err => {
            console.log("download visit report error", err)
            this.reportVisitResult.pending = false;
            this.reportVisitResult.messageError = err?.error?.Message ?? err?.message
        });
    }

    previewVisitReport() {
        this.reportVisitResult = {
            pending: true,
            messageError: "",
            messageWarning :"",
            prviewTotalData: [],
            previewPage: {
                paginatedData: [],
                currentPage: 1,
                totalPages: 1
            }
        };
        this.apiService.previewReportFromVisitPreImport(this.filterVisitReport).subscribe(r => {
            this.reportVisitResult.prviewTotalData = _.orderBy(r, ['dos'], ['desc']);
            this.reportVisitResult.previewPage.totalPages = Math.ceil(this.reportVisitResult.prviewTotalData.length / 25);
            this.updatePaginatedData(this.reportVisitResult);
            this.reportVisitResult.pending = false;
            this.reportVisitResult.messageError = ""
            if (this.reportVisitResult.prviewTotalData && this.reportVisitResult.prviewTotalData.length ==0) {
                this.reportVisitResult.messageWarning = "No data found within the selected filter"
            }
        }, err => {
            console.log("preview visit report error", err)
            this.reportVisitResult.pending = false;
            this.reportVisitResult.messageError = err?.error?.Message ?? err?.message
            this.reportVisitResult.messageWarning = "";
        })
    }


    previewVisitNotFinalizedReason() {
        this.reportNotFinalizedResult = {
            pending: true,
            messageError: "",
            messageWarning:"",
            prviewTotalData: [],
            previewPage: {
                paginatedData: [],
                currentPage: 1,
                totalPages: 1
            }
        };
        this.apiService.previewVisitNotFinalizedReason(this.filterNotFinalized).subscribe(r => {
            this.reportNotFinalizedResult.prviewTotalData = _.orderBy(r, ['visitDateTime'], ['desc']);
            this.reportNotFinalizedResult.previewPage.totalPages = Math.ceil(this.reportNotFinalizedResult.prviewTotalData.length / 25);
            this.updatePaginatedData(this.reportNotFinalizedResult);
            this.reportNotFinalizedResult.pending = false;
            this.reportNotFinalizedResult.messageError = "";

            if (this.reportNotFinalizedResult.prviewTotalData && this.reportNotFinalizedResult.prviewTotalData.length == 0) {
                this.reportNotFinalizedResult.messageWarning = "No data found within the selected date range."
            }
        }, err => {
            console.log("preview visit report error", err)
            this.reportNotFinalizedResult.pending = false;
            this.reportNotFinalizedResult.messageError = err?.error?.Message ?? err?.message;
            this.reportNotFinalizedResult.messageWarning = "";
        })
    }
    updatePaginatedData(reportResult: any) {
        const startIndex = (reportResult.previewPage.currentPage - 1) * 25;
        reportResult.previewPage.paginatedData = reportResult.prviewTotalData.slice(startIndex, startIndex + 25);
    }

    changePage(direction: number, reportResult: any) {
        reportResult.previewPage.currentPage += direction;
        this.updatePaginatedData(reportResult);
    }


    formatDateToCST(dateTimeStr: any): string {
        const date = moment(dateTimeStr);
        const cstDate = moment(date).add(-6, 'hours');
        const formattedDate = moment(cstDate).format('MM/DD/YYYY HH:mm');
        return formattedDate + ' (CST)';
    }
}
