<h1 mat-dialog-title>
  Edit Visit DiagCode
</h1>
<div>
    VisitId: {{visitId}}    OfficeKey: {{officeKey}}
</div>
<div mat-dialog-content>
  <div class="modal-body mx-3 row" *ngIf="isLoading">
    <div class="col-lg-12">
      <span>Loading...</span>
    </div>
  </div>
  <div class="modal-body mx-3 row" *ngIf="!isLoading">
    <div class="col-lg-4" *ngIf="visitDiagCodes?.length > 0">
      <ul class="list-group">
        <li class="list-group-item" *ngFor="let item of visitDiagCodes" [ngClass]="item.isActive? 'active' : '' ">
          <div class="row-item">
              <div>
                  <span *ngIf="!item.isMissingAmd" style="color:green" class="glyphicon glyphicon-ok" arial-hidden="true" data-></span>
                  <span *ngIf="item.isMissingAmd" matTooltip="This proc code was missing on AMD" style="color:red" class="glyphicon glyphicon-info-sign" arial-hidden="true" data-></span>
                  <h5>{{item.icdCode}}</h5>
              </div>
            <div>
                <button mat-icon-button class="edit-DiagCode" aria-label="Check DiagCode" matTooltip="Check DiagCode"
                        (click)="checkOnAMD(item)">
                    <span class="glyphicon glyphicon-eye-open" arial-hidden="true" data-></span>
                </button>
                <button mat-icon-button class="edit-DiagCode" aria-label="Edit Visit DiagCode" matTooltip="Edit Visit DiagCode"
                        (click)="edit(item)">
                    <span class="glyphicon glyphicon-pencil" arial-hidden="true" data-></span>
                </button>
                <button mat-icon-button class="delete-DiagCode" aria-label="Delete Visit DiagCode" matTooltip="Delete Visit DiagCode"
                        (click)="deleteVisitDiagCode(item)">
                    <span class="glyphicon glyphicon-trash" arial-hidden="true" data-></span>
                </button>
            </div>

          </div>
        </li>
      </ul>
    </div>

    <div class="col-lg-12" *ngIf="visitDiagCodes?.length == 0">
        <span>No Data!</span>
    </div>
  </div>
</div>
<div mat-dialog-actions style="justify-content: end;">
  <button class="btn btn-secondary" style="margin-right: 5px" mat-dialog-close>Close</button>
  <!--<button class="btn btn-primary" [disabled]="visitDiagCodeForm.invalid && !isFinish" (click)="save()">-->
    <!-- <ng-container *ngIf="!isSaving">Update & Re-queue</ng-container> -->
    <!--<ng-container *ngIf="!isSaving">Re-queue</ng-container>
    <ng-container *ngIf="isSaving">Saving...</ng-container>-->

</div>