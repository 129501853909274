import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DebugToolComponent } from '../components/debug-tool/debug-tool.component';
import { ImportRecordComponent } from '../components/import-record/import-record.component';
import { BulkUpdateComponent } from '../components/bulk-update/bulk-update.component';
import { CodeReviewRuleComponent } from '../components/code-review-rule/code-review-rule.component';
import { ActivityLogComponent } from '../components/activity-log/activity-log.component';
import { RulesAutomationComponent } from '../components/rules-automation/rules-automation.component';
import { CodeRecordViewComponent } from '../components/code-record-view/code-record-view.component';
import { DashboardComponent } from '../components/dashboard/dashboard.component';
import { PhysiciansComponent } from '../components/physicians/physicians.component';
import { InsuranceInformationComponent } from 'src/components/insurance-information/insurance-information.component';
import { ManageClinicComponent } from '../components/manage-clinic/manage-clinic.component';
import { SettingClinicComponent } from '../components/setting-clinic/setting-clinic.component';
import { ActionSetupManagementComponent } from '../components/action-setup-management/action-setup-management.component';
import { AddendumSetupComponent } from '../components/addendum-setup/addendum-setup.component';

const routes: Routes = [
    {
        path:"record/ImportReport",
        component: ImportRecordComponent
    },
    //{
    //    path: "record/DebugTool",
    //    component: DebugToolComponent
    //},
    {

        path: "record/BulkUpdateNew",
        component: BulkUpdateComponent
    },
    {

        path: "admin/ActionSetupManagemnet",
        component: ActionSetupManagementComponent
    },
    {

        path: "admin/CodeReviewRule",
        component: CodeReviewRuleComponent
    },
    {

        path: "activitylog/GetActivityLog",
        component: ActivityLogComponent
    },
    {

        path: "rulesautomation/GetRulesAutomation",
        component: RulesAutomationComponent
    },
    {

        path: "record/loadcodeNew",
        component: CodeRecordViewComponent
    },
    {

        path: "Home/Index",
        component: DashboardComponent,
        pathMatch: 'full',
    },
    {
        path: "",
        component: DashboardComponent,
        pathMatch: 'full',
    },
    {
        path: "admin/Physicians",
        component: PhysiciansComponent
    },
    {
        path: "admin/InsuranceInformation",
        component: InsuranceInformationComponent
    },
    //{
    //    path: "userAdmin/ManageClinic",
    //    component: ManageClinicComponent
    //},
    {
        path: "admin/Clinic",
        component: SettingClinicComponent
    },
    {

        path: "admin/AddendumSetup",
        component: AddendumSetupComponent
    },

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
