<h1 mat-dialog-title>{{isUpdate? 'Update Clinic': 'Add New Clinic'}}</h1>
<div mat-dialog-content>
    <div style="width: 100%; display: flex; justify-content: center" *ngIf="isLoading">
        <mat-spinner></mat-spinner>
    </div>

    <div *ngIf="!isLoading">
        <div *ngIf="errorAction">
            <p style="color:red">Error: {{errorAction}}</p>
        </div>

        <div *ngIf="isUpdate">
            <div style="display:block; width:100%">
                <h5>{{data.clinic.clinicId}}</h5>
            </div>

            <div style="width: 100%; display: flex; justify-content: space-between;">

                <mat-form-field appearance="fill" style="width: 45%">
                    <mat-label>PV ID</mat-label>
                    <input matInput type="text" [(ngModel)]="currentClinicId" placeholder="AMD Code Name">
                </mat-form-field>

                <mat-form-field appearance="fill" style="width: 45%">
                    <mat-label>AMD ID</mat-label>
                    <input matInput type="text" [(ngModel)]="currentAmdCodeName" placeholder="AMD Code Name">
                </mat-form-field>       
            </div>

            <div style="width: 100%; display: flex; justify-content: space-between;">
                <mat-form-field appearance="fill" style="width: 45%">
                    <mat-label>Office Key Location</mat-label>
                    <mat-select [(ngModel)]="currentOfficeKey">
                        <mat-option *ngFor="let a of allOfficeKeys"
                                    [value]="a.value">
                            {{a.text}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" style="width: 45%">
                    <mat-label>Amd Code Prefix</mat-label>
                    <input matInput type="text" [(ngModel)]="currentAmdCodePrefix" placeholder="AMD Code Prefix">
                </mat-form-field>
            </div>
        </div>

        <div *ngIf="!isUpdate">
            <div>
                <mat-form-field appearance="fill" style="width: 100%">
                    <mat-label>Clinic</mat-label>
                    <input matInput type="text" [(ngModel)]="clinicId133358">
                    <span matSuffix style="opacity:0.7;">133358</span>
                </mat-form-field>
                <!--<span matSuffix style="opacity:0.7;">132677: </span> <span *ngIf="clinic.clinicId">FP{{clinic.clinicId}}</span>-->
            </div>
            <div style="margin-bottom: 15px;">
                <mat-form-field appearance="fill" style="width: 100%">
                    <mat-label>Clinic</mat-label>
                    <input matInput type="text" [(ngModel)]="clinicId132677">
                    <span matSuffix style="opacity:0.7;">132677</span>
                </mat-form-field>
            </div>
            <div style="margin-bottom: 15px;">
                <mat-form-field appearance="fill" style="width: 100%">
                    <mat-label>Clinic Full Name</mat-label>
                    <input matInput type="text" [(ngModel)]="clinicFullName" placeholder="Clinic Full Name">
                </mat-form-field>
            </div>
            <div style="margin-bottom: 15px;">
                <mat-form-field appearance="fill" style="width: 100%">
                    <mat-label>AMD Code Name</mat-label>
                    <input matInput type="text" [(ngModel)]="amdCodeName" placeholder="AMD Code Name">
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions style="justify-content: end;">
    <button class="btn btn-secondary" style="margin-right: 5px" mat-dialog-close >Close</button>
    <button class="btn btn-primary" [disabled]="isLoading" (click)="saveUpdate()" *ngIf="isUpdate">
        <ng-container *ngIf="!isLoading">Update</ng-container>
        <ng-container *ngIf="isLoading">Updating...</ng-container>
    </button>
    <button class="btn btn-primary" [disabled]="isLoading" (click)="saveAddClinic()" *ngIf="!isUpdate">
        <ng-container *ngIf="!isLoading">Add</ng-container>
        <ng-container *ngIf="isLoading">Adding...</ng-container>
    </button>
</div>
