import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { ApiService } from '../../services/api.service';
import { AddClinicModalComponent } from '../modal/add-clinic-modal/add-clinic-modal.component';

@Component({
    selector: 'app-manage-clinic',
    templateUrl: './manage-clinic.component.html',
    styleUrls: ['./manage-clinic.component.scss']

})
export class ManageClinicComponent implements OnInit {

    public isLoading = true;
    public offset = 0;
    public clinics;
    public total = 0;
    public allAMDCodes = [];
    public allOfficeKeys = []; 

    constructor(private apiService: ApiService,
                private matDialog: MatDialog) { }

    ngOnInit() {
        this.getUserClinics();
        this.getClinicOffices();
    }

    getUserClinics() {
        this.isLoading = true;
        var pageNumber = this.offset + 1;
        this.apiService.getUserClinics(pageNumber, 10).subscribe(r => {
            this.clinics = r.records || [];
                
            this.total = r.total || 0;
            this.isLoading = false;
        })
    }

    getClinicOffices() {
        this.apiService.getClinicOffices().subscribe(r => {
            this.allAMDCodes = r.allFacilities;
            this.allOfficeKeys = r.allOfficeKeys
        })
    }

    getPage(event) {
        this.offset = event.offset;
        this.getUserClinics();
    }

    openModalNewClinic() {
        var dialog = this.matDialog.open(AddClinicModalComponent, {
            minWidth: '500px',
            disableClose: true,
            data: { allAMDCodes: this.allAMDCodes, allOfficeKeys: this.allOfficeKeys }
        });

        dialog.afterClosed().subscribe(async r => {
            if (r) {
                this.getUserClinics();
            }
        })
        
    }
}
