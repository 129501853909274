<h2 mat-dialog-title>Addendum for Chart</h2>
<div mat-dialog-content>
    <div style="width: 100%; display: flex; justify-content: center" *ngIf="isLoading">
        <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!isLoading">
        <div *ngFor="let item of addendumItems">
            <div style="margin: 10px 0px">
                <mat-checkbox [(ngModel)]="item.checked" ><span style="font-size: 16px; font-weight:normal">{{item.label}}</span></mat-checkbox>
                <div  *ngIf="item.checked && item.additionalInputs >0 && item.inputLabels ">
                    <div *ngFor="let label of item.inputLabels" style="margin: 0px 10px; display: grid">
                        <span style="font-weight:bold">{{label.keyLabel}} <i style="font-size:10px; color: red;">(Required)</i> </span>
                        <input type="text" placeholder="" [(ngModel)]="label.value" />
                    </div>
                </div>
            </div>
           
        </div>

        <div class="error" *ngIf="error">
            <i style="color: red;">{{error}}</i>
        </div>
    </div>
</div>

<div mat-dialog-actions style="justify-content: space-between;">
    <button class="btn" [disabled]="isLoading" style="background-color: white; color: cornflowerblue; border: 1px solid cornflowerblue;" (click)="submitAddendum()">
        <ng-container *ngIf="!isLoading">{{data.isEdit?"Edit Eddendum":"Create Addendum"}}</ng-container>
        <ng-container *ngIf="isLoading">Processing...</ng-container>
    </button>
    <button class="btn" style="background-color: white; color: red; border: 1px solid red;" (click)="closeDialog()">
        <ng-container>Cancel</ng-container>
    </button>
</div>
