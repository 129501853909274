import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnotifyService } from 'ng-snotify';

import { ApiService } from '../../../services/api.service';

import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
    selector: 'app-add-icd-code-record-modal',
    templateUrl: './add-icd-code-record-modal.component.html',
    styleUrls: ['./add-icd-code-record-modal.component.scss']

})
export class AddICDCodeRecordModalComponent implements OnInit {
    public model = {
        codeType: "IcdCode",
        code: "",
        action: "Add",
        visitId: 0
    }
    public isLoading: boolean = false
    public msgAlert: string = ""
    public arrIcd = []

    constructor(
        private matDialog: MatDialog,
        public dialogRef: MatDialogRef<AddICDCodeRecordModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private apiService: ApiService,
        private snotifyService: SnotifyService,
    ) {
  
    }

    ngOnInit(): void {
        this.model.visitId = this.data.visitId
    }

    searchIcd(event) {
        if (event.target.value.length > 0) {
            this.apiService.getIcds(event.target.value).subscribe((r) => {
                if (r) {
                    this.arrIcd = r[3]
                }
            })
        }
    }

    save() {
        if (!this.model.code) {
            this.msgAlert = "Code name field can not be empty."
            return
        }
        this.isLoading = true
        this.apiService.addHistoryCode(this.model).subscribe((r) => {
            this.isLoading = false;
            if (r.success) {
                this.dialogRef.close('saved')
                this.snotifyService.success(r.responseText)
            } else {
                this.snotifyService.error(r.responseText)
            }
        }, err => {
            this.snotifyService.error("Server error, record is not saved.")
        })
    }

}
