import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogLoadingComponent } from 'src/components/dialog-loading/dialog-loading.component';
import { ApiService } from 'src/services/api.service';

import * as moment from 'moment';
import * as _ from 'lodash';
import { SharedService } from 'src/services/shared.service';

@Component({
  selector: 'app-visit-rule-set-modal',
  templateUrl: './visit-rule-set-modal.component.html',
  styleUrls: ['./visit-rule-set-modal.component.scss']
})
export class VisitRuleSetModalComponent implements OnInit {
  public rules: any = []
  public visitId: any;

  public dialogLoading: any;

  constructor(
    private matDialog: MatDialog,
    public dialogRef: MatDialogRef<VisitRuleSetModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private _snackBar: MatSnackBar,
    private sharedService: SharedService
  ) { }

  ngOnInit() {
    this.rules = this.data.visitRules
    this.visitId = this.data.visitId
  }

  removeRuleChip(ruleName, index) {
    if (confirm('You are about to delete a rule, are you sure?')) {
      this.openLoading()
      this.apiService.removeVisitRuleSet(ruleName, this.visitId).subscribe((r) => {
        this.rules = _.filter(this.rules, (v, i) => {
          return i != index
        })
        this.sharedService.changeVisitRuleSet(this.rules)
        this.closeLoading()
        this.showSnackBar('Removed Successfully!')
      }, err => {
        this.closeLoading()
        this.showSnackBar('Removed Unsuccessfully!')
      })
    } 
  }

  showSnackBar(message) {
    this._snackBar.open(message)
    setTimeout(() => {
      this._snackBar.dismiss()
    }, 1000);
  }

  openLoading() {
    this.dialogLoading = this.matDialog.open(DialogLoadingComponent, {
      minWidth: '150px',
      disableClose: true
    });
  }

  closeLoading() {
    this.dialogLoading.close()
  }

}
