<h1 mat-dialog-title>
  Reset Visit
</h1>
<div>
    MRM VisitId: {{currentVisit.visitId}}
    <br />
    AMD VisitId: {{currentVisit.amdVisitId}}
    <br />
    Current Officekey: {{currentVisit.officeKey}}
</div>
<div mat-dialog-content>
  <div class="modal-body mx-3 row" *ngIf="isLoading">
    <div class="col-lg-12">
      <span>Resetting.. please wait..</span>
    </div>
  </div>
  <div class="modal-body mx-3 row" *ngIf="!isLoading">
    Change the office key to <select [(ngModel)]="newOfficekey">
    <option></option>
    <option *ngFor="let item of assignedOfficeKeys" [value]="item.value">{{item.text}}</option>
</select> and re-send
  </div>
</div>
<div mat-dialog-actions style="justify-content: end;">
    <mat-label style="color:red">WARNING: You must manually remove/void this visit from AMD under office key {{currentVisit.officeKey}}</mat-label>
  <button class="btn btn-primary"  (click)="resetVisit()" [disabled]="isLoading || !!!newOfficekey">
    <ng-container >RESET</ng-container>
  </button>
</div>