import { Component, Inject, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ApiService } from '../../../services/api.service';
import { forkJoin } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnotifyService } from 'ng-snotify';
import { error } from 'console';

@Component({
    selector: 'app-add-edit-addendum-dialog',
    templateUrl: './add-edit-addendum-dialog.component.html',
    styleUrls: ['./add-edit-addendum-dialog.component.scss']

})
export class AddEditAddendumDialogComponent implements OnInit {

    public inCallback = false;
    public currentAddendumItem: any;
    public isError = false;

    constructor(private apiService: ApiService,
                private dialogRef: MatDialogRef<AddEditAddendumDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any, private snotifyService: SnotifyService) {
     
    }

    ngOnInit() {
        this.currentAddendumItem = this.data.addendumItem;
        if (this.currentAddendumItem.additionalInputs > 0 && this.currentAddendumItem.inputLabels) {
            var linputLabelJson = JSON.parse(this.currentAddendumItem.inputLabels);
            this.currentAddendumItem.inputLabels = Object.entries(linputLabelJson).map(([key, value]) => ({ index: key, keyLabel: value }));
        }
    }

    addLabel() {
        if (!this.currentAddendumItem.inputLabels) {
            this.currentAddendumItem.inputLabels = [];
        }
        this.currentAddendumItem.inputLabels.push({ index: this.currentAddendumItem.inputLabels.length+1, keyLabel: '' });
    }

    removeLabel(index: number) {
        this.currentAddendumItem.inputLabels.splice(index, 1);
    }

    saveAddendumItem() {
        this.isError = false;


        if (!this.currentAddendumItem.label) {
            this.isError = true;
            return;
        }

        if (!this.currentAddendumItem.documentText) {
            this.isError = true;
            return;
        }
        this.inCallback = true;
        const cloneItem = _.cloneDeep(this.currentAddendumItem);
        cloneItem.additionalInputs = cloneItem.inputLabels ? cloneItem.inputLabels.length : 0;
        const arrayToJsonObject = (inputArray) => {
            return inputArray.reduce((acc, curr) => {
                acc[curr.index] = curr.keyLabel;
                return acc;
            }, {});
        };

        if (cloneItem.inputLabels && cloneItem.inputLabels.length>0) {
            const originalJsonObject = JSON.stringify(arrayToJsonObject(cloneItem.inputLabels));
            cloneItem.inputLabels = originalJsonObject;

        }
        
         this.apiService.saveAddendumItem(cloneItem).subscribe(r => {
           this.snotifyService.success("Successfully!");
             this.dialogRef.close(r);
             this.inCallback = false;

         }, error => {
             this.snotifyService.error("Server error, the record is not saved.")
             this.inCallback = false;
         })
    }

    closeDialog() {
        this.dialogRef.close();
    }

  

}
