import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/services/api.service';

@Component({
  selector: 'app-new-update-insurance-information',
  templateUrl: './new-update-insurance-information.component.html',
  styleUrls: ['./new-update-insurance-information.component.scss']
})
export class NewUpdateInsuranceInformationComponent implements OnInit {
  public isEdit: boolean = false;
  public isSaving: boolean = false;
  public insuranceForm: FormGroup;
  public isSubmitted: boolean = false;
  public insuranceId: number = null;

  constructor(
    public dialogRef: MatDialogRef<NewUpdateInsuranceInformationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.initForm();
    let insurance = this.data.insurance;
    this.isEdit = this.data.isEdit
    if (this.isEdit && insurance) {
      this.insuranceId = insurance.insuranceId
      this.insuranceForm.patchValue({
        primaryName: insurance.primaryName,
        primaryPhone: insurance.primaryPhone,
        primaryAddress1: insurance.primaryAddress1,
        primaryAddress2: insurance.primaryAddress2,
        primaryCity: insurance.primaryCity,
        primaryState: insurance.primaryState,
        primaryZip: insurance.primaryZip,
        amdCode: insurance.amdCode,
      })
    }
  }

  initForm() {
    this.insuranceForm = this.fb.group({
      primaryName: ['', [Validators.required, Validators.maxLength(150)]],
      primaryPhone: [''],
      primaryAddress1: ['', [Validators.required]],
      primaryAddress2: [''],
      primaryCity: ['', [Validators.required, Validators.maxLength(50)]],
      primaryState: ['', [Validators.required, Validators.maxLength(50)]],
      primaryZip: ['', [Validators.required, Validators.maxLength(50)]],
      amdCode: [''],
    })
  }

  get insuranceF() { return this.insuranceForm.controls }

  save() {
    this.isSaving = true
    this.isSubmitted = true
    let payload = {
      primaryName: this.insuranceForm.value.primaryName,
      primaryPhone: this.insuranceForm.value.primaryPhone,
      primaryAddress1: this.insuranceForm.value.primaryAddress1,
      primaryAddress2: this.insuranceForm.value.primaryAddress2,
      primaryCity: this.insuranceForm.value.primaryCity,
      primaryState: this.insuranceForm.value.primaryState,
      primaryZip: this.insuranceForm.value.primaryZip,
      amdCode: this.insuranceForm.value.amdCode,
      insuranceId: this.isEdit ? this.insuranceId : null
    }
    this.apiService.createAndUpdateInsurance(payload).subscribe((r) => {
      this.isSaving = false
      this.isSubmitted = false
      this.showSnackBar('Saved Succesffuly!')
      this.dialogRef.close('saved')
    }, err => {
      this.isSaving = false
      this.isSubmitted = false
      this.showSnackBar('Saved Unsuccesffuly!')
    })
  }

  showSnackBar(message) {
    this._snackBar.open(message)
    setTimeout(() => {
      this._snackBar.dismiss()
    }, 1000);
  }
}
