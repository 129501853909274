import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ApiService } from '../../services/api.service';
import { forkJoin } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AddEditAddendumDialogComponent } from './../../components/modal/add-edit-addendum-dialog/add-edit-addendum-dialog.component';

@Component({
    selector: 'app-addendum-setup',
    templateUrl: './addendum-setup.component.html',
    styleUrls: ['./addendum-setup.component.scss']

})
export class AddendumSetupComponent implements OnInit {

    public isLoading = true;
    public addendumSettings = [] as any;
    public addendumItems = [] as any;
    constructor(private apiService: ApiService, private dialog: MatDialog) {
     
    }

    ngOnInit() {
        this.getAddendums();
    }

    getAddendums() {
        this.isLoading = true;
        forkJoin([this.apiService.getAddendumSettings(), this.apiService.getAddendumItems()]).subscribe((res: any) => {
            this.addendumSettings = res[0];
            this.addendumItems = res[1];
            console.log(this.addendumItems)
            this.isLoading = false;
        }, err => {
            this.isLoading = false;
        }) 
    }

    openAddEditAddendum(addendumItem) {
        const cloneItem = _.cloneDeep(addendumItem);
        const dialogRef  = this.dialog.open(AddEditAddendumDialogComponent, {
                minWidth: '600px',
                data: {
                    addendumItem: cloneItem
                },
        })

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.id) {
                //console.log(result.id)
                //var index = _.findIndex(this.addendumItems, function (o) { return o.id == result.id; });

                //console.log(index)
                //if (index != -1) {
                //    this.addendumItems[index] = result;
                //} else {
                //    this.addendumItems.push(result);
                //}
                this.getAddendums();
            }
        });
    }
    removeAddendumItem(row) {
       
        this.apiService.deleteAddendumItem(row.id).subscribe(r => {
        var index = _.findIndex(this.addendumItems, function (o) { return o.id == row.id; });
        this.addendumItems.splice(index, 1);
        })
    }


}
