<h1 mat-dialog-title>
    Update Guarantor Information
</h1>
<div mat-dialog-content>
    <div class="row" *ngIf="!isLoading">
        <div class="col-md-6">
            <div class="patient-info">
                <h5>Patient Information</h5>
                <p>Name: <strong>{{ currentPatient.firstName }} {{ currentPatient.lastName }}</strong> </p>
                <p>SSN:<strong>{{ currentPatient.ssn }}</strong> </p>
                <p>Address: <strong>{{ currentPatient.address1 }}, {{ currentPatient.city }}, {{ currentPatient.state }} {{ currentPatient.zip }}</strong> </p>
                <p>Phone:<strong>{{ currentPatient.cellPhone  }}</strong> </p>
                <p>Email:<strong>{{ currentPatient.email }}</strong> </p>
            </div>

            <div class="payer-list">
                <h5>Payer Information</h5>
                <ul>
                    <li *ngFor="let payer of payers">
                        <div class="payer-name">
                            <strong>{{ payer.insName }}</strong>
                        </div>
                        <div class="payer-address">
                            {{ payer.insAddress1 }}, {{ payer.insCity }}, {{ payer.insState }}, {{ payer.insZip }}
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div class="col-md-6">
            <h5>Guarantor Information</h5>

            <div>
                <mat-form-field appearance="outline" class="w-100 form-group">
                    <mat-label>Relationship</mat-label>
                    <mat-select id="relationShipCode" [(ngModel)]="currentGuarantor.relationshipCode">
                        <mat-option *ngFor="let relationship of relationShips" [value]="relationship.hipaarelationship">
                            {{ relationship.description }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div>
                <mat-form-field appearance="outline" class="w-100 form-group">
                    <mat-label>First Name</mat-label>
                    <input matInput id="firstName" required [(ngModel)]="currentGuarantor.firstName" />

                    <mat-error *ngIf="!currentGuarantor.firstName">
                        FirstName is required.
                    </mat-error>
                </mat-form-field>
            </div>

            <div>
                <mat-form-field appearance="outline" class="w-100 form-group">
                    <mat-label>Last Name</mat-label>
                    <input matInput id="lastName" required [(ngModel)]="currentGuarantor.lastName" />
                    <mat-error *ngIf="!currentGuarantor.lastName">
                        LastName is required.
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

    </div>

    <div *ngIf="isLoading">
        <span class="text-gray">Loading...</span>
    </div>
</div>

<div mat-dialog-actions style="justify-content: end;">
    <button class="btn btn-secondary" style="margin-right: 5px" mat-dialog-close>Close</button>
    <button class="btn btn-primary" (click)="saveUpdateAndReQueue()" [disabled]="isLoading">
        <ng-container *ngIf="!isSaving">Update & Re-queue</ng-container>
        <ng-container *ngIf="isSaving">Updating...</ng-container>
    </button>
</div>
