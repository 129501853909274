import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { ImportLogsComponent } from '../import-logs/import-logs.component';

@Component({
    selector: 'app-debug-tool',
    templateUrl: './debug-tool.component.html',
    styleUrls: ['./debug-tool.component.scss']

})
export class DebugToolComponent implements OnInit {


    model: any = {}
    result: any;
    constructor(private apiService: ApiService, private matDialog: MatDialog) {
  
    }

    ngOnInit(): void {
      

      }

    getHistory() {
        this.result = '';
        this.apiService.getFinancialHistory(this.model.officeKey, this.model.patId).subscribe(r => {
            console.log(r)
            this.result = JSON.stringify(r);

        })
    }

}
