import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/services/api.service';
import { zip } from 'rxjs'

import * as _ from 'lodash';
@Component({
    selector: 'app-reset-visit-modal',
    templateUrl: './reset-visit-modal.component.html',
    styleUrls: ['./reset-visit-modal.component.scss']
})
export class ResetVisitModalComponent implements OnInit {
  public isLoading: boolean = false
  public isSaving: boolean = false
  public isSubmitted: boolean = false
  public dialogLoading: any;
    newOfficekey = "";
    public currentVisit: any;
    public assignedOfficeKeys = [];


  constructor(
    private apiService: ApiService,
      public dialogRef: MatDialogRef<ResetVisitModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
      this.currentVisit = this.data.currentVisit
      this.assignedOfficeKeys = this.data.assignedOfficeKeys;
      //console.log("assignedOfficeKeys", this.assignedOfficeKeys)
      console.log(this.currentVisit)
  }




    resetVisit() {
        this.isLoading = true;
        this.apiService.resetVisit({ visitId: this.currentVisit.visitId, officeKey: this.newOfficekey }).subscribe(r => {
            this.isLoading = false;
            this.showSnackBar('Sucessfully, Moved this visit to QUEUED')
            this.dialogRef.close(r)
        }, err => {
            console.log(err)
            this.showSnackBar(err.error)
            this.isLoading = false;
        })
  }

  public showSnackBar(message) {
    this._snackBar.open(message)
    setTimeout(() => {
        this._snackBar.dismiss()
    }, 4000);
  } 
}
