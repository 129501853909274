<h1></h1>

<div  class="row">
    <div class="col-12" style="padding: 0px 20px">
        <h1>Missing Charts Report ({{total}})</h1>
    </div>
</div>

<p *ngIf="isLoading" style="font-size:large;font-weight:bold">Loading...</p>

<ng-container *ngIf="!isLoading">
    <ngx-datatable class="material" [rows]="missingChartsInfo" [headerHeight]="50" [footerHeight]="50"
                   rowHeight="auto" [scrollbarH]="true" [loadingIndicator]="isLoading">
        <ngx-datatable-column name="Visit Id" [width]="100">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.visitId }}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Service Date" [width]="200">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.visitServiceDate | date: 'shortDate'}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Patient" [width]="300">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.patientName }}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Chart File Name" [width]="350">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.chartFileName }}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Link" [width]="100">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <div>
                    <button class="btn btn-secondary"
                            aria-label="Link" matTooltip="Link to Record"
                            (click)="gotoLinkedRecord(row.visitId)">
                        Coding
                    </button>
                </div>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>

    <ng-container *ngIf="missingChartsInfo.length <= 0">
        <div class="row">
            <div class="col-12" style="text-align: center">
                <span style="font-size: 2rem; font-weight: bold;">No Data</span>
            </div>
        </div>
    </ng-container>

</ng-container>

<div class="toast" *ngIf="isShowToast" [ngClass]="classToast">
    <p>{{stringToast}}</p>
</div>

