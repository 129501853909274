<h1 mat-dialog-title>Add CPT Code Record</h1>
<div mat-dialog-content>
    <div class="modal-body mx-3">
        <div id="addcptMsg" class="modalmsg"></div>
        <div class="input-group mb-3">
            <div class="input-group-prepend">
                <label class="input-group-text" for="inputGroupSelect01">Code Type</label>
            </div>
            <select class="form-control" id="codeType" [(ngModel)]="model.codeType">
                <option value="CPTCode">CPT</option>
                <option value="EM_CPTCode">EM</option>
            </select>
        </div>

        <div class="md-form mb-5">
            <div class="form-group">
                <label data-error="wrong" data-success="right" for="cptCodeName">Code name</label>
                <input type="text" id="cptCodeName" class="form-control validate" name="cptCodeName" [(ngModel)]="model.code" required>
            </div>
        </div>

        <div class="md-form mb-5">
            <div class="form-group">
                <label data-error="wrong" data-success="right" for="codeQuantity">Quantity</label>
                <input type="number" [(ngModel)]="model.quantity" class="form-control validate" min="1" value="1">
            </div>
        </div>

        <div class="md-form mb-3">
            <div class="form-group">
                <label data-error="wrong" data-success="right" for="modifierCode">Modifier Code</label>
                <select class="form-control" [(ngModel)]="model.modifier">
                    <option *ngFor="let item of modifiers" [value]="item.id">{{item.id}}</option>
                </select>
            </div>
        </div>

        <div class="md-form mb-3">
            <div class="form-group">
                <label data-error="wrong" data-success="right" for="modifierCode2">Modifier Code</label>
                <select class="form-control" [(ngModel)]="model.modifier2">
                    <option *ngFor="let item of modifiers" [value]="item.id">{{item.id}}</option>
                </select>
            </div>
        </div>

        <div class="md-form mb-3">
            <div class="form-group">
                <label data-error="wrong" data-success="right" for="modifierCode3">Modifier Code</label>
                <select class="form-control" [(ngModel)]="model.modifier3">
                    <option *ngFor="let item of modifiers" [value]="item.id">{{item.id}}</option>
                </select>
            </div>
        </div>

    </div>

    <div *ngIf="msgAlert !== ''" class="alert alert-danger" role="alert">{{msgAlert}}</div>
</div>
<div mat-dialog-actions style="justify-content: end;">
    <button class="btn btn-secondary" style="margin-right: 5px" mat-dialog-close>Close</button>
    <button class="btn btn-primary" (click)="save()" [disabled]="isLoading">
        <ng-container *ngIf="!isLoading">Add</ng-container>
        <ng-container *ngIf="isLoading">Adding...</ng-container>
    </button>
</div>