import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ApiService } from '../../../services/api.service';
import { zip } from 'rxjs';

import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
    selector: 'app-add-physician-modal',
    templateUrl: './add-physician-modal.component.html',
    styleUrls: ['./add-physician-modal.component.scss']

})
export class AddPhysicianModalComponent implements OnInit {
    public physicianForm: FormGroup;
    public mappedProviders: any = []
    public provider: any;

    public oldPvId: number;
    public officeKey: string = ''
    public codeAPI: string = '' 

    public isLoading: boolean = false
    public isSaving: boolean = false
    public isEdit: boolean = false;
    public isCheckPvId: boolean = false;
    public isWarningPCode: boolean = false;
    
    constructor(
        private matDialog: MatDialog,
        public dialogRef: MatDialogRef<AddPhysicianModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private apiService: ApiService,
        private fb: FormBuilder,
        private _snackBar: MatSnackBar
    ) {
  
    }

    ngOnInit(): void {
       this.officeKey = this.data.officeKey
       this.provider = this.data.provider
       this.isEdit = this.data.isEdit

       this.physicianForm = this.fb.group({
        pvPhysicianId: [0, [Validators.required]],
        pvFirstName: ['', [Validators.required]],
        pvLastName: ['', [Validators.required]],
        amdProviderCode: ['', [Validators.required]],
        amProviderId: ['', [Validators.required]],
        isDefault: [false]
       })
       if(this.officeKey && !this.provider){
        this.getProviderList()
       }else{
         this.physicianForm.patchValue({
            amdProviderCode: this.provider.code,
            amProviderId: this.provider.id,
            pvFirstName: this.provider.firstName,
            pvLastName: this.provider.lastName
         })
         this.checkPhysicianIdByName()
       }
       if(this.isEdit){
        let physician = this.data.physician
        this.loadDataPhysicianForm(physician)
       }else{
        // this.getLastPvId()
       }
       this.checkPhysicianId = _.debounce(this.checkPhysicianId, 500)
       this.checkPvPhysicianByPvName = _.debounce(this.checkPvPhysicianByPvName, 500)
    }

    get pvPhysicianId(){
        return this.physicianForm.get('pvPhysicianId')
    }

    get pvFirstName(){
        return this.physicianForm.get('pvFirstName')
    }

    get pvLastName(){
        return this.physicianForm.get('pvLastName')
    }

    get amdProviderCode(){
        return this.physicianForm.get('amdProviderCode')
    }

    get amProviderId(){
        return this.physicianForm.get('amProviderId')
    }

    getProviderList(){
        this.isLoading = true
        this.apiService.getPhysicianProviderList(this.officeKey).subscribe((mappedProviders) => {
            this.mappedProviders = mappedProviders
            this.mappedProviders = this.customizeProviders(this.mappedProviders)
            if(!this.isEdit){
                this.physicianForm.patchValue({
                    amdProviderCode: this.mappedProviders[0].code,
                    amProviderId: this.mappedProviders[0].value,
                    pvFirstName: this.mappedProviders[0].firstName,
                    pvLastName: this.mappedProviders[0].lastName
                 })
                 this.checkPhysicianIdByName()
            }else{
                this.checkProviderCode(true)
            }
            this.isLoading = false
        })
    }

    customizeProviders(mappedProviders){
        _.each(mappedProviders, (p) => {
            var subStart = p.text.indexOf('(')
            var subEnd = p.text.indexOf(')')
            p.code  = p.text.substring(subStart + 1, subEnd)
            let name = p.text.replace(`(${p.code})`, "")
            let nameArr = name.trim().split(',')
            p.firstName = nameArr[1].trim() || ""
            p.lastName = nameArr[0].trim() || ""
        })
        return mappedProviders
    }

    checkProviderCode(isWarning = false){
        let amProviderId = this.physicianForm.get('amProviderId').value
        let findProviderApi = _.find(this.mappedProviders, (p) => {return p.value == amProviderId})
        this.codeAPI = findProviderApi?.code
        if(findProviderApi && findProviderApi.code !==  this.physicianForm.get('amdProviderCode').value){
           if(isWarning){
            this.isWarningPCode = true
           }else{
            this.physicianForm.controls['amdProviderCode'].setErrors({'incorrect' : true})
           }
        }else{
            this.isWarningPCode = false
        }
    }

    getLastPvId(){
        if(this.provider){
            this.isLoading = true
        }
        this.apiService.getLastPhysicianId(this.officeKey).subscribe((lastPId) => {
            this.physicianForm.patchValue({
                pvPhysicianId: lastPId ? lastPId + 1 : 1
             })
            // this.oldPvId = lastPId ? lastPId + 1 : 1;
            if(this.provider){
                this.isLoading = false
            }
        })
    }

    loadDataPhysicianForm(physician){
       this.physicianForm.patchValue({
          pvPhysicianId: physician.pvPhysicianId,
          pvFirstName: physician.pvFirstName,
          pvLastName: physician.pvLastName,
          amdProviderCode: physician.amdProviderCode,
          amProviderId: physician.amdProfileId,
          isDefault: physician.isDefault,
       })
       this.oldPvId = physician.pvPhysicianId
    }

    // getProviderCode(text){
    //    var subStart = text.indexOf('(')
    //    var subEnd = text.indexOf(')')
    //    return text.substring(subStart + 1, subEnd)
    // }

    changeProvider(amProviderId){
        var provider = _.find(this.mappedProviders, (e) => { return e.value === amProviderId})
        this.physicianForm.patchValue({
            amdProviderCode: provider.code,
            pvFirstName: provider.firstName,
            pvLastName: provider.lastName,
        })
        if(!this.isEdit){ this.checkPhysicianIdByName()}
    }

    checkPvPhysicianByPvName(){
        if(!this.isEdit){ this.checkPhysicianIdByName()}
    }

    checkPhysicianId(){
      let pvId = this.physicianForm.get('pvPhysicianId').value
      if(pvId == this.oldPvId){
        this.isCheckPvId = false
        return
      }
      this.oldPvId = _.cloneDeep(pvId)
      this.isCheckPvId = true
      this.physicianForm.controls['pvPhysicianId'].setErrors(null)
      this.apiService.checkPvPhysicianIdExist(this.officeKey, pvId).subscribe((r) => {
        if(!r){
            this.physicianForm.controls['pvPhysicianId'].setErrors({ 'isExist': true, 'required' : false })
        }
        this.isCheckPvId = false
      })
    }

    checkPhysicianIdByName(){
       this.isCheckPvId = true
       let request = {
         officeKey: this.officeKey,
         firstName: this.physicianForm.controls['pvFirstName'].value,
         lastName: this.physicianForm.controls['pvLastName'].value,
       }
       this.physicianForm.controls['pvPhysicianId'].setErrors(null)
       this.apiService.checkPhysicianIdByName(request).subscribe((r) => {
           this.physicianForm.patchValue({
               pvPhysicianId: r != 0 ? r : ""
           })
           this.oldPvId = r != 0 ? r : ""
           if (r == 0) {
             this.physicianForm.controls['pvPhysicianId'].setErrors({ 'isExist': false, 'required' : true })
           } else {
             this.physicianForm.controls['pvPhysicianId'].setErrors({ 'isExist': true, 'required' : false })
           }
        this.isCheckPvId = false
       })
    }

    save() {
       this.isSaving = true
       this.apiService.addNewAndUpdatePhysician(this.officeKey, this.physicianForm.value).subscribe((r) => {
           if(r){
            this.isSaving = false
            this.showSnackBar('Saved Succesffuly!')
            this.dialogRef.close('saved')
           }else{
            this.isSaving = false
            this.showSnackBar('Saved Unsuccesffuly!')
           }
       })
    }
    
    showSnackBar(message) {
        this._snackBar.open(message)
        setTimeout(() => {
            this._snackBar.dismiss()
        }, 1000);
    }

}
