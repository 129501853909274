<h1 mat-dialog-title>Message Physician</h1>
<div mat-dialog-content style="max-height:70vh">
    <div class="modal-body mx-3">
        <div class="alert" [ngClass]="classMsg" role="alert" >
             {{msg}}
        </div>
        <form enctype="multipart/form-data">
            <div class="md-form mb-5">
                <div class="form-group">
                    <label data-error="wrong" data-success="right" for="fromemail">Your Email</label>
                    <input type="email"
                           id="fromemail"
                           class="form-control validate"
                           [(ngModel)]="userEmail"
                           required
                           pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                           readonly [ngModelOptions]="{standalone: true}">
                </div>
            </div>
            <div class="md-form mb-5">
                <div class="form-group">
                    <label data-error="wrong" data-success="right" for="form29">Physicians Emails</label>
                    <mat-spinner *ngIf="physiciansEmails.length ==0"></mat-spinner>
                    <mat-form-field appearance="fill" style="width: 100%" *ngIf="physiciansEmails.length >0">
                        <mat-label>Physicians</mat-label>
                        <mat-select [(ngModel)]="selectedPhysicianEmails"
                                    (ngModelChange)="selectedPhysicianEmails"
                                    name="selectedPhysicianEmails"
                                    [ngModelOptions]="{standalone: true}">
                            <mat-option *ngFor="let pe of physiciansEmails" [value]="pe.email" (click)="selectPhysicianEmail(pe)">
                                {{pe.amdDisplayName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <label>Selected emails: {{selectedPhysicianEmails}}</label>
                </div>
            </div>
            <div class="md-form mb-5">
                <div class="form-group">
                    <label data-error="wrong" data-success="right" for="form29">Additional Email</label>
                    <input type="email" id="toemail" class="form-control" [(ngModel)]="additionalEmail"
                           placeholder="username@cmucs.com"
                           pattern="^([a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@([Cc][Mm][Uu][Cc][Ss]\.[Cc][Oo][Mm])+)*$"
                           [ngModelOptions]="{standalone: true}">
                    <label id="errorLabel" *ngIf="!validEmail && additionalEmail">* email must be of domain @cmucs.com</label>

                </div>
            </div>

            <div class="md-form mb-5">
                <div class="form-group">
                    <label data-error="wrong" data-success="right" for="subject">Subject</label>
                    <input type="text" id="subject" class="form-control" required
                           [(ngModel)]="emailModel.subject" [ngModelOptions]="{standalone: true}">
                    <label id="errorLabel" *ngIf="!emailModel.subject">* must enter a subject</label>
                </div>
            </div>

            <div class="md-form">
                <div class="form-group">
                    <label data-error="wrong" data-success="right" for="message">Your Message</label>
                    <textarea type="text" id="message" class="md-textarea form-control" rows="4" required
                              [(ngModel)]="emailModel.message" [ngModelOptions]="{standalone: true}"></textarea>
                    <label id="errorLabel" *ngIf="!emailModel.message">* must type a message</label>
                </div>
            </div>
            <div class="md-form mb-8">
                <div class="form-group">
                    <label for="name">Chart Upload: </label>
                    <input type="file" class="form-control" id="emailChart" name="emailChart" (change)="handleFileInput($event.target.files)">
                    <input type="checkbox" class="form-check-input" [(ngModel)]="attchChart" [ngModelOptions]="{standalone: true}" /> Attach current patient chart
                </div>
            </div>
        </form>
    </div>
</div>
<div mat-dialog-actions style="justify-content: end;">
    <button *ngIf="!isLoading" class="btn btn-secondary" style="margin-right: 5px" mat-dialog-close>Close</button>
    <button *ngIf="!isLoading" class="btn btn-primary" (click)="save()" [disabled]="!checkValid()">
        <ng-container >Send</ng-container>
    </button>
    <label *ngIf="isLoading">Sending..</label>
</div>