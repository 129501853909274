import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { DialogLoadingComponent } from 'src/components/dialog-loading/dialog-loading.component';

import { ApiService } from 'src/services/api.service';

import * as _ from 'lodash';
import { UpdateDiagConfirmComponent } from './update-diag-confirm/update-diag-confirm.component';

@Component({
  selector: 'app-update-diag-code-modal',
  templateUrl: './update-diag-code-modal.component.html',
  styleUrls: ['./update-diag-code-modal.component.scss']
})
export class UpdateDiagCodeModalComponent implements OnInit {
  public isLoading: boolean = false
  public isSaving: boolean = false
  public isSubmitted: boolean = false

  public visitId: number;
  public officeKey: string = '';
  public visitDiagCodes: any = []
  public visitProcCodeForm: FormGroup;
  public selectedProcCode: any;
  public dialogLoading: any;
  public isFinish: boolean = false;

  constructor(
    private apiService: ApiService,
      public dialogRef: MatDialogRef<UpdateDiagCodeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private matDialog: MatDialog,
  ) { }

  ngOnInit() {
     this.visitId = this.data.visitId
     this.officeKey = this.data.officeKey
     this.initForm()
     this.loadData()
  }
  loadData(){
    this.isLoading = true
    this.apiService.getVisitDiagCodesByVisitId(this.visitId, this.officeKey).subscribe((r) => {
        this.visitDiagCodes = r
        this.isLoading = false
        this.isFinish = _.filter(this.visitDiagCodes, (e) => {return e.isMissingAmd}).length > 0 ? false : true
    })
  }

  initForm() {
    this.visitProcCodeForm = this.fb.group({
      procCode: ['', [Validators.required]],
    })
  }

  get visitF() { return this.visitProcCodeForm.controls }

  editVisitProcCode(item){
    this.selectedProcCode = item;
    this.visitDiagCodes.forEach(e => {
      e.isActive = e.visitProcCodeId == item.visitProcCodeId ? true : false
    });
    if (item) {
      this.visitProcCodeForm.patchValue({
        procCode: item.procCode,
      })
    }
  }

  deleteDiagCode(item){
    if (confirm('You are about to delete a visit diagCode, are you sure?')) {
      this.openLoading()
      this.apiService.deleteVisitProcCodeById(item.visitProcCodeId).subscribe((r ) => {
        this.closeLoading()  
        if(r){
          this.visitDiagCodes = _.filter(this.visitDiagCodes, (e) => {return e.visitProcCodeId != item.visitProcCodeId})
          this.selectedProcCode = null
          this.showSnackBar("Delete Visit ProcCode Successfully!")
        }else{
          this.showSnackBar("Not Found Visit ProcCode!")
        }          
      })
    }
  }

  save(){
    this.isSaving = true
    this.isSubmitted = true
    let payload = {
       visitId: this.visitId,
       procCode: this.visitProcCodeForm.value.procCode,
       visitProcCodeId: this.selectedProcCode.visitProcCodeId
    }
    this.apiService.updateVisitDiagCode(payload).subscribe((r) => {
      this.isSaving = false
      this.isSubmitted = false
      this.showSnackBar('Updated Succesffuly!')
      localStorage.setItem('highlight-visitId', this.visitId.toString())
      this.dialogRef.close('saved')
    }, err => {
      this.isSaving = false
      this.isSubmitted = false
      this.showSnackBar('Updated Unsuccesffuly!')
    })
  }

  openLoading() {
    this.dialogLoading = this.matDialog.open(DialogLoadingComponent, {
         minWidth: '150px',
         disableClose: true
     });
 }

 closeLoading() {
     this.dialogLoading.close()
    }

    edit(item) {

        this.dialogLoading = this.matDialog.open(UpdateDiagConfirmComponent, {
            minWidth: '200px',
            data: {
                visitId: this.visitId,
                officeKey: this.officeKey,
                oldDiagCode: item.icdCode,
                isCheckOnly: false
            }
        });

    }

 checkOnAMD(item){

     this.dialogLoading = this.matDialog.open(UpdateDiagConfirmComponent, {
         minWidth: '200px',
         data: {
             visitId: this.visitId,
             officeKey: this.officeKey,
             oldDiagCode: item.icdCode,
             isCheckOnly: true
         }
     });
    
 }

 showSnackBar(message) {
  this._snackBar.open(message)
  setTimeout(() => {
      this._snackBar.dismiss()
  }, 1000); 
  
}
}
