import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../../services/api.service';
import * as _ from 'lodash';
import { forkJoin, Observable } from 'rxjs';
import { error } from 'console';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';

@Component({
    selector: 'app-edit-visit-modal',
    templateUrl: './edit-visit-modal.component.html',
    styleUrls: ['./edit-visit-modal.component.scss']
})
export class EditVisitModalComponent implements OnInit {

    public isLoading = true;
    public currentVisit;
    public allPhysicians = [];
    public allFinClasses = [];
    public currentPhysicianId: any;
    public currentFinClass: any;

    public oldFinClass: any;
    public oldPhysician: any;
    public error = "";

  constructor(private matDialog: MatDialog,
              public dialogRef: MatDialogRef<EditVisitModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private apiService: ApiService,
  ) { }

    ngOnInit() {
        this.getdataUpdate();
    }

    getdataUpdate() {
        this.isLoading = true;
        this.currentVisit = this.data.visit;
        this.currentFinClass = this.oldFinClass = parseInt(this.data.visit.finClass);
        this.currentPhysicianId = this.data.visit.physicianId;

        forkJoin([this.apiService.getListPhysicians(), this.apiService.getFinClasses()]).subscribe(
            ([data1, data2]) => {
                this.oldFinClass = _.find(data1, d => d.pvPhysicianId == this.data.visit.physicianId)
                this.allPhysicians = _.filter(data1, d => d.officeKey == this.currentVisit.officeKey);
                this.allFinClasses = _.filter(data2, d => d.officeKey == this.currentVisit.officeKey);
                this.isLoading = false;
            }
        );
    }
 
    saveVisit() {
        this.isLoading = true;

        var payload = {
            visitId: this.currentVisit.visitId,
            finClass: this.currentFinClass,
            physicianId: this.currentPhysicianId
        }
        this.apiService.UpdateVisitFinClassPhysician(payload).subscribe(r => {
            this.isLoading = false;
            this.dialogRef.close();
            this.showAlertModal('Update Visit', `Update Visit Successfully!`, 'success');
        }, error => {
            this.isLoading = false;
            this.error = error.message ?? error;
        })
    }

    showAlertModal(title, message, status) {
        const dialogRef = this.matDialog.open(AlertModalComponent, {
            minWidth: '600px',
            data: {
                title: title,
                type: status,
                message: message
            },
        });

        dialogRef.afterClosed().subscribe(result => {

        });
    }

}
