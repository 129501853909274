
import { Injectable } from "@angular/core";

import { HttpClient, HttpHandler, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { catchError, map } from 'rxjs/operators';
import { Location } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    createUpdateCodingNote(arg0: { visitId: number; notes: any; }) {
        return this.http.put(`/Visit/CreateUpdateCodingNote`, arg0);
    }
    getCodingNote(visitId) {
        return this.http.get(`/Visit/GetCodingNote?visitId=${visitId}`)
    }

    constructor(private http: HttpClient) {

    }

    getProcessedIn(page, filter): Observable<any>{
        return this.http.post(`/Record/GetVisitsProcessedIn?page=${page}&pageSize=20`, filter);

    }

    getQueueImportLog(): Observable<any> {
        return this.http.get(`/Record/GetQueueImportLog`)
    }

    getReturnedVisits(): Observable<any> {
        return this.http.get(`/Visit/GetReturnedVisits`)
    }

    getDoneReturnedVisits(payload): Observable<any> {
        return this.http.post(`/Visit/GetDoneReturnedVisits`, payload)
    }

    getVisitReturnByVisitId(visitId): Observable<any> {
        return this.http.get(`/Visit/GetVisitReturnByVisit?visitId=${visitId}`)
    }

    dismissVisitReturn(visitId): Observable<any> {
        return this.http.get(`/Visit/DismissVisitReturn?visitId=${visitId}`)
    }

    getVisit_PvUpdate(visitId): Observable<any> {
        return this.http.get(`/Visit/getVisit_PvUpdate?visitId=${visitId}`)
    }


    downloadVisitReports(): Observable<any> {
        return this.http.get(`/Visit/ReportVisitErrors`, { responseType: 'blob', observe: 'response' });
    }

    //updateVisitProcCodesFromXmlChange(payload): Observable<any> {
    //    return this.http.post(`/Visit/updateVisitProcCodesFromXmlChange`, payload)
    //}

    getErrorsImportLog(filter): Observable<any> {
        return this.http.post(`/Record/GetErrorsImportLog`, filter)
    }

    getFlaggedRetry(filter): Observable<any> {
        return this.http.post(`/Record/GetFlaggedRetry`, filter)
    }

    clearErrorAndEnqueue(visitId): Observable<any> {
        return this.http.get(`/Record/ClearErrorAndEnqueue/?visitId=${visitId}`)
    }

    getPaymentsImportLog(page, payload): Observable<any> {
        return this.http.post(`/Record/GetPaymentsImportLog?page=${page}&pageSize=20`, payload)
    }

    getBatchJob(page): Observable<any> {
        return this.http.get(`/Record/GetBatchJobs?page=${page}&pageSize=20`)
    }

    getProcessedIn4Report(filter): Observable<any> {
        return this.http.post(`/Record/GetVisitsProcessedIn4Report`, filter, {
            responseType: "blob",
            headers: new HttpHeaders().append("Content-Type", "application/json")
        });
    }

    getVisitsByStatus(page,status, filter): Observable<any> {
        return this.http.post(`/Record/GetVisitsByStatus?status=${status}&page=${page}&pageSize=20`, filter);
    }

    unqueue(visitIds) {
        return this.http.post(`/Record/Unqueue`, visitIds);
    }

    flagVisits(visitIds) {
        return this.http.post(`/Record/flagVisits`, visitIds);
    }

    rerunImport(visitIds) {
        return this.http.post(`/Record/RerunImport`, visitIds);
    }

    getVisitsByStatus4Report( status, filter): Observable<any> {
        return this.http.post(`/Record/GetVisitsByStatus4Report?status=${status}`, filter, {
            responseType: "blob",
            headers: new HttpHeaders().append("Content-Type", "application/json")
        });
    }

    getClinics(): Observable<any> {
        return this.http.get(`/Record/GetClinics`);
    }

    getUserClinics(pageNumber , pageSize): Observable<any> {
        return this.http.get(`/userAdmin/getUserClinics?page=${pageNumber}&limit=${pageSize}`);
    }

    getAdminClinics(): Observable<any> {
        return this.http.get(`/admin/GetClinicProfiles`);
    }    

    getClinicOffices(): Observable<any> {
        return this.http.get(`/userAdmin/GetClinicOffices`);
    }

    addNewClinics(clinics): Observable<any> {
        return this.http.post('/userAdmin/AddNewClinicProfile', clinics);
    }
    updateClinic(clinic): Observable<any> {
        return this.http.post('/admin/SaveUpdateClinic', clinic);
    }

    getFinClasses(): Observable<any> {
        return this.http.get(`/Record/GetFinClasses`);
    }

    getFinancialHistory(officeKey, patId): Observable<any> {
        return this.http.get(`Record/GetPatientFinancialHistory/?patId=${patId}&officeKey=${officeKey}`);
    }

    fixMissingCharge(visitIds): Observable<any> {
        return this.http.post(`Record/FixMissingCharge/`, visitIds );
    }

    getAllOfficeKeys(): Observable<any> {
        return this.http.get('Record/GetAllOfficeKeys')
    }

    updateVisitWithOfficeKey(payload): Observable<any> {
        return this.http.post(`/bulkEdit/UpdateVisitWithOfficeKey`, payload);
    }

    SaveCodeReviewRule(payload): Observable<any> {
        return this.http.post('/admin/SaveCodeReviewRule', payload);
    }

    getCodeReviewRule(): Observable<any> {
        return this.http.get('/admin/GetCodeReviewRule')
    }

    getUserEmrId(): Observable<any> {
        return this.http.get('/admin/GetUserEmrId')
    }

    getCodeReviewRuleActionSet(ruleId): Observable<any> {
        return this.http.get(`Admin/GetCodeReviewRuleActionSet?ruleId=${ruleId}`)
    }

    getCodeReviewRuleById(ruleId): Observable<any> {
        return this.http.get(`Admin/GetCodeReviewRuleById?ruleId=${ruleId}`)
    }

    updateCodeReviewRuleById(rule): Observable<any> {
        return this.http.post(`Admin/updateCodeReviewRuleById`, rule)
    }

    deleteCodeReviewRule(ruleId): Observable<any> {
        return this.http.delete(`/admin/DeleteRule?ruleId=${ruleId}`)
    }

    deleteCodeReviewRuleIds(ids): Observable<any> {
        return this.http.put(`/admin/DeleteRuleIds`, ids)
    }

    loadCodeReviewRule(ruleId): Observable<any> {
        return this.http.get(`/admin/LoadCodeReviewRule?ruleId=${ruleId}`)
    }
    SetRuleActive(ruleId, isOn): Observable<any> {
        var data = { }
        return this.http.post(`/admin/SetRuleActive?ruleId=${ruleId}&isOn=${isOn}`, data)
    }

    SetRuleActivebyRuleIds(ruleIds, isOn): Observable<any> {
        var data = {
            ruleIds: ruleIds,
            isOn: isOn
        }
        return this.http.post(`/admin/SetRuleActiveByIds`, data)
    }

    getDataBulkUpdate(): Observable<any> {
        return this.http.get(`/bulkEdit/GetDataBulkUpdate`)
    }

    getRecordVisit(payload, pageInfo): Observable<any> {
        return this.http.post(`/bulkEdit/GetVisitFilter?pageSize=${pageInfo.pageSize}`, payload)
    }

    updateVisitFlagged(payload): Observable<any> {
        return this.http.post(`/bulkEdit/UpdateVisitFlagged`, payload)
    }

    getIcds(searchText): Observable<any> {
        return this.http.get(`https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name,name&uthenticity_token=&terms=${searchText}`)
    }

    getAllPhysicians(): Observable<any> {
        return this.http.get(`/record/getAllPhysicians`)
    }

    executeActBulkUpdate(payload): Observable<any> {
        return this.http.post(`/bulkEdit/executeActBulkUpdate`, payload)
    }

    saveBulkUpdate(payload): Observable<any> {
        return this.http.post(`/bulkEdit/SaveBulkUpdate`, payload)
    }

    getActivityLogTimeLine(payload): Observable<any> {
        return this.http.post(`/ActivityLog/GetActivityLogPagination`, payload)
    }

    saveBulkActionSet(payload): Observable<any> {
        return this.http.post('/bulkEdit/SaveBulkActionSet', payload)
    }

    deleteBulkActionSet(payload): Observable<any> {
        return this.http.post('/bulkEdit/DeleteBulkActionSet', payload)
    }

    getBulkActionSet(userId): Observable<any> {
        let payload = {
            userId
        }
        return this.http.post(`bulkEdit/GetBulkActionSet`, payload)
    }

    getAllBulkActionSet(): Observable<any> {
        return this.http.get(`bulkEdit/GetAllBulkActionSet`)
    }

    getBulkActionSetById(actionSetId): Observable<any> {
        return this.http.get(`bulkEdit/GetBulkActionSetById?id=${actionSetId}`)
    }

    getCodeReviewRuleGroupUser(): Observable<any> {
        return this.http.get('Admin/GetCodeReviewRuleGroupUser')
    }

    removeCodeReviewRuleGroupUser(ruleIds): Observable<any> {
        return this.http.put('Admin/RemoveCodeReviewRuleGroupUser', ruleIds)
    }

    setActiveCodeReviewRuleGroupUser(payload): Observable<any> {
        return this.http.put('Admin/SetActiveCodeReviewRuleGroupUser', payload)
    }

    saveCodeReviewRuleGroupUser(payload): Observable<any>{
        return this.http.post('Admin/SaveCodeReviewRuleGroupUser', payload)
    }

    getCodeReviewRuleByUser(): Observable<any> {
        return this.http.get('Admin/GetCodeReviewRuleByUser')
    }

    getCodeReviewRuleActive(): Observable<any> {
        return this.http.get('Admin/GetCodeReviewRuleActive')
    }

    getCodeReviewRuleAll(): Observable<any> {
        return this.http.get('Admin/GetCodeReviewRuleAll')
    }

    getHistoryCodeByType(type, page, limit, visitId, isAll): Observable<any> {
        return this.http.get(`Record/GetHistoryCodeNew?type=${type}&page=${page}&limit=${limit}&visitId=${visitId}&isAll=${isAll}`)
    }

    addHistoryCode(payload): Observable<any> {
        return this.http.post("Record/AddHistoryCodeNew", payload)
    }

    getModifiers():Observable<any> {
        return this.http.get("Record/GetModifiers")
    }

    updateHistoryCode(payload, visitId): Observable<any> {
        return this.http.post(`Record/UpdateHistoryCodeNew?visitId=${visitId}`, payload)
    }

    deleteHistoryCode(payload): Observable<any> {
        return this.http.post(`Record/DeleteHistoryCodeNew`, payload)
    }

    sendNotification(formData): Observable<any> {
        return this.http.post(`Record/SendNotification`, formData)
    }

    getCurrentUserInfo(): Observable<any> {
        return this.http.get('Record/GetCurrentUserInfo')
    }

    loadCodeFilter(payload, position, visitId): Observable<any> {
        return this.http.post(`Record/LoadCodeFilter?position=${position}&visitId=${visitId}`, payload)
    }

    getPhysiciansEmails(officeKey, visitId): Observable<any> {
        return this.http.get(`Physician/GePhysiciansEmails?officeKey=${officeKey}&visitId=${visitId}`)
    }

    loadChartDoc(chartId) {
        return this.http.get('Record/LoadChartDoc?chartId=' + chartId, { responseType: 'blob' })
    }

    uploadChart(files, visitId): Observable<any> {
        const formData: FormData = new FormData();
        for (const file of files) {
            formData.append('files', file, file.name)
        }
        formData.append('visitId', JSON.stringify(visitId))

        return this.http.post(`Record/UploadChart`, formData)
    }

    saveChartCode(payload): Observable<any> {
        return this.http.post(`Record/SaveChartCodeNew`, payload)
    }

    getUserCompanyEMR(): Observable<any> {
        return this.http.get(`Home/GetUserCompanyEMR`)
    }
    getPendingOCRCount(): Observable<any> {
        return this.http.get('Home/GetPendingOCRCount')
    }

    getVisitCountsForPreviousDay(): Observable<any> {
        return this.http.get('Home/GetVisitCountsForPreviousDay')
    }

    getCurrentYearPendingOCRCount(): Observable<any> {
        return this.http.get('Home/GetCurrentYearPendingOCRCount')
    }
    getJobProcessPaginationOld(page, pageSize): Observable<any> {
        return this.http.get(`Home/GetJobProcess?page=${page}&pageSize=${pageSize}`)
    }

    getJobProcessPaginationNew(page, pageSize): Observable<any> {
        return this.http.get(`Home/GetJobProcessNew?page=${page}&pageSize=${pageSize}`)
    }

    checkFileExist(filePath): Observable<any> {
        return this.http.get(`Home/CheckFileExist?filePath=${filePath}`)
    }

    getOfficekeysProgramConfig(): Observable<any> {
        return this.http.get('Admin/GetOfficekeysProgramConfig')
    }

    getOfficeKeysByUserAssigned(): Observable<any> {
        return this.http.get('Admin/GetOfficeKeysByUserAssigned')
    }

    getMapedPh(officeKey): Observable<any> {
        return this.http.get(`Admin/GetMapedPhNew?officeKey=${officeKey}`)
    }

    deletePhysicianNew(payload): Observable<any> {
        return this.http.post(`Admin/DeletePhysicianNew`, payload)
    }

    getPhysicianProviderList(officeKey):Observable<any>{
        return this.http.get(`Admin/GetPhysicianProviderList?officeKey=${officeKey}`)
    }

    getLastPhysicianId(officeKey):Observable<any>{
        return this.http.get(`Admin/GetLastPhysicianId?officeKey=${officeKey}`)
    }

    checkPvPhysicianIdExist(officeKey, pvId):Observable<any>{
        return this.http.get(`Admin/CheckPvPhysicianIdExist?officeKey=${officeKey}&pvId=${pvId}`)
    }

    addNewAndUpdatePhysician(officeKey, payload): Observable<any> {
        return this.http.post(`Admin/AddNewAndUpdatePhysician?officeKey=${officeKey}`, payload)
    }

    getPhysicianByPvId(officeKey, pvId):Observable<any>{
        return this.http.get(`Admin/GetLastPhysicianId?officeKey=${officeKey}&pvId=${pvId}`)
    }

    logIntoAmd(officeKey): Observable<any>{
        return this.http.get(`Admin/LogIntoAmd?officeKey=${officeKey}`)
    }

    lookupProviders(officeKey): Observable<any>{
        return this.http.get(`Admin/LookupProviders?officeKey=${officeKey}`)
    }

    getJobProcessError(processId): Observable<any>{
        return this.http.get(`Home/GetJobProcessError?processId=${processId}`)
    }

    UpdateActivePhysician(payload): Observable<any>{
        return this.http.post(`Admin/UpdateActivePhysician`, payload)
    }

    updateVisitTag(payload): Observable<any>{
        return this.http.post('Record/UpdateVisitTag', payload)
    }

    getGroupCodeReviewRule(ruleName): Observable<any>{
        return this.http.get(`Admin/GetGroupCodeReviewRule?ruleName=${ruleName}`)
    }

    saveCodeReviewRuleNew(payload): Observable<any> {
        return this.http.post('/admin/SaveCodeReviewRuleNew', payload);
    }

    removeGroupAndItemRule(ruleId, groupRuleId): Observable<any> {
        return this.http.delete(`/admin/RemoveGroupAndItemRule?ruleId=${ruleId}&groupRuleId=${groupRuleId}`)
    }

    removeGroupCode(groupRuleId): Observable<any> {
        return this.http.delete(`/admin/RemoveGroupCode?groupRuleId=${groupRuleId}`)
    }

    setActiveGroupAndRuleItemByRuleName(ruleName, isOn): Observable<any> {
        var data = {
            ruleName: ruleName,
            isOn: isOn
        }
        return this.http.post(`/admin/SetActiveGroupAndRuleItemByRuleName`, data)
    }

    deleteRuleByRuleName(ruleName): Observable<any> {
        return this.http.delete(`/admin/DeleteRuleByRuleName?ruleName=${ruleName}`, )
    }

    rerunFileImport(payload): Observable<any> {
        return this.http.post(`Home/RerunFileImport`, payload)
    }

    rerunImportFileForUpdate(payload): Observable<any> {
        return this.http.post(`Home/RerunImportFileForUpdate`, payload)
    }

    getVisitCheckRuleCode(ruleName, pageSize): Observable<any>{
        return this.http.get(`/record/GetVisitCheckRuleCode?ruleName=${ruleName}&pageSize=${pageSize}`)
    }

    getVisitTags(): Observable<any>{
        return this.http.get(`/record/GetVisitTags`)
    }

    getPatientChartFromPDF(fileName){
        let payload = {}
        return this.http.post(`/record/GetPatientChartFromPDF?fileName=${fileName}`, payload)
    }

    getPatientChart_v2(fileName) {
        return this.http.get(`/record/getPatientChartV2?fileName=${fileName}`,{ responseType: 'blob' })
    }

    getServiceMachine(pageNumber, pageSize){
        return this.http.get(`/home/GetServiceMachine?pageNumber=${pageNumber}&pageSize=${pageSize}`)
    }

    changeOrderVisitCode(payload): Observable<any> {
        return this.http.post(`Record/ChangeOrderVisitCode`, payload)
    }

    getChartVisitImportLog(payload): Observable<any> {
        return this.http.post(`Home/GetChartVisitImportLog`, payload)
    }

    checkTokenExpired(): Observable<any> {
        return this.http.get(`Admin/CheckTokenExpired`)
    }

    checkPhysicianIdByName(request): Observable<any> {
        return this.http.post(`Admin/CheckPhysicianIdByName`, request)
    }

    getPhysicianAmdProviderEmpty(): Observable<any> {
        return this.http.get(`Home/GetPhysicianAmdProviderEmpty`)
    }
    updatePhysicianAmdProvideId(): Observable<any> {
        return this.http.get(`Physician/UpdatePhysicianAmdProvideId`)
    }
    
    runMissingVisits(request){
        return this.http.post(`Record/RunMissingVisits`, request)
        .pipe(
            catchError((err) => {
              console.log(`error caught in service`)
              return throwError(err);
            })
        );
    }

    getUserId(){
        return this.http.get(`Admin/GetUserId`)
    }

    getInsuranceInformations(pageSize, pageNumber){
        return this.http.get(`Admin/GetInsuranceInformations?pageSize=${pageSize}&pageNumber=${pageNumber}`)
        .pipe(
            catchError((err) => {
              console.log(`error caught in service`)
              return throwError(err);
            })
        );
    }

    createAndUpdateInsurance(payload, visitId = 0){
        return this.http.post(`Admin/CreateAndUpdateInsurance?visitId=${visitId}`, payload)
        .pipe(
            catchError((err) => {
              console.log(`error caught in service`)
              return throwError(err);
            })
        );
    }

    updateInsuranceExlude(payload) {
        return this.http.put(`Admin/UpdateInsuranceExclude`, payload)
            .pipe(
                catchError((err) => {
                    console.log(`error caught in service`)
                    return throwError(err);
                })
            );
    }

    deleteInsuranceInformationById(insucranceId){
        return this.http.get(`Admin/DeleteInsuranceInformationById?insuranceId=${insucranceId}`)
        .pipe(
            catchError((err) => {
              console.log(`error caught in service`)
              return throwError(err);
            })
        );
    }

    getInsuranceInformationByVisitId(visitId, officeKey){
        return this.http.get(`Record/GetInsuranceInformationByVisitId?visitId=${visitId}&officeKey=${officeKey}`)
        .pipe(
            catchError((err) => {
              console.log(`error caught in service`)
              return throwError(err);
            })
        );
    }

    updatePayersPriority(payload) {
        return this.http.post(`Record/UpdatePayersPriority`, payload)
            .pipe(
                catchError((err) => {
                    console.log(`error caught in service`)
                    return throwError(err);
                })
            );
    }

    getResLookUpResPartyByName(insName, officeKey) {
        return this.http.get(`Record/GetResLookUpResPartyByName?insName=${insName}&officeKey=${officeKey}`)
            .pipe(
                catchError((err) => {
                    console.log(`error caught in service`)
                    return throwError(err);
                })
            );
    }

    updateResPartyGuarantorImportLog(payload): Observable<any> {
        return this.http.post(`Record/UpdateResPartyGuarantorImportLog`, payload)
            .pipe(
                catchError((err) => {
                    console.log(`error caught in service`)
                    return throwError(err);
                })
            );
    }

    getGuarantorImportLog(visitId): Observable<any> {
        return this.http.get(`Record/GetGuarantorImportLog?visitId=${visitId}`)
            .pipe(
                catchError((err) => {
                    console.log(`error caught in service`)
                    return throwError(err);
                })
            );
    }




    findAmdCodeFormAddress(address, officekey){
        return this.http.get(`Record/FindCarriersFromAddress?address=${address}&officekey=${officekey}`)
        .pipe(
            catchError((err) => {
              console.log(`error caught in service`)
              return throwError(err);
            })
        );
    }

    findAmdCodeFromName(keywordName, officekey) {
        return this.http.get(`Record/FindCarriersFromName?keyword=${keywordName}&officekey=${officekey}`)
            .pipe(
                catchError((err) => {
                    console.log(`error caught in service`)
                    return throwError(err);
                })
            );
    }

    automateFixPhysician(clinicId, physicianName, visitDate, visitId){
        return this.http.get(`Record/AutomateFixPhysician?clicnicId=${clinicId}&physicianName=${physicianName}&visitDate=${visitDate}&visitId=${visitId}`)
        .pipe(
            catchError((err) => {
              console.log(`error caught in service`)
              return throwError(err);
            })
        );
    }

    getVisitProcCodesByVisitId(visitId, officeKey){
        return this.http.get(`Record/GetVisitProcCodesByVisitId?visitId=${visitId}&officeKey=${officeKey}`)
        .pipe(
            catchError((err) => {
              console.log(`error caught in service`)
              return throwError(err);
            })
        );
    }

    getVisitModifierCodesByVisitId(visitId, officeKey) {
        return this.http.get(`Record/GetVisitModifierCodesByVisitId?visitId=${visitId}&officeKey=${officeKey}`)
            .pipe(
                catchError((err) => {
                    console.log(`error caught in service`)
                    return throwError(err);
                })
            );
    }

    getVisitDiagCodesByVisitId(visitId, officeKey) {
        return this.http.get(`Record/GetDiagCodesByVisitId?visitId=${visitId}&officeKey=${officeKey}`)
            .pipe(
                catchError((err) => {
                    console.log(`error caught in service`)
                    return throwError(err);
                })
            );
    }

    deleteVisitProcCodeById(visitProcCodeId): Observable<any> {
        return this.http.delete(`Record/DeleteVisitProcCodeById?visitProcCodeId=${visitProcCodeId}`, )
    }

    updateVisitProcCode(payload): Observable<any> {
        return this.http.post(`Record/UpdateVisitProcCode`, payload)
    }

    deleteVisitDiagCodeById(visitProcCodeId): Observable<any> {
        return this.http.delete(`Record/DeleteVisitDiagCodeById?visitProcCodeId=${visitProcCodeId}`,)
    }

    updateVisitDiagCode(payload): Observable<any> {
        return this.http.post(`Record/UpdateVisitDiagCode`, payload)
    }

    updateVisitModifierCode(payload): Observable<any> {
        return this.http.post(`Record/updateVisitModifierCode`, payload)
    }


    getVisitRules(visitId): Observable<any>{
        return this.http.get(`/record/GetVisitRuleCode?visitId=${visitId}`)
    }

    getVistChartOCRData(visitId): Observable<any> {
        return this.http.get(`/record/GetVistChartOCRData?visitId=${visitId}`)
    }

    removeVisitRuleSet(ruleName, visitId):Observable<any>{
        return this.http.delete(`/record/RemoveVisitRuleSet?visitId=${visitId}&ruleName=${ruleName}`)
    }

    checkVisitProcodeWithAmd(payload): Observable<any> {
        return this.http.post(`Record/CheckVisitProcodeWithAmd`, payload)
    }
    checkVisitDiagCodeWithAmd(diagCode, officeKey): Observable<any> {
        return this.http.get(`Record/CheckDiagcodeWithAmd?diagCode=${diagCode}&officeKey=${officeKey}`)
    }
    checkVisitModifierCodeWithAmd(modifierCode, officeKey): Observable<any> {
        return this.http.get(`Record/CheckModifiercodeWithAmd?modifierCode=${modifierCode}&officeKey=${officeKey}`)
    }
    updateProcCodeForRefVisits(payload): Observable<any> {
        return this.http.post(`Record/UpdateProcCodeForRefVisits`, payload)
    }
    updateDiagCodeForRefVisits(payload): Observable<any> {
        return this.http.post(`Record/UpdateDiagCodeForRefVisits`, payload)
    }
    updateModifierCodeForRefVisits(payload): Observable<any> {
        return this.http.post(`Record/UpdateModifierCodeForRefVisits`, payload)
    }

    reImportErorRecord(payload): Observable<any> {
        return this.http.post(`Home/ReImportErorRecord`, payload)
    }

    getMissingPatientRecords(fileName): Observable<any> {
        return this.http.get(`Home/GetMissingPatientRecords?fileName=${fileName}`)
    }

    getChartFilesByChartId(chartId){
        return this.http.get(`/visit/GetChartFilesByChartId?chartId=${chartId}`)
    }

    checkFileChartExist(filePath, chartDocId): Observable<any> {
        return this.http.get(`Home/CheckFileExist?filePath=${filePath}`)
        .pipe(map(data => {
            var result = {
                chartDocId: chartDocId,
                isExist: data
            }
            return result;
        }));
    }

    uploadFileChartDoc(file, chartDocId, visitId){
        let formData = new FormData();
        formData.append(file.name, file)
        return this.http.post(`/visit/UploadFileChartDoc?chartDocId=${chartDocId}&visitId=${visitId}`, formData)
    }

    requeueVisitChartDoc(visitId): Observable<any> {
        return this.http.get(`Visit/RequeueVisitChartDoc?visitId=${visitId}`)
    }

    flagRetryVisit(visitId) {
        return this.http.get(`/Visit/flagRetryVisit?visitId=${visitId}`);
    }

    flagRetryMultipleVisits(visitIds) {
        return this.http.post(`/Visit/flagRetryMultipleVisits`, visitIds);
    }

    getListPhysicians(){
        return this.http.get(`/Visit/GetListPhysicians`);
    }

    getRulesAutomationActivity(payload): Observable<any> {
        return this.http.post(`/RulesAutomation/GetAutomatedProcessActivity`, payload);
    }

    executeRulesAutomation(payload): Observable<any> {
        return this.http.post(`/RulesAutomation/ExecuteRulesAutomation`, payload);
    }

    updateAutomationExecutionStatus(payload): Observable<any> {
        return this.http.post(`/RulesAutomation/UpdateAutomationExecutionStatus`, payload);
    }

    getEmailAutomationActivity(payload): Observable<any> {
        return this.http.post(`/RulesAutomation/GetAutomatedEmailActivity`, payload);
    }

    getMissingChartsData(payload): Observable<any> {
        return this.http.post(`/RulesAutomation/GetMissingChartsData`, payload);
    }

    getFlaggedVisits() {
        return this.http.get(`/RulesAutomation/GetFlaggedVisits`)
        .pipe(
            catchError((err) => {
                console.log(`error caught in service`)
                return throwError(err);
            })
        );
    }

    getFlaggedVisitsByRuleName(ruleName) {
        return this.http.get(`/RulesAutomation/GetFlaggedVisitsByRuleName?ruleName=${ruleName}`)
            .pipe(
                catchError((err) => {
                    console.log(`error caught in service`)
                    return throwError(err);
                })
            );
    }

    resetVisit(payload) {
        return this.http.post(`Record/ResetVisit`, payload)
    }

    resetEnqueueVisit(payload) {
        return this.http.post(`Record/ResetEnqueueVisit`, payload)
    }
    
    updateOrderIndexForCodeReviewRule(payload): Observable<any> {
        return this.http.post(`/Admin/UpdateOrderIndexForCodeReviewRule`, payload);
    }

    isRunningAutomation() {
        return this.http.get(`/RulesAutomation/IsRunningAutomation`)
            .pipe(
                catchError((err) => {
                    console.log(`error caught in service`)
                    return throwError(err);
                })
            );
    }

    getChartOcrSections(): Observable<any> {
        return this.http.get(`/RulesAutomation/GetActiveOCRSections`);
    }

    getChartOcrVitalsSections(): Observable<any> {
        return this.http.get(`/RulesAutomation/GetActiveOCRVitalSections`);
    }
    
    getChartOrderProceduresSections(): Observable<any> {
        return this.http.get(`/RulesAutomation/GetActiveOCROrderProcSections`);
    }

    getChartOcrReviewOfSysSections(): Observable<any> {
        return this.http.get(`/RulesAutomation/GetActiveOCRReviewOfSystemSections`);
    }

    UpdateVisitFinClassPhysician(payload): Observable<any> {
        return this.http.post(`/Record/UpdateVisitFinClassPhysician`, payload);
    }

    getAddendumItems(): Observable<any> {
        return this.http.get(`/Record/GetAddendumItems`);
    }

    getAddendumSettings(): Observable<any> {
        return this.http.get(`/Admin/GetAddendumSettings`);
    }

    saveAddendumItem(payload): Observable<any> {
        return this.http.post<any>('/Admin/SaveNewUpdateAddendumItem', payload);
    }

    deleteAddendumItem(id): Observable<any> {
        return this.http.delete<any>('/Admin/DeleteAddendumItem?id='+id);
    }

    submitAddendumVisit(payload: any): Observable<any> {
        return this.http.post<any>('/Record/SubmitAddendumVisit' ,payload);
    }

    getVisitChartAddendum(visitId, fileName): Observable<any> {
        return this.http.get<any>(`/Record/GetVisitChartAddendum?visitId=${visitId}&fileName=${fileName}`);
    }

    getChartDocument(chartName): Observable<any> {
        return this.http.get<any>(`/Record/GetChartDocument?chartName=${chartName}`);
    }

    removeVisitPatientDocument(payload): Observable<any> {
        return this.http.post<any>('/Record/RemoveVisitPatientDocument', payload);
    }

    getVisitSourceImportHistories(payload): Observable<any> {
        return this.http.post<any>(`/Record/GetVisitSourceImportHistories`, payload);
    }

    getVisitSourceImportChartChanges(payload): Observable<any> {
        return this.http.post<any>(`/Record/GetVisitSourceImportChartChanges`, payload);
    }

    reUploadChartDocumentByFileName(payload, logNum, visitId): Observable<any> {
        return this.http.post<any>(`/Record/ReUploadChartDocumentByFileName?logNum=${logNum}&visitId=${visitId}`, payload);
    }

    getPayerInformationTypes(): Observable<any> {
        return this.http.get<any>(`/Record/GetPayerInformationTypes`);
    }

    getGuarantorInformation(payerNum, emrId) {
        return this.http.get<any>(`/Record/GetGuarantorInformation?payerNum=${payerNum}&emrId=${emrId}`);
    }

    getPayerInformations(visitId): Observable<any> {
        return this.http.get<any>(`/Record/GetPayerInformations?visitId=${visitId}`);
    }

    getallRelationShips(emrId) {
        return this.http.get<any>(`/Record/GetAllRelationShips?emrId=${emrId}`);
    }

    addNewPayerInformation(payload: any, officeKey): Observable<any> {
        return this.http.post<any>(`/Record/AddNewPayerInformation?officeKey=${officeKey}`, payload);
    }

    updateGuarantorInformation(payload: any, visitId): Observable<any> {
        return this.http.post<any>(`/Record/UpdateGuarantorInformation?visitId=${visitId}`, payload);
    }

    getInsurances(): Observable<any> {
        return this.http.get<any[]>(`/Record/GetInsurances`).pipe(
            catchError((error) => {
                console.error('Error fetching data:', error);
                return of([]);
            })
        );
    }

    searchPayerInformationMemberIds(key: string): Observable<any> {
        return this.http.get<any[]>(`/Record/SearchPayerInformationMemberIds?keySearch=` + key).pipe(
            catchError((error) => {
                console.error('Error fetching data:', error);
                return of([]);
            })
        );
    }

    pullInsuranceAndResetUIQVisits(startDate, endDate): Observable<any> {
        const currentUrl = window.location.href;
        var url = "";
        if (currentUrl.startsWith('https://mrm-stage.cmucs.com') || currentUrl.startsWith("https://localhost")) {
            url = "https://mrmapi-test.cmucs.com/"
        } else {
            url ="https://mrmapi.cmucs.com/"
        }
        url += "ProcessUIQ/rePull-uiq-visits-error";
        return this.http.post<any>(url, {startDate: startDate, endDate: endDate}, { headers: this.getMRMApiHeaders() });
    }

    pullInsuranceAndResetUIQVisitById(visitId): Observable<any> {
        const currentUrl = window.location.href;
        var url = "";
        if (currentUrl.startsWith('https://mrm-stage.cmucs.com') || currentUrl.startsWith("https://localhost")) {
            url = "https://mrmapi-test.cmucs.com/"
        } else {
            url = "https://mrmapi.cmucs.com/"
        }
        url += `ProcessUIQ/rePull-uiq-visit-error-by-visitId/${visitId}`;

        return this.http.get<any>(url, { headers: this.getMRMApiHeaders() });
    }

    repullEverythingVisit(visitId): Observable<any> {
        const currentUrl = window.location.href;
        var url = "";
        if (currentUrl.startsWith('https://mrm-stage.cmucs.com') || currentUrl.startsWith("https://localhost")) {
            url = "https://mrmapi-test.cmucs.com/"
        } else {
            url ="https://mrmapi.cmucs.com/"
        }
        url += `ProcessUIQ/rePull-everything/${visitId}`;
        return this.http.get<any>(url, { headers: this.getMRMApiHeaders() });
    }

    private getMRMApiHeaders(): HttpHeaders {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            'MRM-Api-Key': 'glo7632cdci6ok7uxyxjn20rda2kp8ulxxgwqgdyg3jb7oyhp90zs8w4ihpnicy3'
        });
    }

    getFileDownloadVisitReport(payload: any): Observable<any> {
        return this.http.post(`/Record/GetFileVisitReport`, payload, { responseType: 'blob', observe: 'response' });
    }

    previewReportFromVisitPreImport(payload: any): Observable<any> {
        return this.http.post(`/Record/PreviewVisitReport`, payload);
    }

    previewVisitNotFinalizedReason(payload: any): Observable<any> {
        return this.http.post(`/Record/PreviewVisitNotFinalized`, payload);
    }

}
