import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from '../../services/api.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { interval } from 'rxjs';

@Component({
    selector: 'app-email-automation',
    templateUrl: './email-automation.component.html',
    styleUrls: ['./email-automation.component.scss']
})
export class EmailAutomationComponent implements OnInit {
    public total: number;
    public totalVisits: number;
    public pageSize: number = 20;
    page: any = { CurrentPage: 0 };
    userId: string = "";
    activityViewModels= new Array<any>();
    flaggedVisitIds = new Array<any>();
    public isExecuting: boolean = false;
    public isLoading: boolean = false;
    public message: string = "";
    progressBarValue: number = 0;
    executeActionDisabled = false;
    isShowSearch: boolean = true;
    isShowToast: boolean = false;
    stringToast: string = "";
    classToast: string = "";
    classAlert: string = "";
    sub: any;

    constructor(
        private apiService: ApiService
    ) {
        this.page.PageSize = 500
        this.userId = localStorage.getItem('userId')
    }

    ngOnInit(): void {

        this.loadData();
        this.startInterval();
    }

    ngOnDestroy(): void {
        this.stopInterval();
    }

    startInterval() {
        this.sub = interval(15000).subscribe((val) => {
            this.getAutomatedEmailActivity();
        });
    }

    stopInterval() {
        this.sub.unsubscribe();
    }

    loadData() {
        this.isLoading = true;
        this.getAutomatedEmailActivity();
        this.isLoading = false;
    }

    isAutomationRunning() {
        this.apiService.isRunningAutomation().subscribe((r: any) => {
            this.isExecuting = r.running;
            if (!r.running) {
                this.message = "Nothing new to execute!"
            } else {
                this.message = "READY FOR PROCESSING..."
            }

        });
    }

    getAutomatedEmailActivity() {
        var payload = {
            pageSize: this.pageSize,
            pageNumber: 0,
        }
        this.apiService.getEmailAutomationActivity(payload).subscribe((r) => {
            this.activityViewModels = r.activityViewModels || []
            this.total = r.total
        });
    }


    gotoLinkedRecord(visitId) {
        window.open("/record/loadcodeNew?visitId=" + visitId, "_blank")
    }

    showToast(message, style) {
        switch (style) {
            case "info":
                this.classToast = "toast-info"
                break;
            case "success":
                this.classToast = "toast-success"
                break;
            case "danger":
                this.classToast = "toast-danger"
                break;
            case "warning":
                this.classToast = "toast-warning"
                break;
            default:
                this.classToast = "toast-light"
                break;
        }
        this.isShowToast = true
        this.stringToast = message;
        setTimeout(() => {
            this.isShowToast = false;
            this.stringToast = "";
            this.classAlert = "toast-light";
        }, 3000)
    }
}
