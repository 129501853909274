import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-dialog-loading',
    templateUrl: './dialog-loading.component.html',
    styleUrls: ['./dialog-loading.component.scss']

})
export class DialogLoadingComponent implements OnInit {
    constructor(private matDialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any) {
  
    }

    ngOnInit(): void {
        
    }
}
