<mat-tab-group *ngIf="!currentMsg_saveBulkUpdate" (selectedTabChange)="onTabChange($event)">


    <mat-tab label="Bulk Update">
        <div style="position: relative; overflow-x: hidden; width: 100%;" *ngIf="currentTabIndex ==0" >
            <div *ngIf="isLoading" class="loading">Loading....</div>


            <div class="row" style="display: flex; align-items: center; justify-content: space-between">
                <div class="col-lg-6" style="display: flex; align-items: center;">
                    <h1 style="margin-right: 20px;">Bulk Update Records</h1>
                    <button class="mr-1" mat-raised-button (click)="checkRuleAutomationRunning()">Check is Automation running</button>
                </div>
                <div class="col-lg-6" style="text-align: end">
                    <button mat-raised-button color="primary" [disabled]="executeActionDisabled || isRulesAutomationRunning" (click)="saveBulk()">Bulk Save</button>
                    <div class="action-list-executed">
                        <span *ngFor="let item of listActionExecuted" class="action-chip">{{item.nameAction}}</span>
                    </div>
                    <div>{{currentMsg}}</div>
                </div>
            </div>

            <div class="row" *ngIf="listActionExecuted.length > 0">
                <div class="col-12" style="text-align: end">

                </div>
            </div>

            <div class="row top-buffer" *ngIf="!isLoading">
                <div class="col-md-12">
                    <div class="box box-widget">
                        <div class="box-header with-border">
                            <h3 class="box-title">Filters</h3>
                        </div>
                        <div class="box-body">
                            <div class="row">
                                <div class="col-md-3">
                                    <mat-form-field appearance="fill" style="width: 100%">
                                        <mat-label>Visit Start Date</mat-label>
                                        <input matInput [matDatepicker]="picker1" [(ngModel)]="filter.visitStartDate">
                                        <mat-hint>MM/DD/YYYY</mat-hint>
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3">
                                    <mat-form-field appearance="fill" style="width: 100%">
                                        <mat-label>Visit End Date</mat-label>
                                        <input matInput [matDatepicker]="picker2" [(ngModel)]="filter.visitEndDate">
                                        <mat-hint>MM/DD/YYYY</mat-hint>
                                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                        <mat-datepicker #picker2></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3">
                                    <mat-form-field appearance="fill" style="width: 100%">
                                        <mat-label>Code Review Rule</mat-label>
                                        <mat-select multiple [(ngModel)]="selectedRules">
                                            <mat-option value="all" (click)="selectCodeRule($event)">
                                                <ng-container *ngIf="selectedRules.includes('all')">
                                                    Unselect All
                                                </ng-container>
                                                <ng-container *ngIf="!selectedRules.includes('all')">
                                                    Select All
                                                </ng-container>
                                            </mat-option>
                                            <mat-option *ngFor="let rule of codeReviewRules" [value]="rule.name">
                                                {{rule.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="col-md-3">
                                    <mat-form-field appearance="fill" style="width: 100%">
                                        <mat-label>Tag</mat-label>
                                        <mat-select multiple [(ngModel)]="filter.tags">
                                            <mat-option *ngFor="let tag of visitTags" [value]="tag">
                                                {{tag}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">

                                <div class="col-md-3">
                                    <mat-form-field appearance="fill" style="width: 100%">
                                        <mat-label>Fin Classes</mat-label>
                                        <mat-select multiple [(ngModel)]="filter.finClassIds"
                                                    (ngModelChange)="checkShowBtnSearch()">
                                            <mat-option *ngFor="let fc of dataFilter.finClasses" [value]="fc.text">
                                                {{fc.text}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3">
                                    <mat-form-field appearance="fill" style="width: 100%">
                                        <mat-label>Clinic</mat-label>
                                        <mat-select multiple [(ngModel)]="filter.clinicIds"
                                                    (ngModelChange)="checkShowBtnSearch()">
                                            <mat-option *ngFor="let fc of dataFilter.clinics" [value]="fc.text">
                                                {{fc.text}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3">
                                    <mat-form-field appearance="fill" style="width: 100%">
                                        <mat-label>Physician</mat-label>
                                        <mat-select multiple [(ngModel)]="filter.physicianIds"
                                                    (ngModelChange)="checkShowBtnSearch()">
                                            <mat-option *ngFor="let fc of dataFilter.physicians" [value]="fc.id">
                                                {{fc.text}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3">
                                    <mat-form-field appearance="fill" style="width: 100%">
                                        <mat-label>Office Key</mat-label>
                                        <mat-select multiple [(ngModel)]="filter.officeKeys"
                                                    (ngModelChange)="checkShowBtnSearch()">
                                            <mat-option *ngFor="let fc of dataFilter.officeKeys" [value]="fc.text">
                                                {{fc.text}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <mat-form-field appearance="fill" style="width: 100%">
                                        <mat-label>Payer</mat-label>
                                        <mat-select multiple [(ngModel)]="filter.insInfoIds"
                                                    (ngModelChange)="checkShowBtnSearch()">
                                            <mat-option *ngFor="let fc of dataFilter.insurancesInfo" [value]="fc.id">
                                                {{fc.text}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12" style="text-align: right">
                                    <button mat-raised-button color="primary" class="mr-1" [disabled]="!isShowSearch"
                                            (click)="searchClass()">
                                        Search
                                    </button>
                                    <button class="mr-1" mat-raised-button (click)="refetch()">Refetch</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="table" *ngIf="!isLoading">
                <div class="row top-buffer">
                    <div class="col-md-12">
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <div class="panel-heading-body">
                                    <h3 class="box-title">Bulk Action</h3>
                                    <mat-form-field appearance="fill" *ngIf="bulkActionSets?.length > 0">
                                        <mat-label>Saved Action Set</mat-label>
                                        <mat-select [(ngModel)]="selectedBulkAction"
                                                    (ngModelChange)="changeBulkAction()">
                                            <mat-option *ngFor="let item of bulkActionSets" [value]="item">
                                                {{item.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="panel-body">
                                <div class="box box-warning">
                                    <div class="box-header with-border">
                                        <div class="col-lg-9 align-self-end mt-1">
                                            <button mat-raised-button mat-button (click)="addAction()">
                                                Add
                                                Action
                                            </button>
                                        </div>
                                        <mat-divider></mat-divider>
                                        <div class="col-lg-12" *ngIf="arrAction.length > 0">
                                            <table style="width: 100%">
                                                <tbody>
                                                    <tr style="border-bottom: 1px solid #e8e5e5;"
                                                        *ngFor="let item of arrAction; let i = index">
                                                        <td style="width: 10%">
                                                            <div style="display: flex;align-items: center; justify-content: center">
                                                                <button mat-icon-button color="warn"
                                                                        (click)="deleteItem(i)">
                                                                    <mat-icon>delete</mat-icon>
                                                                </button>
                                                                <button mat-icon-button color="primary"
                                                                        (click)="refreshItem(i)">
                                                                    <mat-icon>refresh</mat-icon>
                                                                </button>
                                                            </div>
                                                        </td>
                                                        <td style="width: 30%">
                                                            <mat-form-field appearance="fill" style="width: 100%">
                                                                <mat-label>Action</mat-label>
                                                                <mat-select (ngModelChange)="changeAction($event, i)"
                                                                            [(ngModel)]="item.value">
                                                                    <mat-option value="addMod">Add Modifier</mat-option>
                                                                    <mat-option value="delMod">
                                                                        Delete Modifier
                                                                    </mat-option>
                                                                    <mat-option value="addCpt">Add CPT</mat-option>
                                                                    <mat-option value="delCpt">Delete CPT</mat-option>
                                                                    <mat-option value="addIcd">Add ICD</mat-option>
                                                                    <mat-option value="delIcd">Delete ICD</mat-option>
                                                                    <mat-option value="addNote">Add Note</mat-option>
                                                                    <mat-option value="updPhys">
                                                                        Update Physician
                                                                    </mat-option>
                                                                    <mat-option value="updClinic">
                                                                        Update Clinic
                                                                    </mat-option>
                                                                    <mat-option value="updOfficeKey">
                                                                        Update OfficeKey
                                                                    </mat-option>
                                                                    <mat-option value="updFinClass">
                                                                        Update Fin Class
                                                                    </mat-option>
                                                                    <mat-option value="unFlag">Clear Flag</mat-option>
                                                                    <mat-option value="unTag">Clear Tags</mat-option>
                                                                    <mat-option value="unRule">Clear Rule</mat-option>
                                                                    <mat-option value="sendEmail">Send Rule Email</mat-option>
                                                                </mat-select>
                                                            </mat-form-field>
                                                        </td>
                                                        <td style="width: 60%">
                                                            <ng-container *ngIf="item.value ==  'addMod' || item.value == 'delMod' ">
                                                                <mat-form-field class="example-full-width"
                                                                                appearance="fill">
                                                                    <mat-label>Code Name</mat-label>
                                                                    <input matInput [(ngModel)]="item.codeName">
                                                                </mat-form-field>
                                                                <mat-form-field class="example-full-width"
                                                                                appearance="fill">
                                                                    <mat-label>Modifier</mat-label>
                                                                    <input matInput [(ngModel)]="item.modifier"
                                                                           (input)="checkModifierValidation(item)"
                                                                           pattern="^[A-Za-z0-9\,]*$">
                                                                    <mat-error *ngIf="!item.isValid">
                                                                        Modifier input allow chars, digits and ',' only
                                                                    </mat-error>
                                                                </mat-form-field>
                                                            </ng-container>
                                                            <ng-container *ngIf="item.value == 'addCpt' || item.value == 'delCpt'">
                                                                <mat-form-field class="example-full-width"
                                                                                appearance="fill">
                                                                    <mat-label>Code Name</mat-label>
                                                                    <input matInput [(ngModel)]="item.codeName">
                                                                </mat-form-field>
                                                            </ng-container>
                                                            <ng-container *ngIf="item.value == 'addIcd' || item.value == 'delIcd'">
                                                                <mat-form-field class="example-full-width"
                                                                                appearance="fill" style="width: 100%">
                                                                    <mat-label>Code Name</mat-label>
                                                                    <input matInput [(ngModel)]="item.codeName"
                                                                           (keyup)="searchIcd($event)" debounce="600"
                                                                           [matAutocomplete]="auto">
                                                                    <mat-autocomplete autoActiveFirstOption
                                                                                      #auto="matAutocomplete">
                                                                        <mat-option *ngFor="let option of arrIcd"
                                                                                    [value]="option[0]">
                                                                            <small><b>Code: </b> </small>
                                                                            <span>{{option[0]}}</span> |
                                                                            <small><b>Name: </b> </small>
                                                                            <span>{{option[1]}}</span>
                                                                        </mat-option>
                                                                    </mat-autocomplete>
                                                                </mat-form-field>
                                                            </ng-container>
                                                            <ng-container *ngIf="item.value == 'updPhys'">
                                                                <mat-form-field appearance="fill" style="width: 100%">
                                                                    <mat-label>Physician</mat-label>
                                                                    <mat-select [(ngModel)]="item.physician">
                                                                        <mat-option *ngFor="let o of physicians"
                                                                                    [value]="o.id">
                                                                            {{o.text}}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </ng-container>
                                                            <ng-container *ngIf="item.value == 'updClinic'">
                                                                <mat-form-field appearance="fill" style="width: 100%">
                                                                    <mat-label>Clinic</mat-label>
                                                                    <mat-select [(ngModel)]="item.clinic">
                                                                        <mat-option *ngFor="let o of clinics"
                                                                                    [value]="o.text">
                                                                            {{o.text}}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </ng-container>
                                                            <ng-container *ngIf="item.value == 'updOfficeKey'">
                                                                <mat-form-field appearance="fill" style="width: 100%">
                                                                    <mat-label>Office Key</mat-label>
                                                                    <mat-select [(ngModel)]="item.officeKey">
                                                                        <mat-option *ngFor="let o of officeKeys"
                                                                                    [value]="o">
                                                                            {{o}}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </ng-container>
                                                            <ng-container *ngIf="item.value == 'unTag'">
                                                                <mat-form-field appearance="fill" style="width: 100%">
                                                                    <mat-label>Value</mat-label>
                                                                    <mat-select multiple [(ngModel)]="item.valueTags">
                                                                        <mat-option *ngFor="let o of visitTags"
                                                                                    [value]="o">
                                                                            {{o}}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </ng-container>
                                                            <ng-container *ngIf="item.value == 'unRule' || item.value == 'sendEmail'">
                                                                <mat-form-field appearance="fill" style="width: 100%">
                                                                    <mat-label>Value Rule</mat-label>
                                                                    <mat-select multiple [(ngModel)]="item.valueRules">
                                                                        <mat-option *ngFor="let rule of codeReviewRules"
                                                                                    [value]="rule.name">
                                                                            {{rule.name}}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </ng-container>
                                                            <ng-container *ngIf="item.value == 'updFinClass'">
                                                                <mat-form-field class="example-full-width"
                                                                                appearance="fill">
                                                                    <mat-label>Financial Class</mat-label>
                                                                    <!-- <input matInput [(ngModel)]="item.finClass"> -->
                                                                    <mat-select [(ngModel)]="item.finClass">
                                                                        <mat-option *ngFor="let fc of dataFilter.finClasses" [value]="fc.text">
                                                                            {{fc.text}}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </ng-container>
                                                            <ng-container *ngIf="item.value == 'addNote'">
                                                                <mat-form-field class="example-full-width w-100" appearance="fill">
                                                                    <mat-label>Notes: </mat-label>
                                                                    <textarea matInput [(ngModel)]="item.note"></textarea>
                                                                </mat-form-field>
                                                            </ng-container>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <ng-container *ngIf="arrAction.length > 0">
                                            <div class="col-lg-12 mt-1" style="text-align: end">
                                                <mat-progress-bar mode="determinate" [value]="currentMsg_ExecuteActBulkUpdate.Value">
                                                </mat-progress-bar>
                                                <br />
                                                <div>
                                                    {{currentMsg_ExecuteActBulkUpdate.Message}}
                                                </div>
                                            </div>
                                            <div class="col-lg-12 mt-1" style="text-align: end">
                                                <button mat-raised-button class="mr-1 btn-save-set"
                                                        (click)="showInputName()">
                                                    Save Set
                                                </button>
                                                <button mat-raised-button class="mr-1 btn-clear-all-action" (click)="clearAllAction()">
                                                    Clear All Actions
                                                </button>
                                                <button mat-raised-button color="primary" class="mr-1"
                                                        [disabled]="executeActionDisabled || isRulesAutomationRunning"
                                                        (click)="executeAction()">
                                                    Execute
                                                </button>
                                                <button mat-raised-button color="warn" class="mr-1"
                                                        (click)="deleteSet()">
                                                    Delete
                                                </button>
                                            </div>
                                            <div class="col-lg-12" style="text-align: end">
                                                <span style="color: gray; font-style: italic">
                                                    All actions have been
                                                    applied to the selected records.
                                                </span>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="alert" [ngClass]="classAlert" role="alert" *ngIf="isAlert">
                    {{messageAlert}}
                </div>
                <div *ngIf="rowsBulkUpdate?.length > 0">
                    <mat-radio-group aria-label="Select an option" (ngModelChange)="selectedAllRow($event)"
                                     [(ngModel)]="selectedAll">
                        <mat-radio-button class="mr-1" value="1">Select All</mat-radio-button>
                        <mat-radio-button value="2">Unselect All</mat-radio-button>
                    </mat-radio-group>
                    <div></div>
                </div>
                <ngx-datatable class="material" [rows]="rowsBulkUpdate" [headerHeight]="50" [footerHeight]="50"
                               rowHeight="auto" [scrollbarH]="true" [loadingIndicator]="loadingTable">
                    <!--[externalPaging]="true"
    [count]="page.RowCount"
    [offset]="page.CurrentPage"
    [limit]="page.PageSize"
    [loadingIndicator]="loadingTable"
    (page)="getPage($event)"-->
                    <ngx-datatable-column name="Action" [width]="50">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <mat-checkbox (ngModelChange)="checkedRow($event, row)" [(ngModel)]="row.isChecked">
                            </mat-checkbox>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Patient Name" [width]="200">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <p> {{ row.patientName }}</p>
                            <!--<p>{{ row.birthday | date: 'MM/dd/yyyy' }}</p>-->
                            <p>{{row.patientDob}}</p>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Clinic Name" [width]="150">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{ row.clinicName }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Physicin" [width]="150">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{ row.physicianName }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Finacial Class" [width]="100">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{ row.pvFinClass }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Payment $" [width]="100">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{ row.payment }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Diag Codes" [width]="100">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <span [innerHTML]="row.diagCode"></span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Icd Codes" [width]="100">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <span [innerHTML]="row.icdCodes"></span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Proc Codes" [width]="100">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <span [innerHTML]="row.procCodes"></span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Visit Date" [width]="100">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{ row.visitTime }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Applied Rules" [width]="150">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <span [innerHTML]="row.appliedRules"></span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [width]="100" [sortable]="false">
                        <ng-template ngx-datatable-header-template >
                            Office Key <br /> Reg ID
                        </ng-template>

                        <ng-template let-row="row" ngx-datatable-cell-template >
                            {{ row.officeKey }} <br />
                            {{ row.visitIdCode }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Carrier" [width]="100">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{ row.insuranceName }}
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Link" [width]="100">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div>
                                <button class="btn btn-secondary"
                                        aria-label="Link" matTooltip="Link to Record"
                                        (click)="gotoLinkedRecord(row.visitId)">
                                    Coding
                                </button>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
            </div>


            <div class="toast" *ngIf="isShowToast" [ngClass]="classToast">
                <p>{{stringToast}}</p>
            </div>

            <div class="modal-backdrop-custom" [ngClass]="isShowInput? 'd-block': 'd-none'">
                <div class="modal-content">
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field class="w-100">
                                <mat-label>Name Action Set</mat-label>
                                <input matInput placeholder="Set 1" [(ngModel)]="nameBulkActionSet" autofocus>
                                <mat-hint class="required" color="warn">Please name the action set</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12" style="text-align: end; padding: 5px 10px">
                            <button mat-raised-button class="mr-1" (click)="hiddenInputName()">Close</button>
                            <button mat-raised-button color="primary" [disabled]="nameBulkActionSet == ''"
                                    (click)="saveSet()">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-tab>

    <mat-tab label="Rules Automation">
        <app-rules-automation *ngIf="currentTabIndex == 1"></app-rules-automation>
    </mat-tab>

    <mat-tab label="Email Automation">
        <app-email-automation *ngIf="currentTabIndex == 2"></app-email-automation>
    </mat-tab>

    <mat-tab label="Missing Chart Files">
        <app-missing-chart-files *ngIf="currentTabIndex == 3"></app-missing-chart-files>
    </mat-tab>

    <mat-tab label="Activity Log">
        <app-activity-log *ngIf="currentTabIndex == 4"></app-activity-log>
    </mat-tab>
</mat-tab-group>

<div *ngIf="currentMsg_saveBulkUpdate" style="text-align:center; margin-top:200px">
    {{currentMsg_saveBulkUpdate}}

</div>